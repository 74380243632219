import React from 'react'
import { Card, Col, Row, Container } from 'react-bootstrap'

import style from './CryptoConverter.module.css'

export default function CryptoConverter(props) {
  return (
    <Card className={style.convBox}>
        <Card.Body>
            <Container>
                <Row>
                    <Col xs={12} md={5} className="d-flex justify-content-center align-items-center">{props.coinId && props.coinId} box</Col>
                    <Col xs={12} md={2} className="d-flex justify-content-center align-items-center"> = </Col>
                    <Col xs={12} md={5} className="d-flex justify-content-center align-items-center"> usd box</Col>
                </Row>
            </Container>
        </Card.Body>
    </Card>
  )
}
