import React, { useContext, useState, useEffect } from 'react'
import app from '../firebase'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}){
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    function signup(email, password){
        return app.auth().createUserWithEmailAndPassword(email, password).then(async cred => {
            console.log(cred.user.uid)
            try{
                await app.firestore()
                .collection('users')
                .doc(cred.user.uid)
                .set({
                    currency: "usd",
                    totalProfitCurrency: 0,
                    totalProfitPercentage: 0,
                    lastUpdate: 0,
                    admin: false,
                    active: false,
                    autoRaports: false,
                    FIATin: 0,
                    lastRaport: 0,
                    FIATout: 0,
                    email: email,
                    lastUpdateCoingecko: 0,
                    oneDayChangeCurrency: 0,
                    oneDayChangePercentage: 0,
                    oneHourChangeCurrency: 0,
                    oneHourChangePercentage: 0,
                    oneWeekChangeCurrency: 0,
                    oneWeekChangePercentage: 0,
                    oneMonthChangeCurrency: 0,
                    oneMonthChangePercentage: 0,
                    totalCurrentValueInUSD: 0,
                    nick: (email.substring(0, email.indexOf('@')))
                })

                await app.firestore()
                .collection('users')
                .doc(cred.user.uid)
                .collection('settings')
                .doc('reports')
                .set({
                    report0630: false,
                    report1300: false,
                    report1800: false,
                    report2300: false,
                })

                await app.firestore()
                .collection('users')
                .doc(cred.user.uid)
                .collection('portfolio')
                .doc('bitcoin')
                .set({
                    coingeckoID: 'bitcoin',
                    show: true,
                    totalAmountOfCoin: 0,
                    totalProfitCurrency: 0,
                    totalProfitPercentage: 0,
                    totalValueInUSD: 0,
                    totalInvestedInUSD: 0,
                })

                await app.firestore()
                .collection('users')
                .doc(cred.user.uid)
                .collection('portfolio')
                .doc('binancecoin')
                .set({
                    coingeckoID: 'binancecoin',
                    show: true,
                    totalAmountOfCoin: 0,
                    totalProfitCurrency: 0,
                    totalProfitPercentage: 0,
                    totalValueInUSD: 0,
                    totalInvestedInUSD: 0,
                })
            }
            catch(e){
                console.log(e)
            }
        }).catch(error => error)
    }

    function login(email, password){
        return app.auth().signInWithEmailAndPassword(email, password)
    }

    function logout(){
        return app.auth().signOut()
    } 

    function resetPassword(email){
        return app.auth().sendPasswordResetEmail(email)
    }

    function updateEmail(email){
        return app.auth().currentUser.updateEmail(email)
    }

    useEffect(() => {
        const unsubscribe = app.auth().onAuthStateChanged(user => {
            setCurrentUser(user)

            if(user){
                setLoading(false)
            }
            else{
                setLoading(false)
            }
        })

        return unsubscribe
    }, [])

    const value = {
        currentUser,
        login,
        logout,
        signup,
        resetPassword,
        updateEmail
    }

    return(
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}