import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap'
import DataTable from 'react-data-table-component';

import { useParams, Switch, Route } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';

import style from './CoinHistory.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDeleteLeft, faPenToSquare, faCheck } from '@fortawesome/free-solid-svg-icons'
import { set } from 'react-ga';

export default function CoinHistory(props) {

    const { coin } = useParams()

    const [ longNameCurrentCoin, setLongNameCurrentCoin ] = useState()
    const [ tableData, setTableData ] = useState([])
    const [ allTableData, setAllTableData ] = useState([])
    const [show, setShow] = useState(false);
    const [ showenDate, setShowenDate] = useState()
    const [ editingTransactionUID, setEditingTransactionUID ] = useState()
    const [ newDateToInsert, setNewDateToInsert ] = useState()
    const [ editingTransaction, setEditingTransaction ] = useState()

    const [ editingCoin, setEditingCoin ] = useState()
    const [ editingAmount, setEditingAmount ] = useState()
    const [ editingRate, setEditingRate ] = useState()
    const [ editingDate, setEditingDate ] = useState()
    const [ editingLogo, setEditingLogo ] = useState()
    const [ editingCoinAction, setEditingCoinAction ] = useState()
    const [ editingCoinCoingecko ,setEditingCoinCoingecko ] = useState()

    const [ editingAmountToEdit, setEditingAmountToEdit ] = useState()
    const [ editingRateToEdit, setEditingRateToEdit ] = useState()
    const [ editingDateToEdit, setEditingDateToEdit ] = useState()
    const [ editingTimeToEdit, setEditingTimeToEdit ] = useState()
    const [ editingCoinActionToEdit, setEditingCoinActionToEdit ] = useState()

    const handleClose = () => { 
        setShow(false);
        setEditingCoin()
        setEditingAmount()
        setEditingRate()
        setEditingDate()
        setEditingLogo()
        setEditingCoinAction()
        setEditingCoinCoingecko()

        setEditingAmountToEdit()
        setEditingCoinActionToEdit()
        setEditingRateToEdit()
        setEditingDateToEdit()
        setEditingTimeToEdit()
        setEditingCoinActionToEdit()
    } 

    const handleEditRecord = (coin, idOfTransaction, transaction) => {
        setEditingTransaction(transaction)
        setNewDateToInsert(new Date(transaction.date))
        let tempDate = new Date(transaction.date)
        setShow(true)
        setEditingTransactionUID(transaction.uid)
        setEditingCoin(transaction.coinFullName)
        setEditingCoinCoingecko(transaction.coin)
        setEditingAmount(parseFloat(transaction.amount))
        setEditingAmountToEdit(parseFloat(transaction.amount))
        setEditingRate(parseFloat(transaction.rate))
        setEditingRateToEdit(parseFloat(transaction.rate))
        setEditingDate(parseFloat(transaction.date))
        setEditingDateToEdit(`${tempDate.getFullYear()}-${tempDate.getMonth()+1 < 10 ? "0"+(tempDate.getMonth()+1) : tempDate.getMonth()+1}-${tempDate.getDate() < 10 ? "0"+tempDate.getDate() : tempDate.getDate()}`)
        setEditingTimeToEdit(`${tempDate.getHours() < 10 ? '0' + tempDate.getHours() : tempDate.getHours()}:${tempDate.getMinutes() < 10 ? '0' + tempDate.getMinutes() : tempDate.getMinutes()}`)
        setEditingCoinActionToEdit(transaction.type)
        transaction.type == 'buy' ? setEditingCoinAction('ZAKUP') : transaction.type == 'sell' ? setEditingCoinAction('SPRZEDAŻ') : transaction.type == 'transfer' ? setEditingCoinAction('TRANSFER') : transaction.type == 'cashIn' ? setEditingCoinAction('WPŁATA') : transaction.type == 'cashOut' ? setEditingCoinAction('WYPŁATA') : setEditingCoinAction('null')
        setEditingLogo(transaction.logo) 
        console.log(transaction)
    }

    const handleSaveChangesFIAT = async () => {
        if(editingAmountToEdit > 0){
            try{
                let newTransaction = {
                    date: new Date(newDateToInsert).valueOf(),
                    amount: parseFloat(editingAmountToEdit),
                    type: editingTransaction.type
                }
                await props.handleUpdateFiatTransaction(editingTransaction.uid, newTransaction)
            }
            catch(e){
                console.log(e)
            }   
        }
    }

    const handleSaveChanges = () => {
        let freePass = true
        let newDate = new Date(newDateToInsert).valueOf()

        let newTransaction = {
            amount: editingAmountToEdit,
            date: newDate,
            rate: editingCoinAction != 'TRANSFER' ? editingRateToEdit : 0,
            type: editingCoinActionToEdit
        }
        if(editingCoinActionToEdit == 'sell'){
            props.userPortfolio && props.userPortfolio.map(coin => {
                if(coin.coingeckoID == editingCoinCoingecko){
                    if(editingCoinActionToEdit == "sell" && (editingCoinAction == 'buy' || editingCoinAction == 'transfer' )){
                        if(coin.totalAmountOfCoin + (2 * editingAmount) < editingAmountToEdit){
                            let max = coin.totalAmountOfCoin + editingAmount
                            toast(`Maksymalnie możesza sprzedać ${max} tokenów.`, {
                                type: 'warning'
                            })
                            freePass = false
                        } 
                    }
                    else{
                        if(coin.totalAmountOfCoin + editingAmount < editingAmountToEdit){
                        
                            let max = coin.totalAmountOfCoin + editingAmount
                            toast(`Maksymalnie możesz sprzedać ${max} tokenów.`, {
                                type: 'warning'
                            })
                            freePass = false
                        }
                    }
                }
            })
        }
        
        freePass && props.editSpecificTransactionOfCoin(editingCoinCoingecko, editingTransactionUID, newTransaction)
    }

    useEffect(() => {
        if(editingDateToEdit){
            let tempDate = new Date(editingDateToEdit)
            setShowenDate(`${tempDate.getFullYear()}-${(tempDate.getMonth()+1) < 10 ? '0' : ''}${tempDate.getMonth()+1}-${tempDate.getDate() < 10 ? '0' : ''}${tempDate.getDate()}`)
        }
    }, [setEditingDateToEdit])

    const handleRemoveRecord = (coin, idOfTransaction) => {
        if(window.confirm("u sure to delete this transaction from db?")){
            props.removeSpecificTransactionOfCoin(coin, idOfTransaction)
        }
    }

    const handleRemoveRecordFiat = (docUid) => {
        if(window.confirm("u sure to delete this transaction from db?")){
            props.removeSpecificTransactionOfCoinFiat(docUid)
            console.log(docUid)
        }
    }

    const handleChangeDate = (datte) => {
        setEditingDateToEdit(datte)
        console.log(newDateToInsert)
        let dat = datte.split('-')
        let tempD = new Date(newDateToInsert)
        tempD.setFullYear(dat[0])
        tempD.setMonth(dat[1]-1)
        tempD.setDate(dat[2])
        if(new Date(tempD) != 'Invalid Date') setNewDateToInsert(tempD)

    }

    const handleChangeTime = (timme) => {
        setEditingTimeToEdit(timme)
        let t = timme.split(':')
        let newDateConverted = new Date(newDateToInsert)
        newDateConverted.setHours(t[0], t[1])
        if(new Date(newDateConverted) != 'Invalid Date') setNewDateToInsert(newDateConverted)
    }

    useEffect(() => {
        newDateToInsert && console.log(newDateToInsert)
    }, [newDateToInsert])

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            maxWidth: '30px'
        },
        {
            name: 'Typ',
            selector: row => row.type,
            sortable: true,
            minWidth: '125px'
        },
        {
            name: 'Data',
            selector: row => row.date,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Kryptowaluta',
            selector: row => row.coin,
            sortable: true,
            minWidth: '125px'
        },
        {
            name: 'Ilość',
            selector: row => row.amount,
            sortable: true,
            minWidth: '125px'
        },
        {
            name: 'Kurs',
            selector: row => row.rate,
            sortable: true,
            minWidth: '125px'
        },
        {
            name: 'Koszt (w USD)',
            selector: row => row.totalValue,
            sortable: true,
            minWidth: '125px'
        },
        {
            name: 'Modyfikacja',
            selector: row => row.action,
            sortable: false,
            minWidth: '125px'
        },
    ];

    const getAllTransaction = async (coin) => {
        await props.getAllTransactionBySpecificCoin(coin)
        console.log(coin)
    }

    useEffect(() => {
    }, [props.transactionsOfCoin])

    useEffect(() => {
        if(coin){
            getAllTransaction(coin)
        }
    }, [])

    useEffect(() => {
        try{
                let output = []
                setAllTableData()
                if(!coin){
                    props.allTransactions && props.allTransactions.map(async (transaction, index) => {
                        let temp
                                temp = {
                                    id: index+1,
                                    coin: transaction.coinFullName,
                                    date: props.visibility == 2 ? props.convertDateToHumanFriendly(transaction.date) : '**:** **.**.****',
                                    amount: (transaction.type != 'cashIn' && transaction.type != 'cashOut' ? props.visibility == 2 ? ((transaction.amount) > 1 && (transaction.amount % 1 == 0)) ? props.numberWithCommas(transaction.amount) : transaction.amount : '*****' : null),
                                    rate: props.visibility == 2 ? transaction.rate : '*****',
                                    type: ((transaction.futures) == 0 || transaction.futures == NaN || transaction.futures == undefined) ? (transaction.type) == 'buy' ? <span style={{color: '#21bf73', fontWeight: '600'}}>ZAKUP</span> : (transaction.type) == 'sell' ? <span style={{color: 'rgb(255, 77, 77)', fontWeight: '600'}}>SPRZEDAŻ</span> : (transaction.type) == 'transfer' ? <span style={{color: '#0d6efd', fontWeight: '600'}}>TRANSFER</span> : (transaction.type) == 'cashIn' ? <span style={{fontWeight: '600'}}>WPŁATA</span> : (transaction.type) == 'cashOut' ? <span style={{fontWeight: '600'}}>WYPŁATA</span> : '?' : (transaction.type) == 'sell' ? 'short' : <span style={{color: '#198754', fontWeight: '600'}}>LONG x{transaction.futures}</span>,
                                    totalValue: (props.visibility == 2 ? `$ ${props.numberWithCommas((parseFloat(transaction.amount) * (transaction.type != 'cashIn' && transaction.type != 'cashOut' ? parseFloat(transaction.rate) : 1)).toFixed(2))}` : '$ *****'),
                                    action: <div>
                                        <Button onClick={() => handleEditRecord(transaction.coin, transaction.uid, transaction)} variant="outline-success" className="btn-sm"><FontAwesomeIcon icon={faPenToSquare} /></Button>
                                        {
                                            (transaction.type == 'cashIn' || transaction.type == 'cashOut') ? 
                                            <Button onClick={() => handleRemoveRecordFiat(transaction.uid)} variant="outline-danger" className="btn-sm" style={{marginLeft: '5px'}}><FontAwesomeIcon icon={faDeleteLeft} /></Button>
                                            : <Button onClick={() => handleRemoveRecord(transaction.coin, transaction.uid)} variant="outline-danger" className="btn-sm" style={{marginLeft: '5px'}}><FontAwesomeIcon icon={faDeleteLeft} /></Button>
                                        }
                                    </div>
                                }
                                output.push(temp)
                    })
    
                    console.log(output)
                    setAllTableData(output)
                }
                else{
                    props.transactionsOfCoin && props.transactionsOfCoin.map(async (transaction, index) => {
                        let temp
                        if(index == 0) setLongNameCurrentCoin(transaction.coinFullName)
                        temp = {
                            id: index+1,
                            coin: transaction.coinFullName,
                            date: props.visibility == 2 ? props.convertDateToHumanFriendly(transaction.date) : '**:** **.**.****',
                            amount: (transaction.type != 'cashIn' && transaction.type != 'cashOut' ? props.visibility == 2 ? ((transaction.amount) > 1 && (transaction.amount % 1 == 0)) ? props.numberWithCommas(transaction.amount) : transaction.amount : '*****' : null),
                            rate: props.visibility == 2 ? transaction.rate : '*****',
                            type: ((transaction.futures) == 0 || transaction.futures == NaN || transaction.futures == undefined) ? (transaction.type) == 'buy' ? <span style={{color: 'rgb(33, 191, 115)', fontWeight: '600'}}>ZAKUP</span> : (transaction.type) == 'sell' ? <span style={{color: '#dc3545', fontWeight: '600'}}>SPRZEDAŻ</span> : (transaction.type) == 'transfer' ? <span style={{color: '#0d6efd', fontWeight: '600'}}>TRANSFER</span> : (transaction.type) == 'cashIn' ? <span style={{fontWeight: '600'}}>WPŁATA</span> : (transaction.type) == 'cashOut' ? <span style={{fontWeight: '600'}}>WYPŁATA</span> : '?' : (transaction.type) == 'sell' ? 'short' : <span style={{color: '#198754', fontWeight: '600'}}>LONG x{transaction.futures}</span>,
                            totalValue: (props.visibility == 2 ? `$ ${props.numberWithCommas((parseFloat(transaction.amount) * (transaction.type != 'cashIn' && transaction.type != 'cashOut' ? parseFloat(transaction.rate) : 1)).toFixed(2))}` : '$ *****'),
                            action: <div>
                                <Button onClick={() => handleEditRecord(transaction.coin, transaction.uid, transaction)} variant="outline-success" className="btn-sm"><FontAwesomeIcon icon={faPenToSquare} /></Button>
                                {
                                    (transaction.type == 'cashIn' || transaction.type == 'cashOut') ? 
                                    <Button onClick={() => handleRemoveRecordFiat(transaction.uid)} variant="outline-danger" className="btn-sm" style={{marginLeft: '5px'}}><FontAwesomeIcon icon={faDeleteLeft} /></Button>
                                    : <Button onClick={() => handleRemoveRecord(transaction.coin, transaction.uid)} variant="outline-danger" className="btn-sm" style={{marginLeft: '5px'}}><FontAwesomeIcon icon={faDeleteLeft} /></Button>
                                }
                            </div>
                        }
                        output.push(temp)
                    })
    
                    console.log(output)
                    setAllTableData(output)
                }
            }
        catch(e){
            console.log(e)
        }
    }, [props.allTransactions, props.transactionsOfCoin, props.visibility])

    return(
           <Container>
                <ToastContainer />
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title className="d-flex flex-column justify-content-center align-items-start">
                        {
                        (editingCoinAction == 'WPŁATA' || editingCoinAction == 'WYPŁATA') ?
                        'Edytuj transakcję'
                        : <div className="d-flex justify-content-center align-items-center">
                            <img src={editingLogo} style={{maxWidth: '28px', marginRight: '10px', borderRadius: '5px'}} />
                            {editingCoin}
                        </div>
                        } 
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                    <div className={style.modalEditLabelHist}>
                            <h4 style={{fontSize: '16px'}}>Edytujesz transakcję:</h4>
                            <p>Typ: <span className={editingCoinAction == "SPRZEDAŻ" ? style.redLabel : editingCoinAction == "ZAKUP" ? style.greenLabel : editingCoinAction == "TRANSFER" ? style.blueLabel : null}>{editingCoinAction}</span></p>
                            <p>Ilość monet: <span>{editingAmount}</span></p>
                            {editingCoinAction != "TRANSFER" && editingCoinAction != 'WPŁATA' && editingCoinAction == 'WYPŁATA' ? <p>Kurs: <span>{editingRate}</span></p> : null }
                            <p>Czas realizacji: <span>{props.convertDateToHumanFriendly(editingDate)}</span></p>
                        </div>
                    <Form className="mt-4">
                        <h4 style={{fontSize: '16px'}}>Wprowadź aktualne wartości:</h4>
                        <Form.Group style={{marginTop: '15px'}}>
                            <Form.Label>Typ transackji</Form.Label>
                            {
                                editingTransaction && editingTransaction.type != 'cashIn' && editingTransaction.type != 'cashOut' ?
                                <Form.Select disabled aria-label="Default select example" onChange={(e) => setEditingCoinActionToEdit(e.target.value)}>
                                    <option value="buy" selected={editingCoinAction == 'ZAKUP' ? true : false}>ZAKUP</option>
                                    <option value="sell" selected={editingCoinAction == 'SPRZEDAŻ' ? true : false}>SPRZEDAŻ</option>
                                    <option value="transfer" selected={editingCoinAction == 'TRANSFER' ? true : false}>TRANSFER</option>
                                </Form.Select> : 
                                <Form.Select disabled aria-label="Default select example" onChange={(e) => setEditingCoinActionToEdit(e.target.value)}>
                                    <option value="buy" selected={editingTransaction && editingTransaction.type == 'cashIn' ? true : false}>WPŁATA</option>
                                    <option value="sell" selected={editingTransaction && editingTransaction.type == 'cashOut' ? true : false}>WYPŁATA</option>
                                </Form.Select>
                            }
                        </Form.Group>
                        <Form.Group style={{marginTop: '15px'}}>
                            <Form.Label>{editingTransaction && (editingTransaction.type == 'cashIn' || editingTransaction.type == 'cashOut') ? 'Kwota' : 'Ilość waluty'}</Form.Label>
                            <Form.Control inputMode='decimal' type="number" onWheel={(e) => e.target.blur()} value={editingAmountToEdit} onChange={(e) => setEditingAmountToEdit(e.target.value)} required />
                        </Form.Group>
                        {
                            editingTransaction && editingTransaction.type != 'cashIn' && editingTransaction.type != 'cashOut' && editingCoinAction != "TRANSFER" ? 
                            <Form.Group className="mt-2">
                                <Form.Label>Kurs {editingCoinAction == 'ZAKUP' ? 'zakupu' : editingCoinAction == 'SPRZEDAŻ' ? 'sprzedaży' : '###'} (w USD)</Form.Label>
                                <Form.Control inputMode='decimal' onWheel={(e) => e.target.blur()} type="number" value={editingRateToEdit} onChange={(e) => setEditingRateToEdit(e.target.value)} required />
                            </Form.Group> 
                            : null
                        }
                        <Form.Group className="mt-1 mb-2">
                            <Form.Label className="mb-0 mt-2">Czas sprzedaży</Form.Label>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Control type="date" className="mx-0 mt-1" value={editingDateToEdit} onChange={(e) => handleChangeDate(e.target.value)} required />
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Control type="time" className="mx-0 mt-1" value={editingTimeToEdit} onChange={(e) => handleChangeTime(e.target.value)} required />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Zamknij
                    </Button>
                    {
                        editingCoinAction && (editingCoinAction == 'WPŁATA' || editingCoinAction == 'WYPŁATA') ?
                        <Button variant="primary" onClick={handleSaveChangesFIAT}>
                            Zapisz wpłatę/wypłata
                        </Button>
                        :
                        <Button variant="primary" onClick={handleSaveChanges}>
                            Zapisz transakcję
                        </Button>
                    }
                    </Modal.Footer>
                </Modal>
                {
                    coin ?
                    <>
                    <Row style={{marginTop: '50px'}}>
                        <Col xs={12}>
                            <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
                            <h6><span style={{fontWeight: '400'}}>Wszystkie transakcje: </span><b>{longNameCurrentCoin && longNameCurrentCoin}</b></h6>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '50px'}}>
                        <Col xs={12} className={style.tableBoxinSingle}>
                            { (allTableData.length) ? <DataTable columns={columns} data={allTableData} pagination /> : <span className="text-muted">brak danych</span> }
                        </Col>
                    </Row>
                    </>
                    : 
                    <>
                    <Row style={{marginTop: '50px'}}>
                        <Col xs={12}>
                            <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
                            <h6><span style={{fontWeight: '400'}}>Wszystkie transakcje</span></h6>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '50px'}}>
                        <Col xs={12} className={`${style.tableBoxinSingle} ${style.allHisBox}`}>
                            { allTableData.length ? <DataTable columns={columns} data={allTableData} pagination noDataComponent="brak danych" /> : <span className="text-muted">brak danych</span> }
                        </Col>
                    </Row>
                    </>
                }
            </Container>
        )
}
