import React from 'react'
import { Card } from 'react-bootstrap'

import style from './TotalProfitBox.module.css'

export default function TotalProfitBox(props) {
  return (
    <Card className="mt-4">
        <Card.Header>
            Całkowity zysk / strata
        </Card.Header>
        <Card.Body className={style.totalProfitBox}>
                    <span className={props.userData && (Number.isNaN(props.userData.totalProfitCurrency) ? style.currecyLabelValueNaN : style.currecyLabelValue)}>
                    {
                        props.visibility == 2 ? 
                        (props.userData ? (Number.isNaN(props.userData.totalProfitCurrency) ? 'brak danych' : (props.userData.totalProfitCurrency >= 0 ? `+${(props.userData.totalProfitCurrency).toFixed(2)}$` : `${(props.userData.totalProfitCurrency).toFixed(2)}$`) ) : 'brak danych') :
                        '***** $'
                    }
                    </span>
                    <span className={props.userData && (Number.isNaN(props.userData.totalProfitPercentage) ? style.percentLabelValueDefault : (props.userData.totalProfitPercentage >= 0 ? style.percentLabelValuePlus : style.percentLabelValueMinus))}>
                    {
                        props.visibility == 2 || props.visibility == 1? 
                        (props.userData ? (Number.isNaN(props.userData.totalProfitPercentage) ? '' : (props.userData.totalProfitPercentage >= 0 ? `(+${(props.userData.totalProfitPercentage).toFixed(2)}%)` : `(${(props.userData.totalProfitPercentage).toFixed(2)}%)`) ) : '') :
                        ''
                    }
                    </span>
        </Card.Body>
    </Card>
  )
}
