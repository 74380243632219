import React, { useState, useRef } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import logo from './../../assets/icon-glow.png'

import { Container, Row, Col, Card, Form, Button, Alert } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e){
    e.preventDefault()
    try{
        setError('')
        setLoading(true)
        await login(emailRef.current.value, passwordRef.current.value)
        history.push('/panel/dashboard')
    } catch(e){
        console.log(e)
        setError('Wystąpił problem z logowaniem.')
    }
    setLoading(false)
  }

  return (
    <Container>
      <Row className="w-100 mx-0">
          <Col xs={12} className="d-flex justify-content-center flex-column align-items-center">
            <img src={logo} alt="logo" className="logoMAIN mt-4 mt-md-5" />
            <h2 className="mt-3 mt-md-5">CryptoFolio<span style={{color: 'rgb(33, 191, 115)'}}>.io</span></h2>
            {/* <h6>Zaloguj się</h6> */}
          </Col>
          <Col xs={12} className="d-flex justify-content-center align-items-center mt-1 flex-column">
              <Card className="w-100 mt-0" style={{maxWidth: "450px"}}>
                  <Card.Header className="px-3 py-3">
                    Personal space for managing own crypto finances.
                  </Card.Header>
                  <Card.Body>
                      {error && <Alert variant="danger">{error}</Alert>}
                      <Form onSubmit={handleSubmit}>
                          <Form.Group id="email" className="">
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="email" ref={emailRef} required />
                          </Form.Group>
                          <Form.Group id="password" className="mt-2">
                              <Form.Label>Hasło</Form.Label>
                              <Form.Control type="password" ref={passwordRef} required />
                          </Form.Group>
                          <Button disabled={loading} variant="primary" className="w-100 mt-3" type="submit">Zaloguj się</Button>
                          <div className='d-flex flex-row justify-content-center align-items-center mt-3'>
                            <hr style={{height: '1px',background: 'white', width: '100px', display: 'block'}} />
                            <span className="px-3" style={{color: 'gray', fontSize: '14px', display: 'flex', alignItems: 'center'}}>LUB</span>
                            <hr style={{height: '1px',background: 'white', width: '100px', display: 'block'}} />
                          </div>
                          <Link to="/join" disabled={loading} className="btn w-100 mt-3 btn-outline-primary" type="submit">Załóż konto</Link>
                      </Form>
                  </Card.Body>
                  <Card.Footer>
                    <span style={{fontSize: '14px'}}>Develop by. <a href="https://janmager.pl">janmager.pl</a></span>
                  </Card.Footer>
              </Card>
          </Col>
      </Row>
    </Container>
  )
}
