import React from 'react'

import { Toast } from 'react-bootstrap'

import './ErrorAlert.css'

export default function ErrorAlert(props) {
  return (
    <Toast style={{position: 'fixed', bottom: 0, right: 0, marginRight: '15px', marginBottom: '15px'}} className={props.errorType}>
        <Toast.Header closeButton={false} style={{color: '#2a2a2a'}}>
            { props.errorTitle && props.errorTitle }
        </Toast.Header>
        <Toast.Body>
            { props.errorMessage && props.errorMessage }
        </Toast.Body>
    </Toast>
  )
}
