import React, { useEffect } from 'react'

import { Card } from 'react-bootstrap'
import LastUpdate from '../LastUpdate/LastUpdate'

import style from './MainOverviewOfAccount.module.css'

export default function MainOverviewOfAccount(props) {

    return (
        props.userData != undefined ?
        <Card>
            <Card.Header>
                <span>Aktualny stan portfela</span>
            </Card.Header>
            <Card.Body className="p-0">
                <strong className={style.totalValutOfWallet}>{props.userData ? (props.visibility == 0 || props.visibility == 1 ? '***** ' : (props.addZeroes((props.userData.totalCurrentValueInUSD).toString()))) : '00.00'}{props.userData ? props.userData.symbol : ' $'}</strong>
                <div className={style.insideCardHeader}>
                    Zmiana 1H
                </div>
                <div className={style.insideCardBody}> 
                    <span className={(Number.isNaN(props.userData.oneHourChangeCurrency) ? style.currecyLabelValueNaN : style.currecyLabelValue)}>
                    {
                        props.visibility == 2 ? 
                        (props.userData ? (Number.isNaN(props.userData.oneHourChangeCurrency) ? 'brak danych' : (props.userData.oneHourChangeCurrency >= 0 ? `+${props.addZeroes((props.userData.oneHourChangeCurrency).toString())}$` : `${props.addZeroes((props.userData.oneHourChangeCurrency).toString())}$`) ) : 'brak danych') :
                        '***** $'
                    }
                    </span>
                    <span className={(Number.isNaN(props.userData.oneHourChangePercentage) ? style.percentLabelValueDefault : (props.userData.oneHourChangePercentage >= 0 ? style.percentLabelValuePlus : style.percentLabelValueMinus))}>
                    {
                        (props.visibility == 2 || props.visibility == 1) ? 
                        (props.userData ? (Number.isNaN(props.userData.oneHourChangePercentage) ? '' : (props.userData.oneHourChangePercentage >= 0 ? `(+${(props.userData.oneHourChangePercentage).toFixed(2)}%)` : `(${(props.userData.oneHourChangePercentage).toFixed(2)}%)`) ) : '') :
                        ''
                    }
                    </span>
                </div>
                <div className={style.insideCardHeader}>
                    Zmiana 24H
                </div>
                <div className={style.insideCardBody}> 
                    <span className={(Number.isNaN(props.userData.oneDayChangeCurrency) ? style.currecyLabelValueNaN : style.currecyLabelValue)}>
                    {
                        props.visibility == 2 ? 
                        (props.userData ? (Number.isNaN(props.userData.oneDayChangeCurrency) ? 'brak danych' : (props.userData.oneDayChangeCurrency >= 0 ? `+${(props.userData.oneDayChangeCurrency).toFixed(2)}$` : `${(props.userData.oneDayChangeCurrency).toFixed(2)}$`) ) : 'brak danych') :
                        '***** $'
                    }
                    </span>
                    <span className={(Number.isNaN(props.userData.oneDayChangePercentage) ? style.percentLabelValueDefault : (props.userData.oneDayChangePercentage >= 0 ? style.percentLabelValuePlus : style.percentLabelValueMinus))}>
                    {
                        (props.visibility == 2 || props.visibility == 1) ?
                        (props.userData ? (Number.isNaN(props.userData.oneDayChangePercentage) ? '' : (props.userData.oneDayChangePercentage >= 0 ? `(+${(props.userData.oneDayChangePercentage).toFixed(2)}%)` : `(${(props.userData.oneDayChangePercentage).toFixed(2)}%)`) ) : '') :
                        ''
                    }
                    </span>
                </div>
                <div className={style.insideCardHeader}>
                    Zmiana 7D
                </div>
                <div className={style.insideCardBody}> 
                    <span className={(Number.isNaN(props.userData.oneWeekChangeCurrency) ? style.currecyLabelValueNaN : style.currecyLabelValue)}>
                    {
                        props.visibility == 2 ? 
                        (props.userData ? (Number.isNaN(props.userData.oneWeekChangeCurrency) ? 'brak danych' : (props.userData.oneWeekChangeCurrency >= 0 ? `+${props.addZeroes((props.userData.oneWeekChangeCurrency).toString())}$` : `${(props.userData.oneWeekChangeCurrency).toFixed(2)}$`) ) : 'brak danych') :
                        '***** $'
                    }
                    </span>
                    <span className={(Number.isNaN(props.userData.oneWeekChangePercentage) ? style.percentLabelValueDefault : (props.userData.oneWeekChangePercentage >= 0 ? style.percentLabelValuePlus : style.percentLabelValueMinus))}>
                    {
                        (props.visibility == 2 || props.visibility == 1) ? 
                        (props.userData ? (Number.isNaN(props.userData.oneWeekChangePercentage) ? '' : (props.userData.oneWeekChangePercentage >= 0 ? `(+${(props.userData.oneWeekChangePercentage).toFixed(2)}%)` : `(${(props.userData.oneWeekChangePercentage).toFixed(2)}%)`) ) : '') :
                        ''
                    }
                    </span>
                </div>
                <div className={style.insideCardHeader}>
                    Zmiana 30D
                </div>
                <div className={style.insideCardBody}> 
                    <span className={(Number.isNaN(props.userData.oneMonthChangeCurrency) ? style.currecyLabelValueNaN : style.currecyLabelValue)}>
                    {
                        props.visibility == 2 ? 
                        (props.userData ? (Number.isNaN(props.userData.oneMonthChangeCurrency) ? 'brak danych' : (props.userData.oneMonthChangeCurrency >= 0 ? `+${props.addZeroes((props.userData.oneMonthChangeCurrency).toString())}$` : `${(props.userData.oneMonthChangeCurrency).toFixed(2)}$`) ) : 'brak danych') :
                        '***** $'
                    }
                    </span>
                    <span className={(Number.isNaN(props.userData.oneMonthChangePercentage) ? style.percentLabelValueDefault : (props.userData.oneMonthChangePercentage >= 0 ? style.percentLabelValuePlus : style.percentLabelValueMinus))}>
                    {
                        (props.visibility == 2 || props.visibility == 1) ? 
                        (props.userData ? (Number.isNaN(props.userData.oneMonthChangePercentage) ? '' : (props.userData.oneMonthhangePercentage >= 0 ? `(+${(props.userData.oneMonthChangePercentage).toFixed(2)}%)` : `(${(props.userData.oneMonthChangePercentage).toFixed(2)}%)`) ) : '') :
                        ''
                    }
                    </span>
                </div>
            </Card.Body>
            <Card.Footer>
                <LastUpdate userData={props.userData}/>
            </Card.Footer>
        </Card> : <span>loading...</span>
    )
}
