import React, { useEffect, useState } from 'react'
import { ListGroup, Row, Col } from 'react-bootstrap'

import { Link } from 'react-router-dom'

import style from './AccountPortfolioList.module.css'

export default function AccountPortfolioList(props) {

    return (
        <ListGroup>
        {
            props.portfolioCoinsValues ?
            props.portfolioCoinsValues.map(coin => {
                return (
                    <Link to={`/panel/coin/${coin.coingeckoID}`} key={coin.coingeckoID}>
                        <ListGroup.Item key={coin.coingeckoID} className="d-flex flex-column align-items-center justify-content-between">
                            <div id={`${coin.coingeckoID}-first`} className="d-flex flex-row align-items-center justify-content-between w-100"> 
                                <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center'>
                                    <img className={`${style.cryptoLogo} mt-1 mt-md-0`} src={coin.logo} style={{marginRight: '10px', borderRadius: '5px'}} />
                                    <div className="d-flex flex-column flex-md-row">
                                        <span className="mt-2 mt-md-0"><b>{coin.fullName}</b><span className="d-none d-md-inline-flex" style={{marginLeft: '5px', marginRight: '12px'}}>({(coin.shortName).toUpperCase()})</span></span>
                                        <span><span className="d-none d-md-inline-flex" style={{marginRight: '4px'}}>|</span> {props.visibility == 2 ? (((coin.wallet) > 1 && (coin.wallet % 1 == 0)) ? props.numberWithCommas(coin.wallet) : coin.wallet.toFixed(8)) : '*****'} {((coin.shortName).toUpperCase())}</span>
                                    </div>
                                </div>
                                <div>
                                    <span className={style.cryptoValueInUSD}>~ {props.visibility == 2 ? props.addZeroes((coin.valueInUSD).toString()) : '*****'} $</span>
                                </div>
                            </div>
                            <div id={`${coin.coingeckoID}-second`} className={`d-flex flex-row align-items-center justify-content-between w-100 container px-0 ${style.secondRow}`}>
                                <Row className="w-100">
                                    <Col xs={12}>
                                        Obecny kurs: <span>{coin.rate && coin.rate} $ (<span className={`ms-1 ${coin.oneDayChange && coin.oneDayChange >= 0 ? style.littleUp : style.littleDown}`}>{coin.oneDayChange && coin.oneDayChange > 0 ? '+' : ''}{coin.oneDayChange && coin.oneDayChange.toFixed(2)}%</span>) </span>
                                    </Col>
                                </Row>
                            </div>
                        </ListGroup.Item>
                    </Link>
                )
            }) : 'brak wybranych kryptowalut'
        }
        </ListGroup>
    )
}