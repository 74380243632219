import React, { useState , useEffect} from 'react'
import AccountOverviewWallet from '../../components/AccountOverviewWallet/AccountOverviewWallet'
import LastUpdate from '../../components/LastUpdate/LastUpdate'
import AccountPortfolioListOverview from '../../components/AccountPortfolioListOverview/AccountPortfolioListOverview'
import AccountPortfolioList from '../../components/AccountPortfolioList/AccountPortfolioList'
import MainOverviewOfAccount from '../../components/MainOverviewOfAccount/MainOverviewOfAccount'
import LastTransactions from '../../components/LastTransactions/LastTransactions'
import BestAndWorstCoin from '../../components/BestAndWorstCoin/BestAndWorstCoin'
import TotalProfitBox from '../../components/TotalProfitBox/TotalProfitBox'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import Loader from '../../components/Loader/Loader'
import style from './PanelMain.module.css'

import { useAuth } from '../../contexts/AuthContext'
import TotalPortfolioChart from '../../components/TotalPortfolioChart/TotalPortfolioChart'
import TotalCashoutBox from '../../components/TotalCashoutBox/TotalCashoutBox'
import TotalCashinBox from '../../components/TotalCashinBox/TotalCashinBox'
import UnrealizedPnl from '../../components/UnrealizedPnl/UnrealizedPnl'
import { ToastContainer, toast } from 'react-toastify';

export default function PanelMain(props) {
    const { currentUser } = useAuth()

    function generatePDFRaport(uid, mail){
        props.sendRaportToEmail(uid, mail)
    }

    // useEffect(() => {
    //     props.chartData && props.chartData.datasets.length > 0 && setShow(true)
    // }, [props.chartData])

    useEffect(() => {
        props.changeRangeOfChart(props.chartRange[4])
    }, [])

    return (
        <Container>
            <Row style={{marginTop: '50px'}}>
                <Col xs={12} md={8} className="d-flex align-items-center justify-content-center justify-content-md-start">
                    <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
                </Col>
                <Col xs={12} md={4} className="align-items-center justify-content-center justify-content-md-end d-flex flex-row mt-4 mt-md-0" style={{position: 'relative'}}>
                    <div className={`${style.toolTipPDF}`} onClick={() => generatePDFRaport(currentUser.uid, currentUser.email)}><span>Wyślij raport na mail<div className={style.arrowToolTip}></div></span></div>
                </Col>
            </Row>
            <Row style={{marginTop: '50px'}}>
                <Col xs={12} md={4}>
                    <MainOverviewOfAccount addZeroes={props.addZeroes} visibility={props.visibility} userData={props.userData} portfolioCoinsValues={props.portfolioCoinsValues} />
                </Col>
                <Col xs={12} md={8} className="mt-4 mt-md-0">
                    <Card>
                        <Card.Header className="">
                            Wykres wartości portfela
                        </Card.Header>
                        <Card.Body>
                            {   (!props.loadingChart && props.chartData && props.chartData.datasets.length > 0) ? 
                                props.visibility == 2 && props.chartData ? 
                                props.chartData && <TotalPortfolioChart changeRangeOfChart={props.changeRangeOfChart} loadingChart={props.loadingChart} chartData={props.chartData} chartRange={props.chartRange} chartOptions={props.chartOptions} totalPortfolioValueHistory={props.totalPortfolioValueHistory} currentChartRange={props.currentChartRange} /> :  
                                props.chartData && <TotalPortfolioChart changeRangeOfChart={props.changeRangeOfChart} chartData={props.chartData} chartRange={props.chartRange} chartOptions={props.hideChartOptions} totalPortfolioValueHistory={props.totalPortfolioValueHistory} loadingChart={props.loadingChart} currentChartRange={props.currentChartRange} />
                                :
                                <div style={{paddingBottom: '50px'}}>
                                    <h4 style={{display: 'flex', justifyContent: 'center', marginTop: '50px', marginBottom: '25px', textAlign: 'center'}}>
                                        Generowanie wykresu...
                                    </h4>                        
                                    <Loader />                        
                                </div>
                            }
                        </Card.Body>
                        <Card.Footer>
                            <LastUpdate userData={props.userData} />
                        </Card.Footer>
                    </Card>
                </Col>
                <Col xs={12} md={3}>
                    <TotalProfitBox
                        userData={props.userData}
                        visibility={props.visibility}
                    />
                </Col>
                <Col xs={12} md={3}>
                    <TotalCashinBox
                        userData={props.userData}
                        numberWithCommas={props.numberWithCommas}
                        visibility={props.visibility}
                        addZeroes={props.addZeroes}
                    />
                </Col>
                <Col xs={12} md={3}>
                    <TotalCashoutBox
                        userData={props.userData}
                        numberWithCommas={props.numberWithCommas}
                        visibility={props.visibility}
                        addZeroes={props.addZeroes}
                    />
                </Col>
                <Col xs={12} md={3}>
                    <UnrealizedPnl
                        userData={props.userData}
                        numberWithCommas={props.numberWithCommas}
                        visibility={props.visibility}
                        addZeroes={props.addZeroes}
                    />
                </Col>
                <Col xs={12} md={7} className="mt-4">
                    <Row className="d-flex flex-column">
                        <Col>
                            <Card>
                                <Card.Header className="">
                                    <AccountPortfolioListOverview userData={props.userData} />
                                </Card.Header>
                                <Card.Body>
                                    <AccountPortfolioList addZeroes={props.addZeroes} numberWithCommas={props.numberWithCommas} portfolioCoinsValues={props.portfolioCoinsValues} visibility={props.visibility} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={5} className="mt-4">
                    { props.allTransactions && props.portfolioCoinsValues && <LastTransactions portfolioCoinsValues={props.portfolioCoinsValues} convertDateToHumanFriendlyDATE={props.convertDateToHumanFriendlyDATE} fetchAllTransactions={props.fetchAllTransactions} visibility={props.visibility} convertDateToHumanFriendly={props.convertDateToHumanFriendly} allTransactions={props.allTransactions} numberWithCommas={props.numberWithCommas} userData={props.userData} /> }
                    <BestAndWorstCoin
                        visibility={props.visibility}
                        biggestProfitCoin={props.biggestProfitCoin}
                        biggestLostCoin={props.biggestLostCoin}
                        biggestLostCurrencyCoin={props.biggestLostCurrencyCoin}
                        biggestProfitUsdCoin={props.biggestProfitCurrencyCoin}
                        mostTransactionCoin={props.mostTransactionCoinState}
                        mostInvestedUSDCoin={props.mostInvestedUSDCoin}
                        mostValueCoin={props.mostValueCoin}
                        lowValueCoin={props.lowValueCoin}
                    />
                </Col>
            </Row>
        </Container>
    )
}
