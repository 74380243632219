import React, { useEffect } from 'react'
import { ListGroup } from 'react-bootstrap'

import style from './EditUsersModule.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faCheck, faFile } from '@fortawesome/free-solid-svg-icons'

export default function EditUsersModule(props) {

    useEffect(() => {
    }, [props.shortUsersInfo])

    const changeUserAdmin = (uid) => {
        props.changeUserAdminStatus(uid)
    }

    const changeUserActive = (uid) => {
        props.changeUserActiveStatus(uid)
    }

    const changeUserReportStatus = (uid) => {
        props.changeUserReportStatus(uid)
    }

    return (
        <ListGroup>
            {
                props.shortUsersInfo && props.shortUsersInfo.map((user,idx) => {
                    return(
                        <ListGroup.Item key={idx} className="d-flex flex-row justify-content-between align-items-center px-0 py-0">
                            <div className="d-flex flex-row">
                                <div className={style.idxUsr}>{idx+1}</div>
                                <span className={style.usrNick}>{user.nick}</span>
                            </div>
                            <div className={style.btnsEditUsr}>
                                <div className={style.btnEditUser} onClick={() => changeUserReportStatus(user.uid)}>
                                    <FontAwesomeIcon icon={faFile} className={`${!user.autoRaports && style.off}`} />
                                </div>
                                <div className={style.btnEditUser} onClick={() => changeUserAdmin(user.uid)}>
                                    <FontAwesomeIcon icon={faLock} className={`${!user.admin && style.off}`} />
                                </div>
                                <div className={style.btnEditUser} onClick={() => changeUserActive(user.uid)}>
                                    <FontAwesomeIcon icon={faCheck} className={`${!user.active && style.off}`} />
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })
            }
        </ListGroup>
    )
}
