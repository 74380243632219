import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'

import style from './AddNewCrypto.module.css'

export default function AddNewCrypto(props) {
  const [activeButton, setActiveButton] = useState(false)
  const [searchedCrypto, setSearchedCrypto] = useState()

  const handleCryptoInput = (e) => {
    setSearchedCrypto(e.target.value)
    if((e.target.value).length >= 3) setActiveButton(true)
    else setActiveButton(false)
  }

  const handleSubmitCheckCrypto = async (e) => {
    e.preventDefault()
    try{
      await props.checkIfCryptoExistInCoingeckoApi(searchedCrypto)
    }
    catch(e){
      console.log(e)
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmitCheckCrypto}>
        <Form.Label>Podaj nazwę kryptowaluty, aby sprawdzić jej poprawność<br /><small className="text-muted">(podaj identyfikator API z coingecko, np. dla XRP jest to 'ripple')</small></Form.Label>
        <Form.Control type="text" placeholder='np. bitcoin, metahero, solana' onChange={handleCryptoInput} required></Form.Control>
        <Button variant="outline-secondary" type="submit" className="btn-sm mt-2 w-100" disabled={!activeButton}>
          Sprawdź
        </Button>
      </Form>
      { props.existInCoingecko ? 
        <div className={style.foundedCryptoBox}>
          <img src={props.searchedCryptoToAdd.image.large} className={style.foundedCryptoImg} />
          <span>Znalezionio: <b>{props.searchedCryptoToAdd.name}</b></span>
          <Button variant="outline-secondary" className="btn-sm mt-2" onClick={props.addNewCryptoToDatabase}>Dodaj kryptowalutę do bazy danych</Button>
          </div> : 
          props.searched ? 
          <div className={style.foundedCryptoBox}>
            <center>Nie znaleziono danej kryptowaluty lub jest ona już dodana do bazy danych.</center>
          </div> : null
        }
    </div>
  )
}
