import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'

import style from './UnrealizedPnl.module.css'

export default function UnrealizedPnl(props) {
    
    return (
        <Card className="mt-4">
            <Card.Header>
                Aktywne futures (PNL)
            </Card.Header>
            <Card.Body className={style.totalProfitBox}>
            <span>
                {props.userData && props.visibility == 2 ? props.userData.futureUnrealizedPNL && props.addZeroes(props.numberWithCommas(props.userData.futureUnrealizedPNL.toFixed(2))) : '*****'}$
            </span>
            </Card.Body>
        </Card>
    )
}
