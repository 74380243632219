import React, { useEffect, useState } from 'react'

import { Container, Row, Col, Collapse, Button, ListGroupItem, ListGroup } from 'react-bootstrap'
import { useParams, Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';

import style from './CoinSingle.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileLines, faCopy, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import LastUpdate from '../../components/LastUpdate/LastUpdate';

import Moment from 'react-moment';
import { useAuth } from '../../contexts/AuthContext';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import CryptoConverter from '../../components/CryptoConverter/CryptoConverter';

export default function CoinSingle(props) {
    const { currentUser } = useAuth()

    const { coinid } = useParams()
    const [open, setOpen] = useState(true);
    const [descLen, setDescLen] = useState(0)
    const [ statsViewOpt, setStatsViewOpt ] = useState(1)
    const [ tableData, setTableData ] = useState([])
    const [ allTableData, setAllTableData ] = useState([])
    const [ longNameCurrentCoin, setLongNameCurrentCoin ] = useState()
    const [ coinDesc, setCoinDesc ] = useState()

    const notify = (adrToCopy, net) => {
        navigator.clipboard.writeText(adrToCopy); 
        toast(
            `Skopiowano adres kontraktu ${actualCoin && actualCoin.fullName.toUpperCase()} dla sieci ${net} do schowka.`, {
            autoClose: 5000,
            type: 'info'
        });
    }

    useEffect(() => {
        try{
                let output = []
                setAllTableData()
                console.log(props.transactionsOfCoin)
                props.transactionsOfCoin && props.transactionsOfCoin.map(async (transaction, index) => {
                        let temp
                                setLongNameCurrentCoin('')
                                temp = {
                                    id: index+1,
                                    coin: transaction.coinFullName,
                                    date: props.visibility == 2 ? props.convertDateToHumanFriendly(transaction.date) : '**:** **.**.****',
                                    amount: (transaction.type != 'cashIn' && transaction.type != 'cashOut' ? props.visibility == 2 ? ((transaction.amount) > 1 && (transaction.amount % 1 == 0)) ? props.numberWithCommas(transaction.amount) : transaction.amount : '*****' : null),
                                    rate: props.visibility == 2 ? transaction.rate : '*****',
                                    type: ((transaction.futures) == 0 || transaction.futures == NaN || transaction.futures == undefined) ? (transaction.type) == 'buy' ? <span style={{color: 'rgb(33, 191, 115)', fontWeight: '600'}}>ZAKUP</span> : (transaction.type) == 'sell' ? <span style={{color: '#dc3545', fontWeight: '600'}}>SPRZEDAŻ</span> : (transaction.type) == 'transfer' ? <span style={{color: '#0d6efd', fontWeight: '600'}}>TRANSFER</span> : (transaction.type) == 'cashIn' ? <span style={{fontWeight: '600'}}>WPŁATA</span> : (transaction.type) == 'cashOut' ? <span style={{fontWeight: '600'}}>WYPŁATA</span> : '?' : (transaction.type) == 'sell' ? 'short' : <span style={{color: '#198754', fontWeight: '600'}}>LONG x{transaction.futures}</span>,
                                    totalValue: (props.visibility == 2 ? `$ ${props.numberWithCommas((parseFloat(transaction.amount) * (transaction.type != 'cashIn' && transaction.type != 'cashOut' ? parseFloat(transaction.rate) : 1)).toFixed(2))}` : '$ *****'),
                                }
                                output.push(temp)
                    })
    
                    console.log(output)
                    setAllTableData(output)
            }
        catch(e){
            console.log(e)
        }
    }, [ props.transactionsOfCoin, props.visibility ])

    useEffect(() => {
        props.getAllTransactionBySpecificCoin(coinid)
    }, [coinid])

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            maxWidth: '30px'
        },
        {
            name: 'Typ',
            selector: row => row.type,
            sortable: true,
            minWidth: '125px'
        },
        {
            name: 'Data',
            selector: row => row.date,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Kryptowaluta',
            selector: row => row.coin,
            sortable: true,
            minWidth: '125px'
        },
        {
            name: 'Ilość',
            selector: row => row.amount,
            sortable: true,
            minWidth: '125px'
        },
        {
            name: 'Kurs',
            selector: row => row.rate,
            sortable: true,
            minWidth: '125px'
        },
        {
            name: 'Koszt (w USD)',
            selector: row => row.totalValue,
            sortable: true,
            minWidth: '125px'
        }
    ];

    const [ actualCoin, setActualCoin ] = useState()

    useEffect(() => {

        window.scrollTo(0, 0)
        props.coinsData && props.portfolioCoinsValues && props.coinsData.map(coin => {
            if(coin.coingeckoID == coinid){
                console.log(coin.coingeckoID)
                props.portfolioCoinsValues.map(coinLocal => {
                    if(coinid == coinLocal.coingeckoID){
                        let coiny = {...coinLocal, coin}
                        console.log(coiny)
                        setDescLen(200)
                        setActualCoin(coiny)
                        // coiny.numberOfAllFuturesOrdersLEV > coiny.numberOfAllTransactions ? setStatsViewOpt(2) : setStatsViewOpt(1)
                    }
                })
            }
        })
    }, []);

    return (
        <Container>
            <ScrollToTop />
            <ToastContainer />
            <Row style={{marginTop: '50px'}}>
                <Col xs={12} md={12} className="d-flex align-items-start justify-content-center flex-column">
                    <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
                    <h6 style={{fontWeight: '400'}}>Aktualne informacje o <b>#{coinid}</b></h6>
                </Col>
            </Row>
            <Row className={style.coinMainRow}>
                <Col xs={12}>
                    <Row>
                        <Col xs={12} md={4} className={`${style.coinBG} d-flex align-items-center`} style={{background: `url(${actualCoin && actualCoin.logo})`}}>
                            <div className="d-flex flex-row align-items-center justify-content-start mb-0" style={{zIndex: '10'}}>
                                <img src={actualCoin && actualCoin.logo} style={{maxWidth: '50px'}} />
                                <h2 className="ps-3 pe-3 d-flex flex-column align-items-start justify-content-center"><span style={{opacity: '0.7', fontSize: '14px'}}>({actualCoin && actualCoin.shortName.toUpperCase()})</span><span>{actualCoin && actualCoin.fullName}</span></h2>
                            </div>
                            <div className={style.backdropOfCoin}></div>
                        </Col>
                        <Col xs={12} md={8}>
                            <Row>
                                <Col xs={12} md={8}>
                                    <Row>
                                        <Col xs={12} md={6} className={style.smallFirstInfo}>
                                            <p>Aktualny kurs<br /><strong style={{fontSize: '20px', paddingTop: '5px', display: 'inline-block'}}>{actualCoin && actualCoin.rate} USD</strong></p>
                                        </Col>
                                        <Col xs={12} md={6} className={style.smallFirstInfo}>
                                            <p>Zmiana 24H<br/><strong style={{fontSize: '20px', paddingTop: '5px', display: 'inline-block'}}>{actualCoin && actualCoin.oneDayChange && actualCoin.oneDayChange.toFixed(8)} ({actualCoin && actualCoin.coin.oneDayChangePercentage && actualCoin.coin.oneDayChangePercentage.toFixed(2)})%</strong></p>
                                        </Col>
                                        <Col xs={12}>
                                            <Row style={{width: '100%'}}>
                                                <Col xs={6} sm={6} md={6} lg={3}>
                                                    <div className={style.littleWindow}>
                                                        <h5>7 DNI</h5>
                                                        <span className={actualCoin ? actualCoin.coin.sevenDaysChangePercentage == 0 ? style.normal : actualCoin && actualCoin.coin.sevenDaysChangePercentage >= 0 ? style.up : style.down : ' ... '}>{actualCoin && actualCoin.coin.sevenDaysChangePercentage && actualCoin.coin.sevenDaysChangePercentage.toFixed(2)}%</span>
                                                    </div>
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={3}>
                                                    <div className={style.littleWindow}>
                                                        <h5>14 DNI</h5>
                                                        <span className={actualCoin ?  actualCoin.coin.fourteenDaysChangePercentage == 0 ? style.normal : actualCoin && actualCoin.coin.fourteenDaysChangePercentage >= 0 ? style.up : style.down : ' ... '}>{actualCoin && actualCoin.coin.fourteenDaysChangePercentage && actualCoin.coin.fourteenDaysChangePercentage.toFixed(2)}%</span>
                                                    </div>
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={3}>
                                                    <div className={style.littleWindow}>
                                                        <h5>30 DNI</h5>
                                                        <span className={actualCoin ?  actualCoin.coin.thirtyDaysChangePercentage == 0 ? style.normal : actualCoin && actualCoin.coin.thirtyDaysChangePercentage >= 0 ? style.up : style.down : ' ... '}>{actualCoin && actualCoin.coin.thirtyDaysChangePercentage && actualCoin.coin.thirtyDaysChangePercentage.toFixed(2)}%</span>
                                                    </div>
                                                </Col>
                                                <Col xs={6} sm={6} md={6} lg={3}>
                                                    <div className={style.littleWindow}>
                                                        <h5>1 ROK</h5>
                                                        <span className={actualCoin ?  actualCoin.coin.oneYearChangePercentage == 0 ? style.normal : actualCoin && actualCoin.coin.oneYearChangePercentage >= 0 ? style.up : style.down : ' ... '}>{actualCoin && actualCoin.coin.oneYearChangePercentage && actualCoin.coin.oneYearChangePercentage.toFixed(2)}%</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} md={4} className={`${style.smallFirstInfo} ${style.smallFirstInfoCol} mt-3 mt-md-0`}>
                                    <p className="d-flex justify-content-center pb-3 mb-0"><span>Low 24H: </span><strong className="ms-2">{actualCoin && actualCoin.coin.low_24h && actualCoin.coin.low_24h.toFixed(8)}</strong></p>
                                    <p className="d-flex justify-content-center py-2 mb-0"><span>High 24H: </span><strong className="ms-2">{actualCoin && actualCoin.coin.high_24h && actualCoin.coin.high_24h.toFixed(8)}</strong></p>
                                    <p className="d-flex justify-content-center pt-3 mb-0"><span>Różnica: </span><strong className="ms-2">{actualCoin && actualCoin.coin.low_24h && actualCoin.coin.high_24h  && Math.abs((1-(actualCoin.coin.low_24h/actualCoin.coin.high_24h))*100).toFixed(2)}%</strong></p>
                                </Col>
                            </Row>
                        </Col> 
                    </Row>

                    <div className={style.firstInfoUpd}>
                        <LastUpdate userData={props.userData} />
                    </div>

                    <Row className="mt-4">
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={4} md={4}><span className={`${style.sfaBtn} ${statsViewOpt == 1 && style.sfaBtnActive}`} onClick={() => setStatsViewOpt(1)}>SPOT</span></Col>
                                <Col xs={4} md={4}><span className={`${style.sfaBtn} ${statsViewOpt == 2 && style.sfaBtnActive}`} onClick={() => setStatsViewOpt(2)}>FUTURES</span></Col>
                                <Col xs={4} md={4}><span className={`${style.sfaBtn} ${statsViewOpt == 3 && style.sfaBtnActive}`} onClick={() => setStatsViewOpt(3)}>MIX</span></Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6} className="mt-4 mt-md-0">
                            <Row>
                                {/* <Col xs={12} className="mt-2">
                                    <h4 className="mt-4 mb-4">Dodaj nową transackję</h4>
                                </Col> */}
                                <Col xs={12} md={actualCoin && actualCoin.wallet == 0 ? 6 : 4} className={`${style.miniActionBox} ${style.miniActionBoxB} mt-4 mt-md-0`}>
                                    <Link to={`/panel/transaction/${actualCoin && actualCoin.coingeckoID}/buy`}>
                                        <div>
                                            Kup <strong>{actualCoin && actualCoin.shortName.toUpperCase()}</strong>
                                        </div>
                                    </Link>
                                </Col>
                                <Col xs={12} md={actualCoin && actualCoin.wallet == 0 ? 0 : 4} className={`${style.miniActionBox} mt-4 mt-md-0 ${style.miniActionBoxS} ${actualCoin && actualCoin.wallet == 0 && style.noneCoins}`}>
                                    {actualCoin && actualCoin.wallet != 0 && <Link to={`/panel/transaction/${actualCoin && actualCoin.coingeckoID}/sell`}>
                                        <div>
                                            Sprzedaj <strong>{actualCoin && actualCoin.shortName.toUpperCase()}</strong>
                                        </div>
                                    </Link> }
                                </Col>
                                <Col xs={12} md={actualCoin && actualCoin.wallet == 0 ? 6 : 4} className={`${style.miniActionBox} ${style.miniActionBoxT} mt-4 mt-md-0`}>
                                    <Link to={`/panel/transaction/${actualCoin && actualCoin.coingeckoID}/transfer`}>
                                        <div>
                                            Dodaj <strong>{actualCoin && actualCoin.shortName.toUpperCase()}</strong>
                                        </div>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6} className={(statsViewOpt == 1 || statsViewOpt == 3) ? 'd-block' : 'd-none'}>
                            <h4 className="mt-5 mb-4">Na podstawie twojego portfela <span className='text-muted'>(SPOT)</span></h4>
                            <p>Część portfela: <strong>{actualCoin && props.visibility == 2 ? actualCoin.perCentOfWallet : '***** '}%</strong></p>
                            <p>Ilość posiadanych monet: <strong>{actualCoin && props.visibility == 2 ? (actualCoin.wallet >= 1 ? props.numberWithCommas(actualCoin.wallet) : actualCoin.wallet) : '*****'}</strong></p>
                            <p>Obecna wartość posiadanych monet: <strong>{actualCoin && props.visibility == 2 ? actualCoin.valueInUSD && actualCoin.valueInUSD.toFixed(2) : '***** '}$</strong></p>
                            <p>Zysk/Strata:&nbsp;
                            { actualCoin && actualCoin.numberOfAllTransactions != 0 ?
                            <><strong className={actualCoin && props.visibility == 2 ? parseFloat(actualCoin.totalProfitPercentage) != 0 ? (parseFloat(actualCoin.totalProfitPercentage) > 0 ? style.aboutWalletUp : style.aboutWalletDown) : style.aboutWalletNormal : ''}>{actualCoin && props.visibility == 2 ? actualCoin.totalProfitCurrency.toFixed(2) : '***** '}$ ({actualCoin && (props.visibility == 2 || props.visibility == 1) ? actualCoin.totalProfitPercentage.toFixed(2) : '*****'}%)</strong></>
                            : <span className='text-muted ms-1'>brak danych</span> }
                            </p>
                            <p>Ostatnia transakcja&nbsp;
                            { actualCoin && actualCoin.numberOfAllTransactions != 0 ?
                                <>
                                <strong>{actualCoin && actualCoin.coin.shortName.toUpperCase()}</strong> wykonana
                                {
                                props.visibility == 2 ?
                                <Moment className="ms-1" style={{fontWeight: '500'}} fromNow locale='pl' interval={1000}>
                                    {actualCoin && new Date(actualCoin.lastTransactionDate)}
                                </Moment>
                                : <strong className="ms-1">*****</strong>
                                } </>
                            : <span className='text-muted ms-1'>brak danych</span> }
                            </p>

                            <h4 className="mt-5 mb-4">Twoje statystyki <strong>{actualCoin && actualCoin.fullName}</strong> <span className='text-muted'>(SPOT)</span></h4>
                            { actualCoin && actualCoin.numberOfAllTransactions != 0 ?
                            <>
                            <p>Średni kurs kupna: <strong>{actualCoin && (props.visibility == 2 ? actualCoin.avgBuyRate && actualCoin.avgBuyRate.toFixed(8) : ' *****')}</strong></p>
                            <p>Średni kurs sprzedaży: <strong>{actualCoin && (props.visibility == 2 ? parseFloat(actualCoin.avgSellRate) > 0 ? actualCoin && actualCoin.avgSellRate && actualCoin.avgSellRate.toFixed(8) : 'brak' : '*****')}</strong></p>
                            <p>Ilośc wszystkich transakcji: <strong>{actualCoin && props.visibility == 2 ? actualCoin.numberOfAllTransactions : '*****'}</strong></p>
                            <p>Ilośc transakcji kupna: <strong>{actualCoin && props.visibility == 2 ? actualCoin.numberOfBuys : '*****'}</strong></p>
                            <p>Ilośc transakcji sprzedaży: <strong>{actualCoin && props.visibility == 2 ? actualCoin.numberOfSells : '*****'}</strong></p>
                            <p>Pierwszy zakup: <strong>{actualCoin && props.visibility == 2 ? props.convertDateToHumanFriendly(actualCoin.firstTransactionDate) : '*****'}</strong></p>
                            <p>Ostatni zakup: <strong>{actualCoin && props.visibility == 2 ? props.convertDateToHumanFriendly(actualCoin.lastTransactionDate) : '*****'}</strong></p>
                            </>
                            : <span className="text-muted">brak danych</span> }
                        </Col>

                        <Col xs={12} md={6} className={(statsViewOpt == 2 || statsViewOpt == 3) ? 'd-block' : 'd-none'}>
                            <h4 className="mt-5 mb-4">Na podstawie twojego portfela <span className='text-muted'>(FUTURES)</span></h4><p>Zysk/Strata:&nbsp;
                            { actualCoin && 
                            <>
                                <strong className={
                                    actualCoin && actualCoin.futurePNLClosedPositionProfit && actualCoin.futureUnrealizedPNL && props.visibility == 2 ? 
                                    (parseFloat(actualCoin.futureUnrealizedPNL)+parseFloat(actualCoin.futurePNLClosedPositionProfit)) != 0 ? 
                                        ((parseFloat(actualCoin.futureUnrealizedPNL)+parseFloat(actualCoin.futurePNLClosedPositionProfit)) > 0 ? style.aboutWalletUp : style.aboutWalletDown) : 
                                    style.aboutWalletNormal : ''}>
                                        {actualCoin && props.visibility == 2 ? (parseFloat(actualCoin.futureUnrealizedPNL)+parseFloat(actualCoin.futurePNLClosedPositionProfit)).toFixed(2) : '***** '}$
                                </strong>
                            </>
                            // : <span className='text-muted ms-1'>brak danych</span> 
                            }
                            </p>
                        </Col>
                        <Col xs={12} md={6}>
                            <Row>
                                <h4 className="mt-5 mb-4">Kalkulator <strong>{actualCoin && actualCoin.coin.shortName.toUpperCase()}</strong></h4>
                                <CryptoConverter
                                    coinId={coinid}
                                />

                                <h4 className="mt-5 mb-4">O tokenie <strong>{actualCoin && actualCoin.coin.shortName.toUpperCase()}</strong></h4>
                                <ListGroup>
                                    <ListGroup.Item className={style.aboutTokenListItem}>
                                        <div className={`d-none d-md-flex ${style.aboutTokenChange}`}>
                                            <span>{actualCoin && actualCoin.coin.athChangePercentage && actualCoin.coin.athChangePercentage.toFixed(2)}%</span>
                                        </div>
                                        <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                            <div className="d-flex flex-column">
                                                <span>Najwyższy historyczny kurs</span>
                                                <span className={style.rate}><strong>{actualCoin && actualCoin.coin.athRate} USD</strong></span>
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <span style={{fontSize: '13px', opacity: '0.75'}}>
                                                    {actualCoin && props.convertDateToHumanFriendly(new Date(actualCoin.coin.athDate).valueOf())}
                                                </span>
                                                <span style={{fontSize: '13px', opacity: '0.75'}}>
                                                    {
                                                        actualCoin && 
                                                        <Moment className="ms-1" style={{fontWeight: '500'}} fromNow locale='pl' interval={1000}>
                                                            {actualCoin && new Date(actualCoin.coin.athDate)}
                                                        </Moment>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    {actualCoin && actualCoin.coin.contractAddress &&
                                    <ListGroup.Item className={style.aboutTokenListItem}>
                                        <div className="d-inline-block flex-row align-items-center justify-content-center w-100" style={{width: '100%'}}>
                                            <div className="d-flex flex-column">
                                                <span>Adres Kontraktu</span>
                                                <ListGroup className="w-100 mt-3">
                                                {
                                                        (actualCoin && actualCoin.coin.platforms != null) && (actualCoin.coin.platforms && Object.keys(actualCoin.coin.platforms).map((coin, index) => {
                                                            return <ListGroup.Item style={{fontSize: '14px'}} key={index} className="d-flex flex-row justify-content-between align-items-center">
                                                                <div className="d-flex justify-content-start">{coin}: <strong className="ms-1">{actualCoin.coin.platforms[coin].length > 15 ? actualCoin.coin.platforms[coin].substring(0, 15)+"..." : actualCoin.coin.platforms[coin]}</strong></div>
                                                                <div>
                                                                    <FontAwesomeIcon icon={faCopy} className={style.copyCA} onClick={() => notify(actualCoin.coin.platforms[coin], coin)} />
                                                                </div>
                                                            </ListGroup.Item>
                                                        }))
                                                    }
                                                </ListGroup>
                                                <span className={style.links}>
                                                    
                                                </span>
                                            </div>
                                            
                                        </div>
                                    </ListGroup.Item>}  
                                    <ListGroup.Item className={style.aboutTokenListItem}>
                                        <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                            <div className="d-flex flex-column">
                                                <span>Oficjalny link do strony projektu</span>
                                                <span className={style.links}>
                                                    <strong><a target='_blank' href={actualCoin && actualCoin.coin.linkToHomepage && actualCoin.coin.linkToHomepage[0]}>{actualCoin && actualCoin.coin.linkToHomepage && actualCoin.coin.linkToHomepage[0]}</a></strong>
                                                </span>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={`${style.aboutTokenListItem} d-flex flex-column`}>
                                        <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                            <div className="d-flex flex-column">
                                                <span><strong>Opis projektu</strong></span>
                                                <div className={style.links} style={{textAlign: 'justify', marginTop: '10px'}}>
                                                    <p className={style.collapsed}>{descLen && actualCoin && actualCoin.coin.desc && actualCoin.coin.desc.length < 5 ? <span className="text-muted" style={{marginLeft: '-20px'}}>brak</span> : actualCoin && (actualCoin.coin.desc) && (actualCoin.coin.desc).substr(0,descLen)}<span style={{display: (open && descLen > 200) ? 'inline' : 'none'}}>...</span></p>
                                                    <Button onClick={function(){ if(open){ setOpen(false); console.log(descLen); setDescLen(actualCoin.coin.desc.length)} else{setOpen(true); setDescLen(200)}}} aria-controls="example-collapse-text" className={`btn-sm w-100 ${actualCoin && actualCoin.coin.desc && actualCoin.coin.desc.length < 200 ? 'd-none' : 'd-block'}`} aria-expanded={open} variant="outline-secondary">
                                                        {
                                                            open ? 
                                                            <span><FontAwesomeIcon icon={faCaretDown} className="me-1" /> Kliknij, aby zobaczyć opis kryptowaluty</span>
                                                            : <span><FontAwesomeIcon icon={faCaretUp} className="me-1" /> Kliknij, aby zwinąć opis</span>
                                                        }
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={style.aboutTokenListItem}>
                                        <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                            <div className="d-flex flex-column">
                                                <span className="mb-2">Kategorie</span>
                                                <div className={style.cats}>
                                                    {
                                                        actualCoin && actualCoin.coin.categories && actualCoin.coin.categories.map((cat, index) => {
                                                            return cat != null && <span key={index} className={style.cat}>{cat}</span>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                                <h6 className="mt-0" style={{fontWeight: '400', opacity: '0.75', textAlign: 'right'}}>
                                    <LastUpdate userData={props.userData} />
                                </h6>
                            </Row>
                        </Col>
                        <Col xs={12} className={style.boxinTabin}>
                            <h4 className="mt-5 mb-4">Ostatnie transakcje <strong>{actualCoin && actualCoin.fullName}</strong></h4>
                            { allTableData && (allTableData.length) ? <DataTable columns={columns} data={allTableData} /> : <span className="text-muted">brak danych</span> }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
