import React, { useEffect, useState } from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import LastUpdate from '../LastUpdate/LastUpdate'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightLeft, faArrowDown, faArrowUp, faQuestion, faRotateRight, faBasketShopping, faBuildingColumns } from '@fortawesome/free-solid-svg-icons'

import './LastTransactions.css'

import style from './LastTransactions.css'

export default function LastTransactions(props) {

  const [ limiter, setLimiter ] = useState(10)

  useEffect(() => {
    if(parseInt(props.portfolioCoinsValues.length) > 10){
      let newLimiter = parseInt(props.portfolioCoinsValues.length * 0.8)
      if(newLimiter < 10) newLimiter = 10
      // setLimiter(newLimiter)
      setLimiter(10)
    }
    else{
      setLimiter(parseInt(props.portfolioCoinsValues.length))
    }
  }, [])

  return (
    <Card>
        <Card.Header className="allHeader"><span>{limiter == 1 ? 'Ostatnia ' : 'Ostatnie '} {limiter} {limiter == 2 || limiter == 3 || limiter == 4 ? 'transakcje' : limiter == 1 ? 'transakcja' : 'transakcji'}</span> <span onClick={props.fetchAllTransactions} className="refreshCard"><FontAwesomeIcon icon={faRotateRight} /></span></Card.Header>
        <Card.Body>
          <ListGroup>
            {
              props.portfolioCoinsValues && props.allTransactions && props.allTransactions.map((transaction, index) => {
                let actionLabel = ''
                let arrow 
                switch(transaction.type){
                  case 'buy':
                    actionLabel = 'buyAction'
                    arrow = <FontAwesomeIcon icon={faArrowUp} />
                    break;
                  case 'sell':
                    actionLabel = 'sellAction'
                    arrow = <FontAwesomeIcon icon={faArrowDown} />
                    break;
                  case 'transfer':
                    actionLabel = 'transferAction'
                    arrow = <FontAwesomeIcon icon={faRightLeft} />
                    break;
                  case 'cashIn':
                    actionLabel = 'fiatActionIn'
                    arrow = <FontAwesomeIcon icon={faArrowUp} />
                    break
                  case 'cashOut':
                    actionLabel = 'fiatActionOut'
                    arrow = <FontAwesomeIcon icon={faArrowDown} />
                    break
                  default: 
                    actionLabel = 'defaultAction'
                    arrow = <FontAwesomeIcon icon={faQuestion} />
                    break;
                }
                if(index < limiter){
                  return(
                    <ListGroup.Item className="d-flex flex-column flex-md-row justify-content-between itemas" key={index}>
                      <span className="d-flex flex-row align-items-center">
                        <span className={`${actionLabel} actionLabelBase`}>{arrow}</span>
                        <span className="d-flex flex-row align-items-center">
                          { actionLabel == 'fiatActionIn' || actionLabel == 'fiatActionOut' ? actionLabel == 'fiatActionIn' ? <FontAwesomeIcon icon={faBasketShopping} style={{fontSize: '18px', color: 'black', opacity: '0.6'}} className="me-3" /> : <FontAwesomeIcon icon={faBuildingColumns} style={{fontSize: '18px', color: 'white', opacity: '0.6'}} className="me-3" /> : <img className="coinLogoLabel" src={transaction.logo} />}
                          <span className="d-flex flex-column">
                            <span className="coinLabel">{actionLabel == 'fiatActionIn' || actionLabel == 'fiatActionOut' ? actionLabel == 'fiatActionIn' ? 'Wpłata waluty' : 'Wypłata waluty' : (transaction.coinFullName)}</span>
                            <span className="coinAmountLabel">{actionLabel == 'fiatActionIn' || actionLabel == 'fiatActionOut' ? actionLabel == 'fiatActionIn' ? '+ '+props.numberWithCommas(transaction.amount)+' USD' : '- '+props.numberWithCommas(transaction.amount)+' USD' : transaction.action && transaction.action == 'sell' ? '- ' : '+ '}{props.visibility == 2 ? transaction.formated && transaction.formated : '*****'} <span className="lightEye">{transaction.shortName && (transaction.shortName).toUpperCase()}</span></span>
                          </span>
                        </span>
                      </span>
                      <span className='d-flex align-items-center'>
                        <span className="roundValueLabel">~ {props.visibility == 2 ? actionLabel == 'fiatActionIn' || actionLabel == 'fiatActionOut' ? transaction.amount >= 1 ? props.numberWithCommas(parseFloat(transaction.amount).toFixed(2)) : (parseFloat(transaction.amount).toFixed(2)) : (parseFloat(transaction.rate) * parseFloat(transaction.amount)).toFixed(2) : '***** $'} USD</span>
                        <span className="d-inline-flex d-md-none" style={{marginLeft: '5px', marginRight: '5px', opacity: '0.45', fontSize: '14px'}}>|</span>
                        <span className="dateLabel">{props.visibility == 2 ? props.convertDateToHumanFriendly(transaction.date) : '**.**.****'}</span>
                      </span>
                    </ListGroup.Item>       
                  ) 
                }
              })
            }
          </ListGroup>
        </Card.Body>
    </Card>
  )
}
