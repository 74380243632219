import React, { useState , useEffect} from 'react'
import app from '../../firebase'
import DataTable from 'react-data-table-component';
import axios from 'axios'
import { useHistory } from 'react-router-dom';

import style from './PanelCoins.module.css'

import { Container, Row, Col } from 'react-bootstrap'

import { useAuth } from '../../contexts/AuthContext'

export default function PanelMain(props) {
    const { currentUser } = useAuth()
    const [ userData, setUserData] = useState()
    const [ tableDataInsert, setTableDataInsert] = useState()
    const history = useHistory()

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            width: '60px',
        },
        {
            name: 'Kryptowaluta',
            selector: row => row.coin,
            sortable: true,
            minWidth: '225px'

        },
        {
            name: '% portfela',
            selector: row => row.percentage,
            sortable: true,
            minWidth: '120px'
        },
        {
            name: 'Kurs',
            selector: row => row.rate,
            sortable: false
        },
        {
            name: 'Ilość',
            selector: row => row.amount,
            sortable: true
        },
        {
            name: 'Wartość (USD)',
            selector: row => row.totalValue,
            sortable: true,
            minWidth: '140px'
        },
        {
            name: 'Średni kurs kupna',
            selector: row => row.avgBuyRate,
            sortable: false,
            minWidth: '150px'
        },
        {
            name: 'Zysk/Strata',
            selector: row => row.totalProfitFromCoin,
            sortable: true,
            minWidth: '175px'
        }
    ];

    const onCryptoRowClick = (event, id) => {
        tableDataInsert && tableDataInsert.map((coin => {
            if(id == coin.id){
                history.push(`/panel/coin/${coin.coingeckoID}`)
            }
        }))
    }

    useEffect(() => {
        let list = []
        let counter = 1
        props.tableData && props.tableData.map((coin, index) => {
            if(coin.show && coin.totalProfitCurrency != 0){
                let temp = {
                    id: counter,
                    coingeckoID: coin.coingeckoID,
                    coin: `${coin.fullName} (${(coin.shortName).toUpperCase()})`,
                    rate: coin.rate,
                    amount: props.visibility == 2 ? ((coin.wallet > 1) && (coin.wallet % 1 == 0)) ? props.numberWithCommas(coin.wallet) : coin.wallet : '***** $',
                    totalValue: props.visibility == 2 ? `${coin.valueInUSD} $` : '***** $',
                    percentage: props.visibility == 2 ? `${coin.perCentOfWallet}%` : '***** %',
                    avgBuyRate: <span className={coin.avgBuyRate == undefined || coin.avgBuyRate == NaN || !coin.avgBuyRate ? 'text-muted' : ''}>{props.visibility == 2 ? `${coin.avgBuyRate == undefined || coin.avgBuyRate == NaN || !coin.avgBuyRate ? 'brak danych' : coin.avgBuyRate.toFixed(6)}` : '*****'}</span>,
                    totalProfitFromCoin: <span className={`${style.coloringSpan} ${coin.totalProfitCurrency == undefined || coin.totalProfitCurrency == NaN || !coin.totalProfitCurrency ? 'text-muted' : ''}`}>{(coin.totalProfitCurrency) ? props.visibility == 2 ? (`${coin.totalProfitCurrency.toFixed(2)} $`) : '***** $' : 'brak danych'} <span className={coin.totalProfitPercentage >= 0 ? style.profitPlus : style.profitMinus}>{coin.totalProfitPercentage ? props.visibility == 2 || props.visibility == 1 ? (`(${coin.totalProfitPercentage.toFixed(2)}%)`) : '' : ''}</span></span>
                }
                list.push(temp)
                counter++
            }
        })
        setTableDataInsert(list)
    }, [props.tableData, props.visibility])

    return (
        <Container>
            <Row style={{marginTop: '50px'}}>
                <Col xs={12}>
                    <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
                    <h6 style={{fontWeight: '400'}}>Całowita wartość portfela: <span style={{fontWeight: '600'}}>${props.userData && props.visibility == 2 ? (props.userData.totalCurrentValueInUSD).toFixed(2) : "***** $"}</span></h6>
                </Col>
            </Row>
            <Row style={{marginTop: '50px'}} className={style.tableBoxin}>
                <Col xs={12}>
                    { (props.tableData) ? <DataTable columns={columns} data={tableDataInsert} striped onRowClicked={(row, event) => { onCryptoRowClick(event, row.id)}} /> : <span>brak wybranych kryptowalut</span> }
                </Col>
            </Row>
        </Container>
    )
}
