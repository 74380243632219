import React, { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'

import style from './TotalPortfolioChart.module.css'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, 
    TimeScale,
    TimeScaleOptions,
    TimeUnit
} from 'chart.js';

import { Line } from "react-chartjs-2";
import ChartRangeLabel from '../ChartRangeLabel/ChartRangeLabel';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function TotalPortfolioChart(props) {
    const { currentUser } = useAuth()

    useEffect(() => {
        // console.log(props.chartData) 
    }, [])

    return (
        <div>
            <div className={style.chartRangeBox}>
                {
                    props.chartRange && props.chartRange.map((rangeChart, index) => {
                        return(
                            <ChartRangeLabel changeRangeOfChart={props.changeRangeOfChart} key={index} range={rangeChart} loadingChart={props.loadingChart} currentChartRange={props.currentChartRange} />
                        )
                    })
                }
                
            </div>
            {
                (props.chartData && props.chartOptions && props.totalPortfolioValueHistory) ? <Line data={props.chartData} options={props.chartOptions} /> : 'loading chart...'
            }
        </div>
    )
}
