import React from 'react'

import { Container, Row, Col } from 'react-bootstrap'

import style from './Changelog.module.css'

export default function Changelog(props) {

  const changelog = [
    {
      version: '1.1.1',
      date: '13.03.2022',
      logs: [
        'uruchomienie aplikacji na hostingu',
        'wdrożenie podstawowych funkcjonalności (zakup, sprzedaż)',
        'tworzenie wykresów historycznych wartości portfela',
        'generowanie tabelek z wartościami'
      ]
    },
    {
      version: '1.1.2',
      date: '19.03.2022',
      logs: [
        'obliczanie różnicy w wartości portfela na przestrzeni czasu',
        'rejestracja użytkowników',
        'optymalizacja odświeżania wartości monet po stronie serwera',
        'algorytm do obliczania zysków i strat',
        'edycja transakcji',
        'optymalizacje pod wersje mobilne',
        'wdrożenie widoku ładowania (loading screen)'
      ]
    },
    {
      version: '1.2.1',
      date: '12.04.2022',
      logs: [
        'przepisany i zoptymalizowany skrypt do obliczania danych portfela (zyski, straty, historyczne wartości, porównania na przestrzeni czasu',
        'poprawki UI/UX',
        'poprawki wykresów (generowanie danych, optymalizacja, estetyka)'
      ]
    },
    {
      version: '1.2.2',
      date: '23.04.2022',
      logs: [
        'skrypt po stronie serwera zoptymalizowany, udoskonalony o funkcjonalność automatycznego pobierania nowego i zweryfiowanego użytkownika w celu aktualizacji jego portfolio',
        'poprawki UI/UX'
      ]
    },
    {
      version: '2.1.1',
      date: '10.05.2022',
      logs: [
        'zakodowana na nowo cała część serwerowa (backend)',
        'wdrożenie websocketów (wyłapywanie zmian i interakcji użytkowników realtime)',
        'uruchomienie serwera i skryptów pracujących 24/7 sprawdzających aktualne kursy oraz aktualizujących portfele użytkowników w czasie rzeczywistym',
        'dodanie informacji o całkowitym zysku portfela',
        'optymalizacja wykresów (poprawki z wyświetlaniem danych, szybkość ładowania, naprawa błędów przy szybkim wprowadzaniu nowych transakcji)',
        'ogólne usprawnienia aplikacji i naprawy błędów wizualnych i funkcjonalnych (między innymi poprawki UI z naciskiem na wieloplatformowe działanie)'
      ]
    },
    {
      version: '2.1.2',
      date: '16.05.2022',
      logs: [
        'odanie informacji oraz całej funkcjonalności (dodawanie, usuwanie, edytowanie) o całkowitej ilość zainwestowanej oraz wypłaconej gotówki',
        'ogólne poprawki UX/UI',
        'dodanie dedykowanych stron dla wszystkich kryptowalut (/panel/coin/ID_KRYPTOWALUTY), które zbierają w jednym miejscu informacje o danej walucie i porównują je z danymi z twojego portfolio',
        'poprawki w kodzie dotyczące obliczania zysku procentowego',
        'implementacja biblioteki moment.js do czytelniejszego prezentowania różnic czasowych',
        'implementacja biblioteki react-toastify do czytelnego i jasnego informowania użytkownika o podejmowanych działaniach na stronie',
        'wprowadzenie poprawek technicznych i zniwelowanie bugów występujących podczas wprowadzania nowej transakcji',
        'dodanie panelu administratora widocznego tylko dla autoryzowanych użytkowników',
      ]
    },
    {
      version: '2.2.1',
      date: '28.05.2022',
      logs: [
        'wprowadzaenie podstawowego systemu wysyłania raportów dotyczących konta na maila'
      ]
    },
    {
      version: '3.1.1',
      date: '29.05.2022',
      logs: [
        'wprowadzaniu systemu umożliwiającego dodawanie i śledzenie w czasie rzeczywistym zleceń z rynku futures',  
        'przyśpieszenie ładowania strony i wykonywania akcji o ponad 350%',
        'poprawki wizualne',
        'poprawki w responsywności',
        'nowa szata graficzna',
        'nowe logo'
      ]
    },
    {
      version: '3.1.2',
      date: '02.06.2022',
      logs: [
        'dodanie szczegółowych statystyk na temat poszczególnych kryptowalut, takich jak: największy zysk, największa strata',
        'odświeżony wygląd zakładki Futures',
        'poprawki drobnych błędów i walidacji danych',
        'zoptymalizowanie ładowania się wykresów (obecnie wszystkie działają prawidłowo na wszystkich zakresach dat)'
      ]
    }
  ]

  return (
    <Container>
      <Row style={{marginTop: '50px'}}>
        <Col xs={12}>
          <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
          <h6 style={{fontWeight: '400'}}>Changelog - przegląd wszystkich wersji i aktualizacji aplikacji <b>CrytoFolio.io</b></h6>
        </Col>
      </Row>
      <Row style={{marginTop: '50px'}}>
        <Col xs={12}>
          {
            changelog.slice(0).reverse().map((commit, index) => {
              return(
                <div className={style.changelogRecord} key={commit.version}>
                  <span className={style.versionTitle}>{index == 0 && <span className={style.actualVersion}>AKTUALNA</span>}<b>v.{commit.version}</b></span>
                  <span className={style.releaseTitle}>wydano: {commit.date}</span>
                  <ul>
                  {
                    commit.logs.map(log => {
                      return <li>{log}</li>
                    })
                  }
                  </ul>
                </div>
              )
            })
          }
        </Col>
      </Row>
    </Container>
  )
}
