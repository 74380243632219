import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Switch, Route, useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faA, faFaceFrown } from '@fortawesome/free-solid-svg-icons'

import PanelMain from '../PanelMain/PanelMain'
import PanelCoins from '../PanelCoins/PanelCoins'
import NavbarMain from '../../components/NavbarMain/NavbarMain'
import PanelMovements from '../PanelMovements/PanelMovements'
import PanelSettings from '../PanelSettings/PanelSettings'
import CoinMovement from '../CoinMovement/CoinMovement'
import Footer from '../../components/Footer/Footer'
import ReportBug from '../ReportBug/ReportBug' 
import Changelog from '../Changelog/Changelog'
import About from '../About/About'
import Futures from '../Futures/Futures'
import CoinSingle from '../CoinSingle/CoinSingle'

import { ToastContainer, toast } from 'react-toastify';

import axios from 'axios'

import app from '../../firebase'
import CoinHistory from '../CoinHistory/CoinHistory'
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert'
import Loader from '../../components/Loader/Loader'
import { faBullseye } from '@fortawesome/free-solid-svg-icons'
import Chart from '../Chart/Chart'
import io from 'socket.io-client'
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop'
import PanelAdmin from '../PanelAdmin/PanelAdmin'
import Reports from '../Reports/Reports'

import { getAuth, updateEmail } from "firebase/auth";
const auth = getAuth();

// const socket = io('http://localhost:4000')
const socket = io('https://desolate-eyrie-76391.herokuapp.com/')
// const socket = io('https://...')

export default function Panel() {
  const { currentUser, currentUserInfo, logout } = useAuth()
  const history = useHistory()

  const limitForHistoryPortfolioQUERY = 1440

  const chartRange = [
    // limiter: minutes * 60 * 1000, ex. for 5 min chart: 5 * 60 * 1000
    // { 
    //   label: 'ALL',
    //   limiter: 0,
    //   displayRecords: 12
    // },
    { 
      label: '15M',
      limiter: 15 * 60 * 1000,
      displayRecords: 15
    },
    { 
      label: '30M',
      limiter: 30 * 60 * 1000,
      displayRecords: 10
    },
    { 
      label: '1H',
      limiter: 60 * 60 * 1000,
      displayRecords: 12
    },
    { 
      label: '3H',
      limiter: 3* 60 * 60 * 1000,
      displayRecords: 12
    },
    { 
      label: '6H',
      limiter: 6 * 60 * 60 * 1000,
      displayRecords: 12
    },
    // { 
    //   label: '12H',
    //   limiter: 12 * 60 * 60 * 1000
    // },
    {
      label: '24H',
      limiter: 24 * 60 * 60 * 1000,
      displayRecords: 12
    },
    {
      label: '3D',
      limiter: 3 * 24 * 60 * 60 * 1000,
      displayRecords: 12
    },
    {
      label: '7D',
      limiter: 7 * 24 * 60 * 60 * 1000,
      displayRecords: 14
    },
    {
      label: '14D',
      limiter: 14 * 24 * 60 * 60 * 1000,
      displayRecords: 14
    },
    {
      label: '30D',
      limiter: 30 * 24 * 60 * 60 * 1000,
      displayRecords: 10
    },
    // {
    //   label: '60D',
    //   limiter: 60 * 24 * 60 * 60 * 1000
    // },
    {
      label: '90D',
      limiter: 90 * 24 * 60 * 60 * 1000,
      displayRecords: 9
    },
    // {
    //   label: '180D',
    //   limiter: 180 * 24 * 60 * 60 * 1000
    // },
    {
      label: '1Y',
      limiter: 365 * 24 * 60 * 60 * 1000,
      displayRecords: 15
    }
  ]

  const hideChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: { 
      yAxis: { 
        display: false 
      },
      xAxis: {
        ticks: {
            maxTicksLimit: 10
        }
      }
    }
  }

  const [ userData, setUserData ] = useState()
  const [ coinsData, setCoinsData ] = useState()
  const [ totalWallet, setTotalWallet ] = useState()
  const [ userPortfolio, setUserPortfolio ] = useState()
  const [ totalPortfolioValueHistory, setTotalPortfolioValueHistory ] = useState()
  const [ totalPortfolioValueHistoryDONTMOVE, setTotalPortfolioValueHistoryDONTMOVE ] = useState()
  const [ chartData, setChartData ] = useState(null)
  const [ chartOptions, setChartOptions ] = useState(null)
  const [ portfolioCoinsValues, setPortfolioCoinsValues ] = useState()
  const [ existInCoingecko, setExistInCoingecko ] = useState(false)
  const [ searchedCryptoToAdd, setSearchedCryptoToAdd ] = useState()
  const [ searched, setSearched ] = useState(false)
  const [ editingCoins, setEditingCoins ] = useState([])
  const [ transactionsOfCoin, setTransactionsOfCoin ]  = useState()
  const [ errorAlert, setErrorAlert ] = useState(false)
  const [ errorTitle, setErrorTitle ] = useState()
  const [ errorMessage, setErrorMessage ] = useState()
  const [ errorType, setErrorType ] = useState()
  const [ activeAccount, setActiveAccount ] = useState(null)
  const [ visibility, setVisibility ] = useState(2)
  const [ currentChartRange, setCurrentChartRange ] = useState()
  const [ allTransactions, setAllTransactions ] = useState()
  const [ loading, setLoading ] = useState(true)
  const [ loadingChart, setLoadingChart ] = useState(true)
  const [ colorMode, setColorMode ] = useState('light')
  const [ shortUsersInfo, setShortUSersInfo ] = useState()
  const [ allFutures, setAllFutures ] = useState()
  const [ allFuturesEnd, setAllFuturesEnd ] = useState()

  const [ biggestProfitCoin, setBiggestProfitCoin] = useState()
  const [ biggestLostCoin, setBiggestLostCoin ] = useState()
  const [ biggestProfitCurrencyCoin, setBiggestProfitCurrencyCoin ] = useState()
  const [ biggestLostCurrencyCoin, setBiggestLostCurrencyCoin ] = useState()
  const [ mostTransactionCoinState, setMostTransactionCoinState] = useState()
  const [ mostInvestedUSDCoin, setMostInvestedUSDCoin] = useState()
  const [ mostValueCoin, setMostValueCoin] = useState()
  const [ lowValueCoin, setLowValueCoin] = useState()

  const [ allChart15m, setAllChart15m ] = useState([])
  const [ allChart30m, setAllChart30m ] = useState([])
  const [ allChart1h, setAllChart1h ] = useState([])
  const [ allChart3h, setAllChart3h ] = useState([])
  const [ allChart6h, setAllChart6h ] = useState([])
  const [ allChart24h, setAllChart24h ] = useState([])
  const [ allChart3d, setAllChart3d ] = useState([])
  const [ allChart7d, setAllChart7d ] = useState([])
  const [ allChart14d, setAllChart14d ] = useState([])
  const [ allChart30d, setAllChart30d ] = useState([])
  const [ allChart90d, setAllChart90d ] = useState([])
  const [ allChart1y, setAllChart1y ] = useState([])

  const checkCurrencySymbol = (currency) => {
    let symbol = '?'

    if(currency){
      switch(currency.toLowerCase()){
        case 'usd':
          symbol = '$'
          break;
        case 'eur':
          symbol = '€'
          break;
        default:
          symbol = '$'
          break;
      }
    }

    return symbol
  }

  const convertDateToHumanFriendly = (timestamp) => {
    let tempDate = new Date(timestamp)
    // let time = `${tempDate.getHours() < 10 ? "0"+tempDate.getHours() : tempDate.getHours()}:${tempDate.getMinutes() < 10 ? "0"+tempDate.getMinutes() : tempDate.getMinutes()}:${tempDate.getSeconds() < 10 ? "0"+tempDate.getSeconds() : tempDate.getSeconds()}`
    let time = `${tempDate.getHours() < 10 ? "0"+tempDate.getHours() : tempDate.getHours()}:${tempDate.getMinutes() < 10 ? "0"+tempDate.getMinutes() : tempDate.getMinutes()}`
    let day = `${tempDate.getDate() < 10 ? "0"+tempDate.getDate() : tempDate.getDate()}.${tempDate.getMonth()+1 < 10 ? "0"+(tempDate.getMonth()+1) : tempDate.getMonth()+1}.${tempDate.getFullYear()}`
  
    return `${time} ${day}`
  }

  const convertDateToHumanFriendlyDATE = (timestamp) => {
    let tempDate = new Date(timestamp)
    let time = `${tempDate.getHours() < 10 ? "0"+tempDate.getHours() : tempDate.getHours()}:${tempDate.getMinutes() < 10 ? "0"+tempDate.getMinutes() : tempDate.getMinutes()}:${tempDate.getSeconds() < 10 ? "0"+tempDate.getSeconds() : tempDate.getSeconds()}`
    let day = `${tempDate.getDate() < 10 ? "0"+tempDate.getDate() : tempDate.getDate()}.${tempDate.getMonth()+1 < 10 ? "0"+(tempDate.getMonth()+1) : tempDate.getMonth()+1}.${tempDate.getFullYear()}`
  
    return `${day}`
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  async function handleLogout(){
      await logout()
      history.push('/')
  }

  function addZeroes(num) {
    const dec = num.split('.')[1]
    const len = dec && dec.length > 2 ? 2 : 2
    return Number(num).toFixed(len)
  }

  const changeUserReportStatus = async (uid) => {
    shortUsersInfo && shortUsersInfo.map(async user => {
      if(user.uid == uid){
        if(userData.autoRaports){
          try{
            await app.firestore()
            .collection('users')
            .doc(uid)
            .update({
              autoRaports: !user.autoRaports
            })

            toast('Zmieniono status konta', {
              type: 'info'
            })

            await fetchAllUsersDataToChangeStatus()
          }
          catch(e){
            console.log(e)
          }
        }
      }
    })
  }

  const changeUserAdminStatus = async (uid) => {
    shortUsersInfo && shortUsersInfo.map(async user => {
      if(user.uid == uid){
        if(userData.admin){
          try{
            await app.firestore()
            .collection('users')
            .doc(uid)
            .update({
              admin: !user.admin
            })

            toast('Zmieniono status konta', {
              type: 'info'
            })

            await fetchAllUsersDataToChangeStatus()
          }
          catch(e){
            console.log(e)
          }
        }
      }
    })
  }

  const changeUserActiveStatus = async (uid) => {
    shortUsersInfo && shortUsersInfo.map(async user => {
      if(user.uid == uid){
        if(userData.admin){
          try{
            await app.firestore()
            .collection('users')
            .doc(uid)
            .update({
              active: !user.active
            })

            toast('Zmieniono status konta', {
              type: 'info'
            })

            await fetchAllUsersDataToChangeStatus()
          }
          catch(e){
            console.log(e)
          }
        }
      }
    })
  }

  const fetchUserData = async () => {
    setLoading(true)
    console.log('fetching user data...')
    let now = new Date().valueOf()
    
    try{
      await app.firestore()
      .collection('users')
      .doc(currentUser && currentUser.uid)
      .get()
      .then(async (documentSnapshot) => {
          if(documentSnapshot.exists){
              let userAccSettings = []
              try{
                await app.firestore()
                .collection('users')
                .doc(currentUser && currentUser.uid)
                .collection('settings')
                .get()
                .then(settingsSnap => {
                  settingsSnap.forEach(set => {
                    let settingData = set.data()
                    userAccSettings[set.id] = settingData
                  })

                  let response = documentSnapshot.data()
                  if(response.active){
                    setActiveAccount(true)
                      toast('Poprawnie pobrano informacje o twoim koncie.', {
                        type: 'success',
                        autoClose: 3000
                      })
                      let tempDate = new Date(response.lastUpdate)
                    response = {
                        ...response, 
                        time: `${tempDate.getHours() < 10 ? "0"+tempDate.getHours() : tempDate.getHours()}:${tempDate.getMinutes() < 10 ? "0"+tempDate.getMinutes() : tempDate.getMinutes()}:${tempDate.getSeconds() < 10 ? "0"+tempDate.getSeconds() : tempDate.getSeconds()}`,
                        day: `${tempDate.getDate() < 10 ? "0"+tempDate.getDate() : tempDate.getDate()}.${tempDate.getMonth()+1 < 10 ? "0"+(tempDate.getMonth()+1) : tempDate.getMonth()+1}.${tempDate.getFullYear()}`,
                        symbol: checkCurrencySymbol(response.currency),
                        settings: userAccSettings
                    }
                    setUserData(response)
                  } 
                  else{
                    setActiveAccount(false)
                    setLoading(false)
                    setUserData(response)
                  }
                })
              }
              catch(e){
                console.log(e)
                setLoading(false)
                setActiveAccount(false)
              }
          }
          else{
            setLoading(false)
          }
        setLoading(false)
      })
    }
    catch(e){
      console.log(e)
      setLoading(false)
    }
  }

  const fetchAllUsersDataToChangeStatus = async () => {
    if(userData && userData.admin){
      try{
        let usrs = []
        await app.firestore()
        .collection('users')
        .get()
        .then(querySnap => {
          querySnap.forEach(user => {
            let usrData = user.data()
            let temp = {
              uid: user.id,
              nick: user.data().nick,
              admin: user.data().admin,
              active: user.data().active,
              autoRaports: user.data().autoRaports
            }
            usrs.push(temp)
          })
          setShortUSersInfo(usrs)
        })
      }
      catch(e){
        console.log(e)
      }
    }
  }

  const collectUserPortfolioData = async () => {
    let list = []
    
    let bigestProfit = 0
    let bigestProfitCoin = 0
    let bigestLost = 0
    let bigestLostCoin = 0
    let biggestProfitUsd = 0
    let biggestProfitUsdCoin = 0
    let biggestLostUsd = 0
    let biggestLostUsdCoin = 0
    let mostTransaction = 0
    let mostTransactionCoin = {}
    let mostTotalInv = 0
    let mostTotalInvCoin = {}
    let mostValue = 0
    let mostValueCoin = {}
    let lowValue = 999999
    let lowValueCoin = {}


    userPortfolio && userPortfolio.forEach(coin => {
        coinsData.forEach(coinMain => {
            let percent = 0

            if(coinMain.coingeckoID == coin.coingeckoID){
              if(coin.totalProfitPercentage > bigestProfit){
                bigestProfit = coin.totalProfitPercentage
                bigestProfitCoin = {coin, coinMain}
              }
              if(coin.totalProfitPercentage < bigestLost){
                bigestLost = coin.totalProfitPercentage
                bigestLostCoin = {coin, coinMain}
              }
              if(coin.totalProfitCurrency > biggestProfitUsd){
                biggestProfitUsd = coin.totalProfitCurrency
                biggestProfitUsdCoin = {coin, coinMain}
              }
              if(coin.totalProfitCurrency < biggestLostUsd){
                biggestLostUsd = coin.totalProfitCurrency
                biggestLostUsdCoin = {coin, coinMain}
              }
              if(coin.numberOfAllTransactions > mostTransaction){
                mostTransaction = parseInt(coin.numberOfAllTransactions)
                mostTransactionCoin = {coin, coinMain}
              }
              if(coin.totalInvestedInUSD > mostTotalInv){
                mostTotalInv = coin.totalInvestedInUSD
                mostTotalInvCoin = {coin, coinMain}
              }
              if(coin.totalValueInUSD > mostValue){
                mostValue = coin.totalValueInUSD
                mostValueCoin = {coin, coinMain}
              }
              if(coin.totalValueInUSD < lowValue && coin.totalValueInUSD > 0 ){
                lowValue = coin.totalValueInUSD
                lowValueCoin = {coin, coinMain}
              }
              let percent = 0
              coin.totalAmountOfCoin != undefined ? percent = (parseFloat(((coinMain.actualRate * coin.totalAmountOfCoin) * 100) / userData.totalCurrentValueInUSD)).toFixed(2) : percent = 0
                if(coin.show){
                  list.push({
                    ...coin,
                    show: coin.show,
                    coingeckoID: coinMain.coingeckoID,
                    fullName: coinMain.longName,
                    shortName: coinMain.shortName,
                    rate: parseFloat(coinMain.actualRate),
                    wallet: parseFloat(coin.totalAmountOfCoin),
                    valueInUSD: parseFloat((coinMain.actualRate * coin.totalAmountOfCoin).toFixed(2)),
                    logo: coinMain.logo,
                    perCentOfWallet: percent,
                    avgBuyRate: coin.avgBuyRate,
                    avgSellRate: coin.avgSellRate,
                    numberOfAllTransactions: coin.numberOfAllTransactions,
                    numberOfSells: coin.numberOfSells,
                    numberOfBuys: coin.numberOfBuys,
                    oneDayChange: coinMain.oneDayChangePercentage,
                    firstTransactionDate: coin.firstTransactionDate,
                    lastTransactionDate: coin.lastTransactionDate,
                    totalProfitCurrency: coin.totalProfitCurrency,
                    totalProfitPercentage: coin.totalProfitPercentage,
                  })
                }
                setPortfolioCoinsValues(list)
             }

          })
      })

      setBiggestProfitCoin(bigestProfitCoin)
      setBiggestLostCoin(bigestLostCoin)
      setBiggestProfitCurrencyCoin(biggestProfitUsdCoin)
      setBiggestLostCurrencyCoin(biggestLostUsdCoin)
      setMostTransactionCoinState(mostTransactionCoin)
      setMostInvestedUSDCoin(mostTotalInvCoin)
      setMostValueCoin(mostValueCoin)
      setLowValueCoin(lowValueCoin)
  }

  const fetchTotalPortfolioValueHistory = async () => {
    console.log('fetching user history portfolio data...')
    try{
        let list = []
        let timestamps = []

        let first = true
      
        await app.firestore()
        .collection('users')
        .doc(currentUser.uid)
        .collection("totalPortfolioValueHistory").orderBy("time", "desc").limit(limitForHistoryPortfolioQUERY)
        .get()
        .then((documentSnapshot) => {
            documentSnapshot.forEach((doc) => {
                let record = doc.data()
                let last = 0
                record = {...record, id: doc.id}
                if(first){
                  list.push(record)
                  last = record.value
                  timestamps.push(record.time)
                  first = false
                }
                else{
                  if(record.time > (timestamps[timestamps.length-1] + 60 * 1000) || record.value != last){
                    timestamps.push(record.time)
                    list.push(record)
                    last = record.value
                  }  
                }

            })

            list.sort((a,b) => {return (a.time - b.time)})

            setTotalPortfolioValueHistory(list)
            setTotalPortfolioValueHistoryDONTMOVE(list) 
        })
    }
    catch(e){
      console.log(e)
    }
  }

  const fetchFullUserPortfolio = async () => {
    console.log('fetching user portfolio data...')
    try{
        let list = []

        await app.firestore()
        .collection('users')
        .doc(currentUser.uid)
        .collection('portfolio')
        .get()
        .then(async (documentSnapshot) => {
            documentSnapshot.forEach(doc => {
                list.push(doc.data())
            })
          setUserPortfolio(list)
        })
    }
    catch(e){
        console.log(e)
    }
  }

  const checkIfCryptoExistInCoingeckoApi = async (coin) => {
    console.log(`checking if ${coin} exist in coingecko DB`)
    setSearched(true)
    let check = false
    let repeat = false
    let goShow = false
    userPortfolio.map(coinP => {
      if(coinP.coingeckoID == coin){
        if(coinP.show) repeat = true
        else{
          repeat = true
          goShow = true
        }
      }
    })
    if(true){
      try{
        await axios.get(`https://api.coingecko.com/api/v3/coins/${coin}/`).then(async response => {
            let data = response.data
            await updateCoinRateDB()
            if(data){
              console.log(data)
              check = true
              setSearchedCryptoToAdd(data)
            }
            return check
        }).then((check) => {
          if(check){
            setExistInCoingecko(true)
          }
          else{
            setExistInCoingecko(false)
            setSearchedCryptoToAdd()
          } 
        })
      }
      catch(e){
          if(e.response.status == 404) {
            setExistInCoingecko(false)
            setSearchedCryptoToAdd()
          }
      }
    }
    if(repeat){
      if(goShow){
        try{
          console.log('changing hide to show')
          await app.firestore()
          .collection('users')
          .doc(currentUser.uid)
          .collection('portfolio')
          .doc(coin)
          .update({
            show: true
          })
        }
        catch(e){
          console.log(e)
        }
        setExistInCoingecko(false)
        setSearchedCryptoToAdd()
      }
      else{
        setExistInCoingecko(false)
        setSearchedCryptoToAdd()
      }
      
    }
  }

  const updateCoinRateDB = async (coin) => {
    // console.log(`updating ${coin} coin rate to db...`)
    // try{
    //     await axios.get(`https://api.coingecko.com/api/v3/coins/${coin}/`).then(response => {
    //         let data = response.data
    //         app.firestore()
    //         .collection('coins')
    //         .doc(coin)
    //         .set({
    //             actualRate: data.market_data.current_price.usd,
    //             coingeckoID: data.id,
    //             logo: data.image.large,
    //             longName: data.name,
    //             shortName: data.symbol
    //         })
    //     })
    // }
    // catch(e){
    //     console.log(e)
    // }
  }

  const updateLastUpdateCoingecko = async () => {
    console.log('updating last time update coingecko to firestore...')
    let now = new Date().valueOf()
    try{
        await app.firestore()
        .collection('users')
        .doc(currentUser.uid)
        .update({
            lastUpdateCoingecko: now
        })
    }
    catch(e){
        console.log(e)
    }
  }

  const updateTotalWalletFirestore = async (total) => {
    // console.log('updating total wallet do firestore...')
    // let now = new Date().valueOf()
    // try{
    //     await app.firestore()
    //     .collection('users')
    //     .doc(currentUser.uid)
    //     .update({
    //         totalCurrentValueInUSD: total,
    //         lastUpdate: now,
    //         oneHourChangeCurrency: 238.90
    //     })
    //     await app.firestore()
    //     .collection('users')
    //     .doc(currentUser.uid)
    //     .collection('totalPortfolioValueHistory')
    //     .add({
    //         value: total,
    //         time: now
    //     })
    //     fetchUserData()
    //     // fetchTotalPortfolioValueHistory()
    // }
    // catch(e){
    //     console.log(e)
    // }
  }

  const fetchCoinsData = async () => {
    console.log('fetching coins data...')
    try{
        setLoading(true)
        let list = []

        await app.firestore()
        .collection('coins')
        .get()
        .then((documentSnapshot) => {
            documentSnapshot.forEach(doc => {
                list.push(doc.data())
            })
        })
        setCoinsData(list)
    }
    catch(e){
        console.log(e)
    }
  }

  const sendRaportToEmail = async (uid, mail) => {
    let nowy = new Date().valueOf()
    if(userData.lastRaport == undefined || userData.lastRaport == 0 || (userData.lastRaport && (userData.lastRaport+(15 * 60 * 1000) < (nowy)))){
      try{
        socket.emit('generate-report', ({uid: uid, mail: mail}))
      }
      catch(e){
        console.log(e)
      }
      await toast(`Poprawnie wysłano raport na maila: ${mail}`, {
        type: 'success'
      })
    }
    else{
      toast(`Następne generowanie raportu możliwe o ${convertDateToHumanFriendly(userData.lastRaport+(15 * 60 * 1000))} .`, {
        type: 'info'
      })
    }
  }

  const addNewCryptoToDatabase = async () => {
    console.log(`adding to database new coin: ${searchedCryptoToAdd.name}`)
    try{
      setLoading(true)

      await socket.emit('update-new-coin', ({coinId: searchedCryptoToAdd.id}))

      await app.firestore()
        .collection('coins')
        .doc(searchedCryptoToAdd.id)
        .set({
            actualRate: searchedCryptoToAdd.market_data.current_price.usd,
            coingeckoID: searchedCryptoToAdd.id,
            logo: searchedCryptoToAdd.image.large,
            longName: searchedCryptoToAdd.name,
            shortName: searchedCryptoToAdd.symbol
          })

          await app.firestore()
          .collection('users')
          .doc(currentUser.uid)
          .collection('portfolio')
          .doc(searchedCryptoToAdd.id)
          .set({
              show: true,
              totalAmountOfCoin: 0,
              totalInvestedInUSD: 0,
              totalProfitCurrency: 0,
              totalProfitPercentage: 0,
              coingeckoID: searchedCryptoToAdd.id,
            })

          let ids = []

          await app.firestore()
          .collection('users')
          .doc(currentUser.uid)
          .collection('portfolio')
          .doc(searchedCryptoToAdd.id)
          .collection('transactions')
          .get()
          .then(docSnap => {
            docSnap.forEach(trans => {
              ids.push(trans.id)
            })
            ids.map(async id => {
              await app.firestore()
              .collection('users')
              .doc(currentUser.uid)
              .collection('portfolio')
              .doc(searchedCryptoToAdd.id)
              .collection('transactions')
              .doc(id)
              .delete()
            })
          })
        console.log('added new coin.')
        
        refreshSite()
    }
    catch(e){
      console.log(e)
      setLoading(false)
    }
  }

  const deleteCoinFromDB = async (coin) => {
    if(window.confirm("u sure to delete coin from db?")){
      console.log(`deleting from db: ${coin}`)
      try{
          if(coin != 'bitcoin' || coin != 'bnb')
          await app.firestore()
          .collection('users')
          .doc(currentUser.uid)
          .collection('portfolio')
          .doc(coin)
          .delete()

          console.log('deleted coin.')
          
          fetchFullUserPortfolio()
      }
      catch(e){
        console.log(e)
      }
    }
    else{
      console.log('canceled delete coin')
    }
  }

  const startBuying = (coin) => {
    let list = editingCoins
    list.push({
      coin: coin.coingeckoID,
      action: 'buy'
    })
    setEditingCoins(list)
    fetchCoinsData()
  }

  const startSelling = (coin) => {
    let list = editingCoins
    list.push({
      coin: coin.coingeckoID,
      action: 'sell'
    })
    setEditingCoins(list)
    fetchCoinsData()
  }

  const startTransfering = (coin) => {
    let list = editingCoins
    list.push({
      coin: coin.coingeckoID,
      action: 'transfer'
    })
    setEditingCoins(list)
    fetchCoinsData()
  }

  const backToChooseOption = (coin) => {
    let list = editingCoins
    const removeIndex = list.findIndex( item => item.coin == coin.coingeckoID );
    list.splice( removeIndex, 1 );
    setEditingCoins(list)
    fetchCoinsData()
  }

  const buyCryptoAction = async (coin, wantBuy, rate, time) => {
    console.log(`trying to buy outine : ${wantBuy} ${coin.longName} po ${rate} USD`)
        try{
          socket.emit('buy-crypto', { 
            uid: currentUser.uid,
            cryptoId: coin.coingeckoID,
            amount: wantBuy,
            rate: rate,
            date: time
          })

          setLoading(true)

          setTimeout(() => {
            refreshSite()
          }, 1)

          toast(`Poprawnie zarejestrowano zakup`, {
            type: 'success',
          })
          
          return true
        }
        catch(e){
          console.log(e)
          setLoading(false)
          return false
        }
  }

  const initFuturesCrypto = async (currentCoin, amount, rate, time, futures, type) => {
    try{
      socket.emit('init-futures', { 
        uid: currentUser.uid,
        cryptoId: currentCoin.coingeckoID,
        amount: amount,
        rate: rate,
        date: time,
        levarage: futures,
        type: type
      })

      setLoading(true)

      

      toast(`Poprawnie zarejestrowano zakup`, {
        type: 'success',
      })

      setTimeout(() => {
        refreshSite()
      }, 100)
    }
    catch(e){
      console.log(e)
      setLoading(false)
    }
  }

  const sellCryptoAction = async (coin, wantSell, rate, max, time) => {
    console.log(`trying to sell outine : ${wantSell} ${coin.longName} po ${rate} USD`)
        try{
          socket.emit('sell-crypto', { 
            uid: currentUser.uid,
            cryptoId: coin.coingeckoID,
            amount: wantSell,
            rate: rate,
            date: time
          })

          setLoading(true)

          setTimeout(() => {
            refreshSite()
            setLoading(false)
          }, 1)

          toast(`Poprawnie zarejestrowano sprzedaż`, {
            type: 'success',
          })

          return true
        }
        catch(e){
          console.log(e)
          setLoading(false)
          return false
        }
  }

  const transferCryptoAction = async (coin, wantTransfer, time) => {
    console.log(`trying to transfer outine : ${wantTransfer} ${coin.longName} po 0 USD`)
        try{
          socket.emit('transfer-crypto', { 
            uid: currentUser.uid,
            cryptoId: coin.coingeckoID,
            amount: wantTransfer,
            rate: 0,
            date: time
          })

          setLoading(true)

          setTimeout(() => {
            refreshSite()
            setLoading(false)
          }, 1500)

          toast(`Poprawnie zarejestrowano transfer`, {
            type: 'success',
          })

          return true
        }
        catch(e){
          console.log(e)
          setLoading(false)
          return false
        }
  }

  const getAllTransactionBySpecificCoin = async (coin) => {
    console.log(`Fetching all transactions of ${coin}`)
    try{
      let list = []

      await app.firestore()
      .collection('users')
      .doc(currentUser.uid)
      .collection('portfolio')
      .doc(coin)
      .collection("transactions")
      .orderBy("date", "desc")
      .get()
      .then(docSnap => {
        docSnap.forEach(transaction => {
          let output = transaction.data()
          output = {...output, uid: transaction.id, coinFullName: coin.toUpperCase()}
          list.push(output)
        })
        setTransactionsOfCoin(list)
        return list
      })
    }
    catch(e){
      console.log(e)
    }
  }

  const fetchDelPostionFuture = async (uid, coinId, docId) => {
    try{
      socket.emit('del-future-position', {
        uid: uid,
        coinId: coinId,
        docId: docId
      })

      await refreshSite()
      toast('Usunięto zlecenie z historii.', {
        type: 'info'
      })
    }
    catch(e){
      console.log(e)
    }
  }

  const fetchRetryPostionFuture = async (uid, coinId, docId) => {
    try{
      socket.emit('retry-future-position', {
        uid: uid,
        coinId: coinId,
        docId: docId
      })

      await refreshSite()
      toast('Zmieniono status zlecenia na aktywny.', {
        type: 'info'
      })
    }
    catch(e){
      console.log(e)
    }
  }

  const fetchCloseFuturePosition = async (uid, coinId, docId, endRate, endDate) => {
    try{
      socket.emit('close-future-position', { 
        uid: uid,
        coinId: coinId,
        docId: docId,
        endRate: endRate,
        endDate: endDate
      })

      await refreshSite()
      toast('Zamknięto zlecenie futures.', {
        type: 'info'
      })
    }
    catch(e){

    } 
}

  const removeSpecificTransactionOfCoin = async (coinCoingeckoID, idOfTransaction) => {
    console.log(`Removing transaction in ${coinCoingeckoID} with ID: ${idOfTransaction}`)
    try{
      await socket.emit('refresh-coin-portfolio', { 
        uid: currentUser.uid,
        coinId: coinCoingeckoID,
      })

      setLoading(true)

      await app.firestore()
      .collection('users')
      .doc(currentUser.uid)
      .collection('portfolio')
      .doc(coinCoingeckoID)
      .collection('transactions')
      .doc(idOfTransaction)
      .delete()

      setTimeout(async () => {
        refreshSite()
      }, 100)

      toast(`Poprawnie usunięto transakcję`, {
        type: 'success',
      })
      
      return true
    }
    catch(e){
      setLoading(false)
      console.log(e)
    }
  }

  const removeSpecificTransactionOfCoinFiat = async (docUid) => {
    try{
      await socket.emit('update-user-profile', { 
        uid: currentUser.uid
      })

      setLoading(true)

      await app.firestore()
      .collection('users')
      .doc(currentUser.uid)
      .collection('bankTransfers')
      .doc(docUid)
      .delete()

      setTimeout(() => {
        refreshSite()
      }, 100)

      toast(`Poprawnie usunięto transakcję`, {
        type: 'success',
      })
      
      return true
    }
    catch(e){
      setLoading(false)
      console.log(e)
    }
  }

  const editSpecificTransactionOfCoin = async (coinCoingeckoID, idOfTransaction, newTransaction) => {
    console.log(`Editing transaction in ${coinCoingeckoID} with ID: ${idOfTransaction}`)
    try{
      socket.emit('refresh-coin-portfolio', { 
        uid: currentUser.uid,
        coinId: coinCoingeckoID,
      })

      await app.firestore()
      .collection('users')
      .doc(currentUser.uid)
      .collection('portfolio')
      .doc(coinCoingeckoID)
      .collection('transactions')
      .doc(idOfTransaction)
      .update(newTransaction)

      refreshSite()
    }
    catch(e){
      console.log(e)
    }
  }

  const handleUpdateFiatTransaction = async (docUid, newTransaction) => {
    try{
      socket.emit('update-user-profile', { 
        uid: currentUser.uid
      })

      await app.firestore()
      .collection('users')
      .doc(currentUser.uid)
      .collection('bankTransfers')
      .doc(docUid)
      .update(newTransaction)

      await refreshSite()
    }
    catch(e){
      console.log(e)
    }
  }

  const changeVisibility = () => {
    console.log('changing visibility...')
    if(visibility == 0){
      setVisibility(1)
    }
    else if(visibility == 1){
      setVisibility(2)
    } 
    else{
      setVisibility(0)
    } 
  }

  const changeRangeOfChart = async (range) => {
    if(true){
      setCurrentChartRange(range)
      setLoadingChart(true)
      console.log(`changing range of chart to: ${range.label}`)

      let list = []
        let labels = []
        let timestamps = []
        let values = []

        // let margin = 6 * 60 * 1000
        let margin = 60 * 1000

        let first = true
        let now = new Date().valueOf()

        let endArr = []

        if(range.label == '1Y'){
          setLoadingChart(true)

          if(allChart1y.length < 1){
            let stepper = 43800 * 60 * 1000
            endArr = []
            for(let i = 0; i < 12; i++){
              
              await app.firestore().collection('users').doc(currentUser.uid).collection('totalPortfolioValueHistory').where('time', '<=', (now-(stepper*(i))-margin)).orderBy("time", "desc").limit(1).get().then(docSnap => {
                docSnap.forEach(record => {
                  endArr.push(record.data())
                })
              })
            }
            
            setAllChart1y(endArr)
            setLoadingChart(false)
          }
          else{
            endArr = allChart1y
          }
        }

        else if(range.label == '90D'){
          setLoadingChart(true)

          if(allChart90d.length < 1){
            let stepper = 10800 * 60 * 1000
            endArr = []
            for(let i = 0; i < 12; i++){
              
              await app.firestore().collection('users').doc(currentUser.uid).collection('totalPortfolioValueHistory').where('time', '<=', (now-(stepper*(i))-margin)).orderBy("time", "desc").limit(1).get().then(docSnap => {
                docSnap.forEach(record => {
                  endArr.push(record.data())
                })
              })
            }
            
            setAllChart90d(endArr)
            setLoadingChart(false)
          }
          else{
            endArr = allChart90d
          }
        }

        else if(range.label == '30D'){
          setLoadingChart(true)

          if(allChart30d.length < 2){
            let stepper = 3600 * 60 * 1000
            endArr = []
            for(let i = 0; i < 12; i++){
              
              await app.firestore().collection('users').doc(currentUser.uid).collection('totalPortfolioValueHistory').where('time', '<=', (now-(stepper*(i))-margin)).orderBy("time", "desc").limit(1).get().then(docSnap => {
                docSnap.forEach(record => {
                  endArr.push(record.data())
                })
              })
            }
            
            setAllChart30d(endArr)
            setLoadingChart(false)
          }
          else{
            endArr = allChart30d
          }
        }

        else if(range.label == '14D'){
          setLoadingChart(true)

          if(allChart14d.length < 2){
            let stepper = 1680 * 60 * 1000
            endArr = []
            for(let i = 0; i < 12; i++){
              
              await app.firestore().collection('users').doc(currentUser.uid).collection('totalPortfolioValueHistory').where('time', '<=', (now-(stepper*(i))-margin)).orderBy("time", "desc").limit(1).get().then(docSnap => {
                docSnap.forEach(record => {
                  endArr.push(record.data())
                })
              })
            }
            
            setAllChart14d(endArr)
            setLoadingChart(false)
          }
          else{
            endArr = allChart14d
          }
        }

        else if(range.label == '7D'){
          setLoadingChart(true)

          if(allChart7d.length < 2){
            let stepper = 840 * 60 * 1000
            endArr = []
            for(let i = 0; i < 12; i++){
              
              await app.firestore().collection('users').doc(currentUser.uid).collection('totalPortfolioValueHistory').where('time', '<=', (now-(stepper*(i))-margin)).orderBy("time", "desc").limit(1).get().then(docSnap => {
                docSnap.forEach(record => {
                  endArr.push(record.data())
                })
              })
            }
            
            setAllChart7d(endArr)
            setLoadingChart(false)
          }
          else{
            endArr = allChart7d
          }
        }

        else if(range.label == '3D'){
          setLoadingChart(true)

          if(allChart3d.length < 2){
            let stepper = 360 * 60 * 1000
            endArr = []
            for(let i = 0; i < 12; i++){
              
              await app.firestore().collection('users').doc(currentUser.uid).collection('totalPortfolioValueHistory').where('time', '<=', (now-(stepper*(i))-margin)).orderBy("time", "desc").limit(1).get().then(docSnap => {
                docSnap.forEach(record => {
                  endArr.push(record.data())
                })
              })
            }
            
            setAllChart3d(endArr)
            setLoadingChart(false)
          }
          else{
            endArr = allChart3d
          }
        }

        else if(range.label == '24H'){
          setLoadingChart(true)

          if(allChart24h.length < 2){
            let stepper = 120 * 60 * 1000
            endArr = []
            for(let i = 0; i < 12; i++){
              
              await app.firestore().collection('users').doc(currentUser.uid).collection('totalPortfolioValueHistory').where('time', '<=', (now-(stepper*(i))-margin)).orderBy("time", "desc").limit(1).get().then(docSnap => {
                docSnap.forEach(record => {
                  endArr.push(record.data())
                })
              })
            }
            
            setAllChart24h(endArr)
            setLoadingChart(false)
          }
          else{
            endArr = allChart24h
          }
        }

        else if(range.label == '6H'){
          if(allChart6h.length < 2){
            let stepper = 30 * 60 * 1000
            endArr = []
            for(let i = 0; i < 12; i++){
              
              await app.firestore().collection('users').doc(currentUser.uid).collection('totalPortfolioValueHistory').where('time', '<=', (now-(stepper*(i))-margin)).orderBy("time", "desc").limit(1).get().then(docSnap => {
                docSnap.forEach(record => {
                  endArr.push(record.data())
                })
              })
            }
            
            setAllChart6h(endArr)
          }
          else{
            endArr = allChart6h
          }
        } 

        else if(range.label == '3H'){
          if(allChart3h.length < 2){
            let stepper = 15 * 60 * 1000
            endArr = []
            for(let i = 0; i < 12; i++){
              
              await app.firestore().collection('users').doc(currentUser.uid).collection('totalPortfolioValueHistory').where('time', '<=', (now-(stepper*(i))-margin)).orderBy("time", "desc").limit(1).get().then(docSnap => {
                docSnap.forEach(record => {
                  endArr.push(record.data())
                })
              })
            }
            
            setAllChart3h(endArr)
          }
          else{
            endArr = allChart3h
          }
        } 

        else if(range.label == '1H'){
          if(allChart1h.length < 2){
            let stepper = 5 * 60 * 1000
            endArr = []
            for(let i = 0; i < 12; i++){
              
              await app.firestore().collection('users').doc(currentUser.uid).collection('totalPortfolioValueHistory').where('time', '<=', (now-(stepper*(i))-margin)).orderBy("time", "desc").limit(1).get().then(docSnap => {
                docSnap.forEach(record => {
                  endArr.push(record.data())
                })
              })
            }
            
            setAllChart1h(endArr)
          }
          else{
            endArr = allChart1h
          }
        } 

        else if(range.label == '30M'){
          if(allChart30m.length < 2){
            let stepper = 5 * 60 * 1000
            endArr = []
            for(let i = 0; i < 6; i++){
              
              await app.firestore().collection('users').doc(currentUser.uid).collection('totalPortfolioValueHistory').where('time', '<=', (now-(stepper*(i))-margin)).orderBy("time", "desc").limit(1).get().then(docSnap => {
                docSnap.forEach(record => {
                  endArr.push(record.data())
                })
              })
            }
            
            setAllChart30m(endArr)
          }
          else{
            endArr = allChart30m
          }
        } 

        else if(range.label == '15M'){
          if(allChart15m.length < 2){
            let stepper = 5 * 60 * 1000
            endArr = []
            for(let i = 0; i < 3; i++){
              
              await app.firestore().collection('users').doc(currentUser.uid).collection('totalPortfolioValueHistory').where('time', '<=', (now-(stepper*(i))-margin)).orderBy("time", "desc").limit(1).get().then(docSnap => {
                docSnap.forEach(record => {
                  endArr.push(record.data())
                })
              })
            }
            
            setAllChart15m(endArr)
          }
          else{
            endArr = allChart15m
          }
        } 

        endArr.sort((a,b) => {
          return a.time - b.time
        })

        endArr && endArr.map((recordData, index) => {        
                let record = recordData
                record = {...record, id: record.id}
                if(true){
                    list.push(record)
                    let tempDate = new Date(record.time)
                    let time = `${tempDate.getHours() < 10 ? "0"+tempDate.getHours() : tempDate.getHours()}:${tempDate.getMinutes() < 10 ? "0"+tempDate.getMinutes() : tempDate.getMinutes()}`
                    let day = `${tempDate.getDate() < 10 ? "0"+tempDate.getDate() : tempDate.getDate()}.${tempDate.getMonth()+1 < 10 ? "0"+(tempDate.getMonth()+1) : tempDate.getMonth()+1}.${tempDate.getFullYear()}`
                    values.push((record.value).toFixed(2))
                    labels.push(`${day} ${time}`) 
                    timestamps.push(record.time)
                    first = false
                    if(record.time > (timestamps[timestamps.length-1] + 55 * 1000)){
                      list.push(record)
                      let tempDate = new Date(record.time)
                      let time = `${tempDate.getHours() < 10 ? "0"+tempDate.getHours() : tempDate.getHours()}:${tempDate.getMinutes() < 10 ? "0"+tempDate.getMinutes() : tempDate.getMinutes()}`
                      let day = `${tempDate.getDate() < 10 ? "0"+tempDate.getDate() : tempDate.getDate()}.${tempDate.getMonth()+1 < 10 ? "0"+(tempDate.getMonth()+1) : tempDate.getMonth()+1}.${tempDate.getFullYear()}`
                      values.push((record.value).toFixed(2))
                      labels.push(`${day} ${time}`) 
                      timestamps.push(record.time)
                    }  
                }
                if(index+1 == endArr.length){
                  setChartData({
                    labels: labels,
                    datasets: [
                      {
                        label: `Cena w ${userData && userData.currency}`,
                        data: values,
                        borderColor: 'rgb(37, 133, 87)',
                        backgroundColor: 'rgba(33, 191, 115, 1)',
                        fill: true,
                      }
                    ]
                  })
            
                  setChartOptions({
                      responsive: true,
                      plugins: {
                          legend: {
                              display: false
                          },
                      },
                      elements: {
                        line: {
                          tension: 0.5
                        }
                      },
                      scales: {
                        xAxis: {
                          ticks: {
                              maxTicksLimit: 15
                          }
                        }
                      }
                  })

                  setLoadingChart(false)
                  setTotalPortfolioValueHistory(list)
                }
              })
    }
  }

  const handleInsertFiatOut = async (uid, amount, time) => {
    try{
      await app.firestore()
      .collection('users')
      .doc(uid)
      .collection('bankTransfers')
      .add({
        date: time,
        amount: parseFloat(amount),
        type: 'cashOut'
      })

      await refreshSite()
    }
    catch(e){
      console.log(e)
    }
  }

  const handleInsertFiatIn = async (uid, amount, time) => {
    try{
      await app.firestore()
      .collection('users')
      .doc(uid)
      .collection('bankTransfers')
      .add({
        date: time,
        amount: parseFloat(amount),
        type: 'cashIn'
      })

      await refreshSite()
    }
    catch(e){
      console.log(e)
    }
  }

  const fetchAllTransactions = async () => {
      try{
        let list = []
        let fts = []
        let ftsEnd = []
        portfolioCoinsValues && portfolioCoinsValues.map(async (coin, index) => {
          await app.firestore()
          .collection('users')
          .doc(currentUser.uid)
          .collection('portfolio')
          .doc(coin.coingeckoID)
          .collection('transactions')
          .get()
          .then(async sub => {
            if(sub.docs.length >= 0){
              await app.firestore()
              .collection('users')
              .doc(currentUser.uid)
              .collection('portfolio')
              .doc(coin.coingeckoID)
              .collection('transactions')
              .get()
              .then((documentSnapshot) => {
                  documentSnapshot.forEach(doc => {
                    if(coin.show){
                      let output = doc.data()
                      output = {...output, uid: doc.id, coin: coin.coingeckoID, coinFullName: coin.fullName, logo: coin.logo, shortName: coin.shortName, formated: output.amount > 1 ? numberWithCommas(output.amount) : output.amount}
                      if(output.futures == 0 || output.futures == NaN || output.futures == undefined) list.push(output)
                    }
                  })
              })

              await app.firestore()
              .collection('users')
              .doc(currentUser.uid)
              .collection('portfolio')
              .doc(coin.coingeckoID)
              .collection('futures')
              .get()
              .then((documentSnapshot) => {
                  documentSnapshot.forEach(doc => {
                    if(coin.show){
                      let output = doc.data()
                      if(output.status == "on"){
                        output = {...output, uid: doc.id, coingeckoID: coin.coingeckoID, coinFullName: coin.fullName, logo: coin.logo, shortName: coin.shortName}
                        fts.push(output)
                      }
                      if(output.status == "off"){
                        output = {...output, uid: doc.id, coingeckoID: coin.coingeckoID, coinFullName: coin.fullName, logo: coin.logo, shortName: coin.shortName}
                        ftsEnd.push(output)
                      }
                    }
                  })
              })
            }
            if(index == portfolioCoinsValues.length - 1){
              // list.sort((a,b) => {return (b.date - a.date)})
              fts.sort((a,b) => {return (b.endDate - a.endDate)})
              ftsEnd.sort((a,b) => {return (b.endDate - a.endDate)})
              setAllFutures(fts)
              setAllFuturesEnd(ftsEnd)

              try{
                await app.firestore()
                .collection('users')
                .doc(currentUser.uid)
                .collection('bankTransfers')
                .get()
                .then(querySnap => {
                  querySnap.forEach(record => {
                    let data = record.data()
                    data = {...data, uid: record.id}
                    list.push(data)
                  })
                  list.sort((a,b) => {return (b.date - a.date)})
                  setAllTransactions(list)
                })
              }
              catch(e){
                console.log(e)
              }
            }
          })
        })
      }
      catch(e){
        console.error(e)
      }
  }

  const handleDeleteHistoryPortfolioValueRecord = async (idOfDoc) => {
    // console.log(`deleting history value record with id: ${idOfDoc}`)
    // if(window.confirm("u sure to delete this record from db?")){
    //   try{
    //     setLoading(true)

    //     await app.firestore()
    //     .collection('users')
    //     .doc(currentUser.uid)
    //     .collection('totalPortfolioValueHistory')
    //     .doc(idOfDoc)
    //     .delete()

    //     await app.firestore()
    //       .collection('users')
    //       .doc(currentUser.uid)
    //       .update({
    //         fastUpdate: true
    //       })

    //     setTimeout(() => {
    //       // fetchTotalPortfolioValueHistory()
    //       refreshSite()
    //       setLoading(false)
    //     }, 1000)
  
    //   }
    //   catch(e){
    //     console.log(e)
    //     setLoading(false)
    //   }
    // }
  }

  const refreshSite = async () => {
    await socket.emit('update-user-profile', ({uid: currentUser.uid}))

    await app.firestore()
    .collection('users')
    .doc(currentUser && currentUser.uid)
    .get()
    .then(async (documentSnapshot) => {
      if(documentSnapshot.exists){
        setLoading(true)
        let currentStamp = new Date().valueOf()
        let response = documentSnapshot.data()
        let lastUpd = response.lastUpdate
        if(currentStamp > lastUpd + 5000){
          await fetchUserData()
          await fetchCoinsData()
          await fetchFullUserPortfolio()
          await fetchAllUsersDataToChangeStatus()
          await fetchAllTransactions()
          setLoading(false)
        }
      }
    })
  }

  const changeColorMode = () => {
    toast(`Dark mode comming soon...`, {
      type: 'info',
    })
  }

  const hideCoinInDB = async (type, coin) => {
    if(type == 'hide'){
      try{
        await app.firestore()
        .collection('users')
        .doc(currentUser.uid)
        .collection('portfolio')
        .doc(coin)
        .update({
          show: coin == 'bitcoin' || coin == 'binancecoin' ? true: false
        })

        alert(`Ukryto z widocznych: ${coin}`)
        fetchFullUserPortfolio()
      }
      catch(e)
      {
        console.log(e)
      }
    }
    if(type == 'show'){
      try{
        await app.firestore()
        .collection('users')
        .doc(currentUser.uid)
        .collection('portfolio')
        .doc(coin)
        .update({
          show: true
        })

        alert(`Ustawiono widoczność dla: ${coin}`)
        fetchFullUserPortfolio()
      }
      catch(e)
      {
        console.log(e)
      }
    }
  }
 
  useEffect(() => {
    refreshSite()
    setTimeout(() => {
      console.log('loading false after 8 seconds...')
      setLoading(false)
    }, 8000)
  }, []) 
 
  useEffect(() => {
    userData && userPortfolio && collectUserPortfolioData()
    userPortfolio && userData && fetchTotalPortfolioValueHistory()
  }, [userPortfolio])

  useEffect(() => {
    portfolioCoinsValues && fetchAllTransactions()
  }, [portfolioCoinsValues])

  return (
    <>
      <ToastContainer />
      <Container fluid className="px-0 d-flex justify-content-between flex-column" style={{minHeight: '100vh', position: 'relative'}}>
      <div className="d-flex justify-content-start flex-column">
      <NavbarMain 
        colorMode={colorMode} 
        changeColorMode={changeColorMode} 
        handleLogout={handleLogout} 
        refreshSite={refreshSite} 
        changeVisibility={changeVisibility} 
        visibility={visibility}
        loading={loading}
        userData={userData}
        activeAccount={activeAccount}
        allTransactions={allTransactions} />
        { (!loading) && activeAccount ?  
        <Container className="px-0 pt-5">
          <ScrollToTop />
          <Switch>
            <Route path="/panel/dashboard" exact>
              { userData &&
              <PanelMain 
                convertDateToHumanFriendlyDATE={convertDateToHumanFriendlyDATE}
                addZeroes={addZeroes}
                mostValueCoin={mostValueCoin}
                lowValueCoin={lowValueCoin}
                mostTransactionCoinState={mostTransactionCoinState}
                biggestLostCoin={biggestLostCoin}
                mostInvestedUSDCoin={mostInvestedUSDCoin}
                biggestProfitCoin={biggestProfitCoin}
                biggestLostCurrencyCoin={biggestLostCurrencyCoin}
                biggestProfitCurrencyCoin={biggestProfitCurrencyCoin}
                fetchAllTransactions={fetchAllTransactions}
                allTransactions={allTransactions}
                fetchTotalPortfolioValueHistory={fetchTotalPortfolioValueHistory}
                userData={userData}
                convertDateToHumanFriendly={convertDateToHumanFriendly}
                chartData={chartData}
                chartOptions={chartOptions}
                numberWithCommas={numberWithCommas}
                portfolioCoinsValues={portfolioCoinsValues}
                totalPortfolioValueHistory={totalPortfolioValueHistory}
                fetchFullUserPortfolio={fetchFullUserPortfolio}
                visibility={visibility}
                hideChartOptions={hideChartOptions}
                changeRangeOfChart={changeRangeOfChart}
                currentChartRange={currentChartRange}
                chartRange={chartRange}
                refreshSite={refreshSite}
                fetchUserData={fetchUserData}
                fetchCoinsData={fetchCoinsData}
                loadingChart={loadingChart}
                sendRaportToEmail={sendRaportToEmail}
              /> }
            </Route>
            <Route path="/panel/browse" exact>
              <PanelMovements
                visibility={visibility}
                fetchFullUserPortfolio={fetchFullUserPortfolio}
                transferCryptoAction={transferCryptoAction}
                sellCryptoAction={sellCryptoAction}
                fetchCoinsData={fetchCoinsData}
                setEditingCoins={setEditingCoins}
                buyCryptoAction={buyCryptoAction}
                backToChooseOption={backToChooseOption}
                editingCoins={editingCoins}
                startBuying={startBuying}
                startSelling={startSelling}
                startTransfering={startTransfering}
                portfolioCoinsValues={portfolioCoinsValues}
                coinsData={coinsData}
                userData={userData}
                userPortfolio={userPortfolio}
                fetchTotalPortfolioValueHistory={fetchTotalPortfolioValueHistory}
                fetchUserData={fetchUserData}
                numberWithCommas={numberWithCommas} />
            </Route>
            <Route path="/panel/history/:coin?">
              <CoinHistory
                handleUpdateFiatTransaction={handleUpdateFiatTransaction}
                convertDateToHumanFriendlyDATE={convertDateToHumanFriendlyDATE}
                visibility={visibility}
                editSpecificTransactionOfCoin={editSpecificTransactionOfCoin}
                removeSpecificTransactionOfCoin={removeSpecificTransactionOfCoin}
                removeSpecificTransactionOfCoinFiat={removeSpecificTransactionOfCoinFiat}
                numberWithCommas={numberWithCommas}
                convertDateToHumanFriendly={convertDateToHumanFriendly}
                coinsData={coinsData}
                userPortfolio={userPortfolio}
                getAllTransactionBySpecificCoin={getAllTransactionBySpecificCoin}
                userData={userData}
                allTransactions={allTransactions}
                transactionsOfCoin={transactionsOfCoin}
              />
            </Route>
            <Route path="/panel/transaction/:coin/:action">
              <CoinMovement 
                numberWithCommas={numberWithCommas}
                coinsData={coinsData} 
                userData={userData}
                initFuturesCrypto={initFuturesCrypto}
                userPortfolio={userPortfolio}
                buyCryptoAction={buyCryptoAction}
                sellCryptoAction={sellCryptoAction}
                transferCryptoAction={transferCryptoAction}
              />
            </Route>
            <Route path="/panel/coin/:coinid">
              <CoinSingle 
                userData={userData}
                numberWithCommas={numberWithCommas}
                coinsData={coinsData}
                visibility={visibility}
                transactionsOfCoin={transactionsOfCoin}
                convertDateToHumanFriendly={convertDateToHumanFriendly}
                portfolioCoinsValues={portfolioCoinsValues}
                getAllTransactionBySpecificCoin={getAllTransactionBySpecificCoin}
              />
            </Route>
            <Route path="/panel/wallet">
              <PanelCoins 
                visibility={visibility}
                numberWithCommas={numberWithCommas}
                userData={userData} 
                tableData={portfolioCoinsValues} />
            </Route> 
            <Route path="/panel/settings" exact>
              <PanelSettings 
                hideCoinInDB={hideCoinInDB}
                userPortfolio={userPortfolio}
                handleDeleteHistoryPortfolioValueRecord={handleDeleteHistoryPortfolioValueRecord}
                totalPortfolioValueHistoryDONTMOVE={totalPortfolioValueHistoryDONTMOVE}
                userData={userData}
                handleInsertFiatIn={handleInsertFiatIn}
                checkIfCryptoExistInCoingeckoApi={checkIfCryptoExistInCoingeckoApi}
                existInCoingecko={existInCoingecko}
                searchedCryptoToAdd={searchedCryptoToAdd}
                addNewCryptoToDatabase={addNewCryptoToDatabase}
                searched={searched}
                refreshSite={refreshSite}
                coinsData={coinsData}
                handleInsertFiatOut={handleInsertFiatOut}
                convertDateToHumanFriendly={convertDateToHumanFriendly}
                deleteCoinFromDB={deleteCoinFromDB} />
            </Route> 
            <Route path="/panel/chart" exact>
              <Chart 
                userData={userData}
                visibility={visibility}
                hideChartOptions={hideChartOptions}
                changeRangeOfChart={changeRangeOfChart}
                currentChartRange={currentChartRange}
                chartRange={chartRange}
                chartData={chartData}
                loadingChart={loadingChart}
                chartOptions={chartOptions}
                totalPortfolioValueHistory={totalPortfolioValueHistory}
              />
            </Route>
            <Route path="/panel/changelog" exact>
              <Changelog
                userData={userData} />
            </Route>
            <Route path="/panel/reports" exact>
              <Reports
                fetchUserData={fetchUserData}
                userData={userData} />
            </Route>
            <Route path="/panel/about" exact>
              <About
                userData={userData} />
            </Route>
            <Route path="/panel/futures" exact>
              <Futures
                fetchCloseFuturePosition={fetchCloseFuturePosition}
                allFutures={allFutures}
                allFuturesEnd={allFuturesEnd}
                visibility={visibility}
                fetchRetryPostionFuture={fetchRetryPostionFuture}
                coinsData={coinsData}
                convertDateToHumanFriendly={convertDateToHumanFriendly}
                numberWithCommas={numberWithCommas}
                fetchDelPostionFuture={fetchDelPostionFuture}
                userData={userData} />
            </Route>
            <Route path="/panel/admin" exact>
              <PanelAdmin
                shortUsersInfo={shortUsersInfo}
                changeUserAdminStatus={changeUserAdminStatus}
                changeUserReportStatus={changeUserReportStatus}
                changeUserActiveStatus={changeUserActiveStatus}
                fetchAllUsersDataToChangeStatus={fetchAllUsersDataToChangeStatus}
                handleDeleteHistoryPortfolioValueRecord={handleDeleteHistoryPortfolioValueRecord}
                convertDateToHumanFriendly={convertDateToHumanFriendly}
                totalPortfolioValueHistoryDONTMOVE={totalPortfolioValueHistoryDONTMOVE}
                userData={userData} />
            </Route>
            <Route path="/panel/report-bug" exact>
              <ReportBug
                userData={userData} />
            </Route>
          </Switch>

          { errorAlert && <ErrorAlert errorTitle={errorTitle} errorMessage={errorMessage} errorType={errorType} /> }
        </Container> : 
        <Container>
          <Row>
            <Col xs={12} className="mt-5 pt-5">
              { 
              activeAccount == null ? 
              <>
                <h4 style={{display: 'flex', justifyContent: 'center', marginTop: '50px', textAlign: 'center'}}>
                  Pobieranie informacji o Twoim koncie...
                </h4>    
                <Loader />
              </> :
              activeAccount == false ? 
              <>
                <div className="mt-5 text-center"><FontAwesomeIcon icon={faFaceFrown} style={{fontSize: '34px', color: 'white', marginBottom: '25px'}} /><br/><span style={{color: '#21bf73'}}>Brak aktywnego konta</span><br /><small className="text-muted">Aby aktywować, skontaktuj się pod: <b style={{color: 'rgb(230 230 230)'}}>cryptofolioio@gmail.com</b></small></div>
              </> : 
              <>
                <h4 style={{display: 'flex', justifyContent: 'center', marginTop: '50px', textAlign: 'center'}}>
                  Pobieranie informacji o Twoim koncie...
                </h4>    
                <Loader />
              </>
              }
            </Col>
          </Row>
        </Container>
        }
      </div>
      <Footer />
    </Container>
    </>
    
  )
}
