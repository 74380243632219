import React, { useEffect, useState, useRef } from 'react'
import { Card, Row, Col, Container, Form, Button, FormControl } from 'react-bootstrap'
import { useParams, NavLink } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeftLong, faFloppyDisk, faBasketShopping, faMoneyBill1Wave, faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons'

import style from './CoinMovement.module.css'
export default function CoinMovement(props) {

    const {coin, action} = useParams()
    const [ currentCoin, setCurrentCoin ] = useState()
    const [ currentAction, setCurrentAction ] = useState()
    const [ maxToSell, setMaxToSell ] = useState(0)
    const [ rate, setRate ] = useState(0)
    const [ now, setNow ] = useState(new Date())
    const [ date, setDate ] = useState(`${new Date().getFullYear()}-${new Date().getMonth()+1 < 10 ? "0"+(new Date().getMonth()+1) : new Date().getMonth()+1}-${new Date().getDate() < 10 ? "0"+new Date().getDate() : new Date().getDate()}`)
    const [ time, setTime ] = useState(`${new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()}:${new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()}`)
    const [ sAmount, setSAmount ] = useState()
    const [ sumOver, setSumOver ] = useState(0)
    const [ sumOverS, setSumOverS ] = useState(0)
    const [ insertRate, setInsertRate ] = useState(0)
    const [ insertRateS, setInsertRateS ] = useState(0)
    const [ insertAmount, setInsertAmount ] = useState(0)
    const [ insertFutures, setInsertFutures ] = useState(0)
    const [ futuresRange, setFuturesRange ] = useState([])

    const cryptoAmountRef = useRef()
    const cryptoRateRef = useRef()

    useEffect(() => {
        let lev = []

        for(let u = 1; u <= 125; u++){
            if(u < 25){
                lev.push(u)
            }
            else if(u < 50){
                if(u % 5 == 0) lev.push(u)
            }
            else if(u < 100){
                if(u % 10 == 0) lev.push(u)
            }
            else{
                if(u % 25 == 0) lev.push(u)
            }
        }

        setFuturesRange(lev)
    }, [])

    const handleBuyCrypto = (coin, amount, rate, time, futures, type) => {
        if(amount && rate){
            try{
                if(futures > 0 && futures <= 125) props.initFuturesCrypto(currentCoin, amount, rate, time, futures, type)
                else props.buyCryptoAction(currentCoin, amount, rate, time, futures)
                cryptoAmountRef.current.value = ''
                cryptoRateRef.current.value = ''
                console.log('registered bought succesfully')
            }
            catch(e){
                console.log(e)
            }
        }
    }

    const handleSellCrypto = (coin, amount, rate, max, time, futures, type) => {
        console.log(rate, amount, max, currentCoin, time, futures)
        if(amount && rate && (amount <= max  || futures > 0)){
            try{
                if(futures > 0 && futures <= 125) props.initFuturesCrypto(currentCoin, amount, rate, time, futures, type)
                else props.sellCryptoAction(currentCoin, amount, rate, max, time, futures)
                setSAmount()
                cryptoAmountRef.current.value = ''
                cryptoRateRef.current.value = ''
                console.log('registered sold succesfully')
            }
            catch(e){
                console.log(e)
            }
        }
        // else{
        //     if(futures != 0){
        //         toast('Zlecenie futures nie są jeszcze obsługiwane. Wybierz MARKET SPOT.', {
        //             type: 'warning'
        //         })
        //     }
        //     else{
        //         console.error(`Uzupełnij kurs i ilość waluty.`)
        //         toast(`Uzupełnij prawidłowo wszystkie pola. ${amount > max ? `Maksymalna ilość waluty jaką możesz sprzedać to: ${max}` : ''}`, {
        //             type: 'warning'
        //         })
        //     }
        // }
    }

    const handleTransferCrypto = (coin, amount, time) => {
        console.log(amount)
        if(amount){
            try{
                props.transferCryptoAction(currentCoin, amount, time)
                cryptoAmountRef.current.value = ''
                console.log('registered transfer succesfully')
            }
            catch(e){
                console.log(e)
            }
        }
        else{
            console.error(`Uzupełnij kurs i ilość waluty.`)
            toast(`Uzupełnij kurs i ilość waluty.`, {
                type: 'warning'
            })  
        }
    }

    const insertMax = () => {
       setSAmount(maxToSell)
    }

    const handleChangeDate = (datee) => {
        setDate(datee)
        let dat = datee.split("-")
        let tempD = new Date(now)
        tempD.setFullYear(dat[0])
        tempD.setMonth(dat[1]-1)
        tempD.setDate(dat[2])
        if(new Date(tempD) != 'Invalid Date')  setNow(tempD)
    }

    const handleChangeTime = (timee) => {
        setTime(timee)
        let t = timee.split(':')
        let newDateConverted = new Date(date)
        newDateConverted.setHours(t[0], t[1])
        if(new Date(newDateConverted) != 'Invalid Date') setNow(newDateConverted)
    }

    const handleInserAmount = (amnt) => {
        setSumOver((amnt*parseFloat(insertRate)).toFixed(2))
        setInsertAmount(amnt)
    }

    const handleInsertRate = (rt) => {
        setSumOver((rt*parseFloat(insertAmount)).toFixed(2))
        setInsertRate(rt)
    }

    const handleInsertRateS = (rt) => {
        setSumOverS((rt*parseFloat(sAmount)).toFixed(2))
        setInsertRate(rt)
    }

    useEffect(() => {
        props.coinsData && props.coinsData.map(coiny => {
            props.userPortfolio && props.userPortfolio.map(coinP => {
                if(coinP.coingeckoID == coin){
                    setMaxToSell(coinP.totalAmountOfCoin)
                }
            })
            if(coiny.coingeckoID == coin){
                setRate(coiny.actualRate)
                setInsertRate(coiny.actualRate)
                setInsertRateS(coiny.actualRate)
                setCurrentCoin(coiny)   
                setCurrentAction(action)
            } 
        })
    }, [props.userPortfolio])

    useEffect(() => {
        console.log(now)
    }, [date, time])

    useEffect(() => {
        let f = sAmount ? sAmount : 0
        setSumOverS((f*insertRateS).toFixed(2))
    }, [sAmount, insertRateS])

    useEffect(() => {
        console.log(`sumover ${sumOver} = amount (${insertAmount}) + rate (${insertRate})`)
    }, [sumOver])

    return (
        <Container className={style.MainParent}>
            <ToastContainer />
            <Row style={{marginTop: '50px'}} className={style.title}>
                <Col xs={12}>
                    <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
                    <h6 style={{fontWeight: '400'}}>Obecnie posiadasz: <span style={{fontWeight: '600'}}>{`${(maxToSell && maxToSell > 1 ? props.numberWithCommas(maxToSell) : maxToSell.toFixed(8))} ${currentCoin && (currentCoin.shortName).toUpperCase()}`}</span>, wartego około <span style={{fontWeight: '600'}}>{`${(rate * maxToSell).toFixed(2)}`}$</span></h6>
                </Col>
            </Row>
            <Row style={{marginTop: '50px'}}>
                {
                    (currentAction && currentCoin) && <Card className="px-0">
                        <Card.Header>
                            <img src={currentCoin.logo} style={{maxHeight: '20px', marginRight: '5px'}} />
                            <span>{currentCoin.longName}</span>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs={12} className="d-flex justify-content-center">
                                {   (action && currentCoin) &&
                                    action == 'buy' || action == 'sell' || action == 'transfer' ? 
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <Button as={NavLink} to={`/panel/transaction/${coin}/buy`} className={action == 'buy' ? `${style.actionGreenActive} ${style.actionBTNactive}` : `${style.actionGreen} ${style.actionBTN}`}>
                                            <span className="d-none d-md-flex justify-content-center align-items-center">ZAKUP</span>
                                            <span className="py-2 px-1 d-flex d-md-none justify-content-center align-items-center">
                                                <FontAwesomeIcon style={{fontSize: '20px'}} icon={faBasketShopping} />
                                            </span>
                                        </Button>
                                        <Button as={NavLink} to={`/panel/transaction/${coin}/sell`} className={action == 'sell' ? `${style.actionRedActive} ${style.actionBTNactive}` : `${style.actionRed} ${style.actionBTN}`}>
                                            <span className="d-none d-md-flex justify-content-center align-items-center">SPRZEDAŻ</span>
                                            <span className="py-2 px-1 d-flex d-md-none justify-content-center align-items-center">
                                                <FontAwesomeIcon style={{fontSize: '20px'}} icon={faMoneyBill1Wave} />
                                            </span>
                                        </Button>
                                        <Button as={NavLink} to={`/panel/transaction/${coin}/transfer`} className={action == 'transfer' ? `${style.actionBlueActive} ${style.actionBTNactive}` : `${style.actionBlue} ${style.actionBTN}`}>
                                            <span className="d-none d-md-flex justify-content-center align-items-center">TRANSFER</span>
                                            <span className="py-2 px-1 d-flex d-md-none justify-content-center align-items-center">
                                                <FontAwesomeIcon style={{fontSize: '20px'}} icon={faHandHoldingDollar} />
                                            </span>
                                        </Button>
                                    </div>
                                    : '404'
                                }
                                </Col>
                                <Col xs={12}>
                                {
                                    (action && currentCoin) &&
                                    action == 'buy' ? 
                                    <Form>
                                        <Form.Group style={{marginTop: '25px'}}>
                                            <Form.Label>Ilość waluty</Form.Label>
                                            <Form.Control type="number" onWheel={(e) => e.target.blur()} inputMode='decimal' onChange={(e) => handleInserAmount(e.target.value)} placeholder="np. 0.00245" ref={cryptoAmountRef} required />
                                        </Form.Group>
                                        <Form.Group className="mt-4">
                                            <Row>
                                                <Col xs={12} md={8}>
                                                    <Form.Label>Kurs kupna (w USD)</Form.Label>
                                                    <Form.Control type="number" onWheel={(e) => e.target.blur()} inputMode='decimal' onChange={(e) => handleInsertRate(e.target.value)} defaultValue={currentCoin.actualRate} ref={cryptoRateRef} placeholder={coin.actualRate} required />
                                                </Col>
                                                <Col xs={12} md={4} className="mt-4 mt-md-0">
                                                    <Form.Label>Dźwignia</Form.Label>
                                                    <Form.Select value={insertFutures} onChange={e => { setInsertFutures(e.target.value); }} >
                                                        <option value="0">Spot Market</option>
                                                        {
                                                            futuresRange && futuresRange.map(x => {
                                                                return (
                                                                    <option key={x} value={x}>x{x}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group className="mt-3 mb-2">
                                            <Form.Label className="mb-0 mt-2">Czas zakupu</Form.Label>
                                            <div className="">
                                                {/* <span style={{fontSize: '13px', marginTop: '0px', paddingTop: '0px', opacity: '0.5'}}>Nie musisz wypełniać, jeśli chcesz wybrać dzisiejszą datę.</span> */}
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <Form.Control type="date" className="mx-0 mt-1" value={date} onChange={(e) => handleChangeDate(e.target.value)} required />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <Form.Control type="time" className="mx-0 mt-1" value={time} onChange={(e) => handleChangeTime(e.target.value)} required />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form.Group>
                                        <div>
                                            Wartość zakupu: <strong>~ {parseFloat(sumOver) && parseFloat(insertFutures) && sumOver > 0 ? props.numberWithCommas((parseFloat(sumOver) / (parseFloat(insertFutures) == 0 ? 1 : parseFloat(insertFutures))).toFixed(2)) : parseFloat(sumOver) != 0 ? props.numberWithCommas((parseFloat(sumOver)).toFixed(2)) : 0} USD</strong>
                                        </div>
                                        <Button className="mt-4 w-100 btn p-3" variant="outline-success" onClick={() => handleBuyCrypto(coin, cryptoAmountRef.current.value, cryptoRateRef.current.value, (new Date(now).valueOf()), insertFutures, 'long')}>
                                            <FontAwesomeIcon icon={faFloppyDisk} /> <span style={{marginLeft: '5px'}}>{insertFutures == 0 ? 'Zapisz zakup' : 'Graj LONG'}</span>
                                        </Button>
                                    </Form> : 
                                    action == 'sell' ? 
                                    <Form>
                                        <Form.Group style={{marginTop: '25px'}}>
                                            <Form.Label>Ilość waluty</Form.Label>
                                            <div className="row">
                                                <Col xs={12} md={8}>
                                                    <Form.Control type="number" onWheel={(e) => e.target.blur()} inputMode='decimal' value={sAmount} onChange={(e) => setSAmount(e.target.value)} placeholder="np. 0.00245" required />
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Button variant="secondary" onClick={insertMax} className="btn-sm w-100 h-100 mt-2 mt-md-0">MAX</Button>
                                                </Col>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mt-4">
                                            <Row>
                                                <Col xs={12} md={8}>
                                                    <Form.Label>Kurs sprzedaży (w USD)</Form.Label>
                                                    <Form.Control type="number" onWheel={(e) => e.target.blur()} inputMode='decimal' onChange={(e) => handleInsertRateS(e.target.value)} defaultValue={currentCoin.actualRate} max={maxToSell} ref={cryptoRateRef} placeholder={coin.actualRate} required />
                                                </Col>
                                                <Col xs={12} md={4} className="mt-4 mt-md-0">
                                                    <Form.Label>Dźwignia</Form.Label>
                                                    <Form.Select value={insertFutures} onChange={e => { setInsertFutures(e.target.value); }} >
                                                        <option value="0">Spot Market</option>
                                                        {
                                                            futuresRange && futuresRange.map((x, index) => {
                                                                return (
                                                                    <option key={index} value={x}>x{x}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                </Col>
                                            </Row>
                                            </Form.Group>
                                        <Form.Group className="mt-3 mb-2">
                                            <Form.Label className="mb-0 mt-2">Czas sprzedaży</Form.Label>
                                            <div className="">
                                                {/* <span style={{fontSize: '13px', marginTop: '0px', paddingTop: '0px', opacity: '0.5'}}>Nie musisz wypełniać, jeśli chcesz wybrać dzisiejszą datę.</span> */}
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <Form.Control type="date" className="mx-0 mt-1" value={date} onChange={(e) => handleChangeDate(e.target.value)} required />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <Form.Control type="time" className="mx-0 mt-1" value={time} onChange={(e) => handleChangeTime(e.target.value)} required />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form.Group>
                                        <div>
                                            Wartość sprzedażt: <strong>~ {parseFloat(sumOverS) && parseFloat(insertFutures) && sumOverS > 0 ? props.numberWithCommas((parseFloat(sumOverS) / (parseFloat(insertFutures) == 0 ? 1 : parseFloat(insertFutures))).toFixed(2)) : parseFloat(sumOverS) != 0 ? props.numberWithCommas((parseFloat(sumOverS)).toFixed(2)) : 0} USD</strong>
                                            {/* Wartość sprzedaży: <strong>~ {props.numberWithCommas(sumOverS)} USD</strong> */}
                                        </div>
                                        <Button className="mt-4 w-100 btn p-3" variant="outline-danger" onClick={() => handleSellCrypto(coin, sAmount, cryptoRateRef.current.value, maxToSell, (new Date(now).valueOf()), insertFutures, 'short')}>
                                            <FontAwesomeIcon icon={faFloppyDisk} /> <span style={{marginLeft: '5px'}}>{insertFutures == 0 ? 'Zapisz sprzedaż' : 'Graj SHORT'}</span>
                                        </Button>
                                    </Form>
                                    :
                                    action == 'transfer' ? 
                                    <Form>
                                        <Form.Group style={{marginTop: '25px', display: 'flex', flexDirection: 'column'}}>
                                            <Form.Label style={{marginBottom: '0px'}}>Ilość waluty</Form.Label>
                                            <span style={{fontSize: '13px', marginBottom: '7px', marginTop: '3px', opacity: '0.5'}}>Tokeny otrzymane np. jako reward z pool, airdrop bonus itp., realizowane po kursie 0.0 (darmowe tokeny)</span>
                                            <Form.Control type="number" onWheel={(e) => e.target.blur()} ref={cryptoAmountRef} inputMode='decimal' placeholder="np. 384.23" required />
                                        </Form.Group>
                                        <Form.Group className="mt-3 mb-2">
                                            <Form.Label className="mb-0 mt-2">Czas transferu</Form.Label>
                                            <div className="">
                                                {/* <span style={{fontSize: '13px', marginTop: '0px', paddingTop: '0px', opacity: '0.5'}}>Nie musisz wypełniać, jeśli chcesz wybrać dzisiejszą datę.</span> */}
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <Form.Control type="date" className="mx-0 mt-1" value={date} onChange={(e) => handleChangeDate(e.target.value)} required />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <Form.Control type="time" className="mx-0 mt-1" value={time} onChange={(e) => handleChangeTime(e.target.value)} required />
                                                    </Col>
                                                </Row>                                            </div>
                                        </Form.Group>
                                        <Button className="mt-4 w-100 btn p-3" variant="outline-primary" onClick={() => handleTransferCrypto(coin, cryptoAmountRef.current.value, (new Date(now).valueOf()))}>
                                            <FontAwesomeIcon icon={faFloppyDisk} /> <span style={{marginLeft: '5px'}}>Zapisz transfer</span>
                                        </Button>
                                    </Form>
                                    : 
                                    '404'
                                }
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                }
            </Row>
        </Container>
    )
}
