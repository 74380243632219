import React from 'react'
import { Card } from 'react-bootstrap'

import style from './TotalCashoutBox.module.css'

export default function TotalCashoutBox(props) {
  return (
    <Card className="mt-4">
        <Card.Header>
            Wypłaty FIAT
        </Card.Header>
        <Card.Body className={style.totalProfitBox}>
          <span>
            { props.userData && props.visibility == 2 ? props.userData.FIATout != undefined && props.numberWithCommas(props.userData.FIATout.toFixed(2)) : '*****'}$
          </span>
        </Card.Body>
    </Card>
  )
}
