import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Link } from 'react-router-dom'

import style from './Footer.module.css'

export default function Footer() {
  return (
    <Container fluid className={`${style.footer} py-3`}>
        <>
        <Container>
            <Row>
                <Col xs={12} md={6} className={`text-center text-md-start d-flex flex-row align-items-center justify-content-center justify-content-md-start flex-wrap ${style.leftFooter}`}>
                    <Link to="/panel/about">O projekcie</Link>
                    <span>•</span>
                    <Link to="/panel/changelog">Changelog</Link>
                    <span>•</span>
                    <Link to="/panel/report-bug">Zgłoś błąd</Link>
                </Col>
                <Col xs={12} md={6} className={`text-center text-md-end mt-2 mt-md-0 ${style.rightFooter}`}>
                    &copy; 2022 CryptoFolio<span style={{color: '#21bf73'}}>.io</span> <br/>
                    {/* created with ♥ by <a href="https://janmager.pl">janmager.pl</a> */}
                </Col>
            </Row>
        </Container>
        </>
    </Container>
  )
}
