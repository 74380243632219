import React, { useEffect } from 'react'
import { Card, Container, Row, Col, ListGroup } from 'react-bootstrap'

import style from './BestAndWorstCoin.module.css'

export default function BestAndWorstCoin(props) {
    return (
        <Card className="mt-4">
            <Card.Header>
                Statystyki bazując na twoim portfolio
            </Card.Header>
            <Card.Body>
                <Container className={style.statsBox}>
                    <Row>
                        <Col xs={6} md={6} className={`mt-3 text-center mb-1 ${style.biggestProfitBox}`}>
                            <h3>Największy zysk $</h3>
                            {
                                props.biggestProfitUsdCoin != undefined && props.biggestProfitUsdCoin ?
                                <>
                                    <div>
                                        <img className={props.visibility == 2 ? 'd-inline-flex' : 'd-none'} src={props.biggestProfitUsdCoin && props.biggestProfitUsdCoin.coinMain.logo} />
                                        <span>{props.visibility == 2 ? props.biggestProfitUsdCoin && props.biggestProfitUsdCoin.coinMain.shortName : '*****'}</span>
                                    </div>    
                                    <h4>{props.visibility == 2 ? props.biggestProfitUsdCoin && props.biggestProfitUsdCoin.coin.totalProfitCurrency.toFixed(2) : '*****'}$</h4>
                                    <h5>{props.visibility == 2 || props.visibility == 1 ? props.biggestProfitUsdCoin && props.biggestProfitUsdCoin.coin.totalProfitPercentage.toFixed(2) : '*****'} %</h5>
                                </>
                                : <span style={{fontSize: '14px', opacity: '0.3', fontWeight: '400'}}>brak zysku</span>
                            }
                        </Col>
                        <Col xs={6} md={6} className={`mt-3 text-center mb-1 ${style.biggestProfitBox}`}>
                            <h3>Największy zysk %</h3>
                            {
                                props.biggestProfitCoin != undefined && props.biggestProfitCoin ?
                                <>
                                    <div>
                                        <img className={props.visibility == 2 ? 'd-inline-flex' : 'd-none'} src={props.biggestProfitCoin && props.biggestProfitCoin.coinMain.logo} />
                                        <span>{props.visibility == 2 ? props.biggestProfitCoin && props.biggestProfitCoin.coinMain.shortName : '*****'}</span>
                                    </div>    
                                    <h4>{props.visibility == 2 || props.visibility == 1 ? props.biggestProfitCoin && props.biggestProfitCoin.coin.totalProfitPercentage.toFixed(2) : '*****'}%</h4>
                                    <h5>{props.visibility == 2 ? props.biggestProfitCoin && props.biggestProfitCoin.coin.totalProfitCurrency.toFixed(2) : '*****'} $</h5>
                                </>
                                : <span style={{fontSize: '14px', opacity: '0.3', fontWeight: '400'}}>brak zysku</span>
                            }
                        </Col>
                        <hr />
                        <Col xs={6} md={6} className={`mt-3 text-center mb-1 ${style.biggestLostBox}`}>
                            <h3>Największy strata $</h3>
                            {
                                props.biggestLostCurrencyCoin != undefined && props.biggestLostCurrencyCoin ?
                                <>
                                    <div>
                                        <img className={props.visibility == 2 ? 'd-inline-flex' : 'd-none'} src={props.biggestLostCurrencyCoin && props.biggestLostCurrencyCoin.coinMain.logo} />
                                        <span>{props.visibility == 2 ? props.biggestLostCurrencyCoin && props.biggestLostCurrencyCoin.coinMain.shortName : '*****'}</span>
                                    </div>                                    
                                    <h4>{props.visibility == 2 ? props.biggestLostCurrencyCoin && props.biggestLostCurrencyCoin != undefined && props.biggestLostCurrencyCoin.coin.totalProfitCurrency.toFixed(2) : '*****'}$</h4>
                                    <h5>{props.visibility == 2 || props.visibility == 1 ? props.biggestLostCurrencyCoin && props.biggestLostCurrencyCoin != undefined && props.biggestLostCurrencyCoin.coin.totalProfitPercentage.toFixed(2) : '*****'} %</h5>
                                </>
                                : <span style={{fontSize: '14px', opacity: '0.3', fontWeight: '400'}}>brak straty</span>
                            }
                        </Col>
                        <Col xs={6} md={6} className={`mt-3 text-center mb-1 ${style.biggestLostBox}`}>
                            <h3>Największy strata %</h3>
                            {
                                props.biggestLostCoin != undefined && props.biggestLostCoin ?
                                <>
                                    <div>
                                        <img className={props.visibility == 2 ? 'd-inline-flex' : 'd-none'} src={props.biggestLostCoin && props.biggestLostCoin.coinMain.logo} />
                                        <span>{props.visibility == 2 ? props.biggestLostCoin && props.biggestLostCoin.coinMain.shortName : '*****'}</span>
                                    </div>
                                    <h4>{props.visibility == 2 || props.visibility == 1 ? props.biggestLostCoin && props.biggestLostCoin.coin.totalProfitPercentage.toFixed(2) : '*****'}%</h4>
                                    <h5>{props.visibility == 2 ? props.biggestLostCoin && props.biggestLostCoin.coin.totalProfitCurrency.toFixed(2) : '*****'} $</h5>
                                </>
                                : <span style={{fontSize: '14px', opacity: '0.3', fontWeight: '400'}}>brak straty</span>
                            }
                        </Col>
                        <Col xs={12} className="px-0">
                            <ListGroup>
                                <ListGroup.Item className={style.listItemStats}>
                                    <h6>Najwięcej transakcji</h6>
                                    <span>
                                        <img src={props.mostTransactionCoin && props.mostTransactionCoin.coinMain && props.mostTransactionCoin.coinMain.logo && props.mostTransactionCoin.coinMain.logo} />
                                        <span>{props.mostTransactionCoin && props.mostTransactionCoin.coinMain && props.mostTransactionCoin.coinMain.longName && props.mostTransactionCoin.coinMain.longName} <b>({props.mostTransactionCoin && props.mostTransactionCoin.coinMain && props.mostTransactionCoin.coinMain.shortName && props.mostTransactionCoin.coinMain.shortName.toUpperCase()})</b> : </span>
                                        <b>{props.mostTransactionCoin && props.mostTransactionCoin.coin && props.mostTransactionCoin.coin.numberOfAllTransactions}</b>
                                    </span>
                                </ListGroup.Item>
                                <ListGroup.Item className={style.listItemStats}>
                                    <h6>Najwięcej zainwestowane $</h6>
                                    <span>
                                        <img src={props.mostInvestedUSDCoin && props.mostInvestedUSDCoin.coinMain && props.mostInvestedUSDCoin.coinMain.logo} />
                                        <span>{props.mostInvestedUSDCoin && props.mostInvestedUSDCoin.coinMain && props.mostInvestedUSDCoin.coinMain.longName && props.mostInvestedUSDCoin.coinMain.longName} <b>({props.mostInvestedUSDCoin &&props.mostInvestedUSDCoin.coinMain && props.mostInvestedUSDCoin.coinMain.shortName && props.mostInvestedUSDCoin.coinMain.shortName.toUpperCase()})</b> : </span>
                                        <b>{props.mostInvestedUSDCoin && props.mostInvestedUSDCoin.coin && props.mostInvestedUSDCoin.coin.totalInvestedInUSD.toFixed(2)} $</b>
                                    </span>
                                </ListGroup.Item>
                                <ListGroup.Item className={style.listItemStats}>
                                    <h6>Największa wartość w $</h6>
                                    <span>
                                        <img src={props.mostValueCoin && props.mostValueCoin.coinMain && props.mostValueCoin.coinMain.logo} />
                                        <span>{props.mostValueCoin && props.mostValueCoin.coinMain && props.mostValueCoin.coinMain.longName && props.mostValueCoin.coinMain.longName} <b>({props.mostValueCoin &&props.mostValueCoin.coinMain && props.mostValueCoin.coinMain.shortName && props.mostValueCoin.coinMain.shortName.toUpperCase()})</b> : </span>
                                        <b>{props.mostValueCoin && props.mostValueCoin.coin && props.mostValueCoin.coin.totalValueInUSD.toFixed(2)} $</b>
                                    </span>
                                </ListGroup.Item>
                                <ListGroup.Item className={style.listItemStats}>
                                    <h6>Najmniejsza wartość w $</h6>
                                    <span>
                                        <img src={props.lowValueCoin && props.lowValueCoin.coinMain && props.lowValueCoin.coinMain.logo} />
                                        <span>{props.lowValueCoin && props.lowValueCoin.coinMain && props.lowValueCoin.coinMain.longName && props.lowValueCoin.coinMain.longName} <b>({props.lowValueCoin &&props.lowValueCoin.coinMain && props.lowValueCoin.coinMain.shortName && props.lowValueCoin.coinMain.shortName.toUpperCase()})</b> : </span>
                                        <b>{props.lowValueCoin && props.lowValueCoin.coin && props.lowValueCoin.coin.totalValueInUSD.toFixed(2)} $</b>
                                    </span>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}
