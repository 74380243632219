import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'

import style from './TotalCashinBox.module.css'

export default function TotalCashinBox(props) {

  return (
    <Card className="mt-4">
        <Card.Header>
            Wpłaty FIAT
        </Card.Header>
        <Card.Body className={style.totalProfitBox}>
          <span>
          { props.userData && props.visibility == 2 ? props.userData.FIATin != undefined && props.numberWithCommas(props.userData.FIATin.toFixed(2)) : '*****'}$
          </span>
        </Card.Body>
    </Card>
  )
}
