import React, { useEffect, useState } from 'react'

import { Container, Row, Col, ListGroup, Modal, Button, Form } from 'react-bootstrap'
import LastUpdate from '../../components/LastUpdate/LastUpdate'
import { useAuth } from '../../contexts/AuthContext'
import Moment from 'react-moment';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateBack, faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons'


import style from './Futures.module.css'

export default function Futures(props) {
  const [ allLevar, setAllLevar ] = useState()
  const [ allLevarEnd, setAllLevarEnd] = useState()
  const [ endRateInput, setEndRateInput ] = useState()
  const [modalShow, setModalShow] = useState(false);
  const [ closingPosId, setClosingPosId ] = useState()
  const [ closingPosDoc, setClosingPosDoc ] = useState()
  const [ closingPosRate, setClosingPosRate] = useState()
  const [ closingPosDate, setClosingPosDate ] = useState(new Date())
  const [ date, setDate ] = useState(`${new Date().getFullYear()}-${new Date().getMonth()+1 < 10 ? "0"+(new Date().getMonth()+1) : new Date().getMonth()+1}-${new Date().getDate() < 10 ? "0"+new Date().getDate() : new Date().getDate()}`)
  const [ time, setTime ] = useState(`${new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()}:${new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()}`)
  const [ unrealizedPNLValue, setUnrealizedPNLValue ] = useState(0)

  const { currentUser } = useAuth()

  useEffect(() => {
    console.log('initing')
    let out = []
    let out2 = []
    let prevDate = null
    let endObj = []
    let dayProfit = 0
    let profitDolar = 0
    let allProfitFuturesEnd = 0
    props.allFutures && props.allFutures.map((order, index) => {
      props.coinsData && props.coinsData.map(coin => {
        if(coin.coingeckoID == order.coingeckoID){
            let dta = order
            dta = {...order, actualRate: coin.actualRate}
            out.push(dta)
        }
      })            
    })

    props.allFuturesEnd && props.allFuturesEnd.map((order, index) => {
      props.coinsData && props.coinsData.map(coin => {
        if(coin.coingeckoID == order.coingeckoID){
            let dta = order
            if(index == 0) endObj.push({ day: props.convertDateToHumanFriendly(order.endDate).substr(6, props.convertDateToHumanFriendly(order.endDate).length) })

            if(props.convertDateToHumanFriendly(order.endDate).substr(6, props.convertDateToHumanFriendly(order.endDate).length) == prevDate || prevDate == null){
              dta = {...order, actualRate: coin.actualRate}
              endObj.push(dta)
              if(order.type == 'long'){
                profitDolar = ((((order.endRate / order.rate) - 1) * order.levarage) * ((order.amount * order.rate)/order.levarage))
              }
              else if(order.type == 'short'){
                profitDolar = ((((order.rate / order.endRate) - 1) * order.levarage) * ((order.amount * order.rate)/order.levarage))
              }
              dayProfit += profitDolar
              if(index+1 == props.allFuturesEnd.length){
                out2.push(endObj)
                dayProfit = 0
              }
            }

            else{
              endObj.push({dayProfit: dayProfit})
              out2.push(endObj)
              dayProfit = 0
              dta = {...order, actualRate: coin.actualRate}
              endObj = []
              if(order.type == 'long'){
                profitDolar = ((((order.endRate / order.rate) - 1) * order.levarage) * ((order.amount * order.rate)/order.levarage))
              }
              else if(order.type == 'short'){
                profitDolar = ((((order.rate / order.endRate) - 1) * order.levarage) * ((order.amount * order.rate)/order.levarage))
              }
              dayProfit += profitDolar
              endObj.push({day: props.convertDateToHumanFriendly(order.endDate).substr(6, props.convertDateToHumanFriendly(order.endDate).length)}, dta)
              console.log('else')
              if(index+1 == props.allFuturesEnd.length){
                endObj.push({dayProfit: dayProfit})
                out2.push(endObj)
              }
            }
            prevDate = props.convertDateToHumanFriendly(order.endDate).substr(6, props.convertDateToHumanFriendly(order.endDate).length)
        }
      })            
    })

    setAllLevar(out)
    setUnrealizedPNLValue(props.userData.futureUnrealizedPNL)
    setAllLevarEnd(out2)
  }, [props.allFutures, props.allFuturesEnd])

  const handleChangeDate = (datee) => {
    setDate(datee)
    let dat = datee.split("-")
    let tempD = new Date(closingPosDate)
    tempD.setFullYear(dat[0])
    tempD.setMonth(dat[1]-1)
    tempD.setDate(dat[2])
    if(new Date(tempD) != 'Invalid Date')  setClosingPosDate(tempD)
}

const handleChangeTime = (timee) => {
    setTime(timee)
    let t = timee.split(':')
    let newDateConverted = new Date(date)
    newDateConverted.setHours(t[0], t[1])
    if(new Date(newDateConverted) != 'Invalid Date') setClosingPosDate(newDateConverted)
}
  
  const closeFuturePosition = async (coinId, docId, endRate, endDate) => {
    try{
      if(endRate > 0){
        await props.fetchCloseFuturePosition(currentUser.uid, coinId, docId, endRate, endDate.valueOf())
      }
      
    }
    catch(e){
      console.log(e)
    }
  }

  const initRetryPostion = async (coinId, docId) => {
    try{
      if(window.confirm("u sure to back this position to active?")){
        await props.fetchRetryPostionFuture(currentUser.uid, coinId, docId)
      }
    }
    catch(e){
      console.log(e)
    }
  }

  const initDelPosition = async (coinId, docId) => {
    try{
      if(window.confirm("u sure to delete this position forever?")){
        await props.fetchDelPostionFuture(currentUser.uid, coinId, docId)
      }
    }
    catch(e){
      console.log(e)
    }  
  }

  return (
    <Container>
      <Modal 
        show={modalShow}
        onHide={() => setModalShow(false)}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Zamknij pozycję <strong>{closingPosId}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>
            ... of doc {closingPosDoc}
          </p> */}
          <Form.Group>
            <Form.Label>Podaj kurs zamknięcia pozycji</Form.Label>
            <Form.Control  inputMode='decimal' type="number" value={closingPosRate} onChange={(e) => setClosingPosRate(e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group className="mt-3 mb-2">
            <Form.Label className="mb-0 mt-2">Czas zamknięcia</Form.Label>
            <div className="">
              {/* <span style={{fontSize: '13px', marginTop: '0px', paddingTop: '0px', opacity: '0.5'}}>Nie musisz wypełniać, jeśli chcesz wybrać dzisiejszą datę.</span> */}
              <Row>
                <Col xs={12} md={6}>
                  <Form.Control type="date" className="mx-0 mt-1" value={date} onChange={(e) => handleChangeDate(e.target.value)} required />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Control type="time" className="mx-0 mt-1" value={time} onChange={(e) => handleChangeTime(e.target.value)} required />
                </Col>
              </Row>
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => closeFuturePosition(closingPosId, closingPosDoc, closingPosRate, closingPosDate)}>Zamknij pozycję</Button>
          <Button variant="outline-danger" onClick={() => setModalShow(false)}>Zamknij</Button>
        </Modal.Footer>
      </Modal>
      <Row style={{marginTop: '50px'}}>
        <Col xs={12}>
          <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
          <h6 style={{fontWeight: '400'}}>Przegląd zleceń na giełdzie futures</h6>
        </Col>
      </Row>
      <Row style={{marginTop: '30px'}}>
        <Col xs={12}>
          {
            allLevar && allLevarEnd && allLevar.length == 0 && allLevarEnd.length == 0 && props.allFuturesEnd && props.allFuturesEnd.length == 0 &&
            <span className="text-muted">brak zleceń futures</span>
          }
          {allLevar && allLevar.length >= 0 && 
          <>
            <h4>Aktywne</h4> 
            { allLevar.length == 0 ? 
              <span className="text-muted">Brak aktywnych zleceń na rynku futures.</span>
            : 
            <>
               <h5 className={style.unrealizedPNL}><span>Niezralizowany PNL:</span> <strong>{props.visibility == 2 ? unrealizedPNLValue && unrealizedPNLValue >= 0 ? <span>+</span> : null : ''}<span>{props.visibility == 2 ? unrealizedPNLValue && props.numberWithCommas(unrealizedPNLValue.toFixed(2)) : '*****'}</span> <span>$</span></strong></h5>
            </>
            }
            
          </>
          }
          <Row className="mt-4">
          {
            allLevar && allLevar.map((order, idx) => {
              let profitDolar = 0
              let profitPercent = 0

              if(order.type == 'long'){
                profitDolar = ((((order.actualRate / order.rate) - 1) * order.levarage) * ((order.amount * order.rate)/order.levarage))
                profitPercent = (((order.actualRate / order.rate) - 1) * order.levarage) * 100
              }
              else if(order.type == 'short'){
                profitDolar = ((((order.rate / order.actualRate) - 1) * order.levarage) * ((order.amount * order.rate)/order.levarage))
                profitPercent = (((order.rate / order.actualRate) - 1) * order.levarage) * 100
              }

              console.log(`Profit for ${order.coinFullName} = ${profitDolar}`)
              
              return (
                <Col xs={12} sm={6} md={4} key={idx} className={`${order.type == 'long' ? style.longItem : style.shortItem} mt-3 mb-5`}>
                  <div>
                    <span className={style.positionBoxLabel}>
                      {order.type}
                    </span>
                    <Row className={`${style.posotionBoxInfoSec} mt-3`}>
                      <Col xs={2} md={2}>
                        <img src={order.logo} className={style.positionBoxImg} />
                      </Col>
                      <Col xs={10} md={10} className="d-flex flex-column">
                        <span style={{fontSize: '16px', fontWeight: '500'}}>{order.coinFullName}</span>
                        <span style={{fontSize: '14px'}}>Kurs: <strong>{order.actualRate} $</strong></span>
                      </Col>
                      <hr className="mt-3 mb-1" />
                      <Col xs={12} className="d-flex flex-row flex-wrap mt-1">
                        <span className="pe-1">Rozmiar pozycji:</span>
                        <span><strong>{props.visibility == 2 ? order.amount : '*****'} {order.shortName.toUpperCase()}</strong></span>
                      </Col>
                      <Col xs={12} className="d-flex flex-row flex-wrap mt-1">
                        <span className="pe-1">Kurs otwarcia:</span>
                        <span><strong>{props.visibility == 2 ? order.rate : '*****'} $</strong></span>
                      </Col>
                      <Col xs={12} className="d-flex flex-row flex-wrap mt-1">
                        <span className="pe-1">Dźwignia:</span>
                        <span><strong>{props.visibility == 2 ? `x${order.levarage}` : '***'}</strong></span>
                      </Col>
                      <Col xs={12} className="d-flex flex-row flex-wrap mt-1">
                        <span className="pe-1">Wkład:</span>
                        <span><strong>{props.visibility == 2 ? ((order.amount * order.rate)/order.levarage).toFixed(2) : '*****'}$</strong></span>
                      </Col>
                      <Col xs={12} className="d-flex flex-row flex-wrap mt-1">
                        <span className="pe-1">Profit:</span>
                        <span><strong><span className={profitDolar && profitDolar > 0 ? style.pluss : style.minuss}>{props.visibility == 2 ? profitDolar && profitDolar.toFixed(2) : '*****'}$</span> {props.visibility == 2 ? profitPercent && '('+profitPercent.toFixed(2)+'%)' : ''}</strong></span>
                      </Col>
                      <Col xs={12} md={6} className="mt-3">
                        <div>
                          <Button variant="outline-success" onClick={() => {setModalShow(true); setClosingPosId(order.coingeckoID); setClosingPosDoc(order.uid); setClosingPosRate(order.actualRate)}} className="w-100 btn-sm">Zamknij</Button>
                        </div>
                      </Col>
                      <Col xs={12} md={6} className="mt-3">
                        <div>
                          <Button variant="outline-danger" onClick={() => { setClosingPosId(order.coingeckoID); setClosingPosDoc(order.uid); initDelPosition(order.coingeckoID, order.uid);}} className="w-100 btn-sm">Usuń</Button>
                        </div>
                      </Col>
                      <hr className="mt-3 mb-1" />
                      <Col xs={12} className="d-flex flex-row flex-wrap mt-1 mb-2" style={{fontSize: '11px'}}>
                        <span className="pe-1">Otwarto pozycję</span>
                        <span><strong>
                        {props.visibility == 2 ? 
                          <Moment fromNow locale='pl' interval={5000}>
                            {order.date && new Date(order.date)}
                          </Moment> : '** *** *****'}
                        </strong></span>
                        <strong className="w-100 mt-1">{props.visibility == 2 ? props.convertDateToHumanFriendly(order.date) : ""}</strong>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )
            })
          }
          </Row>
          { allLevar && allLevar.length > 0 &&
            <div className="mt-3" style={{textAlign: 'right'}}>
              <LastUpdate userData={props.userData} />
            </div>
          }
        </Col>
        <Col xs={12} className="mt-4">
          {allLevarEnd && allLevarEnd.length > 0 && <h4>Zrealizowane</h4>}
          <ListGroup>
          {
            allLevarEnd && allLevarEnd.map((orderGroup, idx) => {
              return (
                <div key={idx}>
                  {
                    orderGroup && orderGroup.map((order, idxy) => {
                      if(idxy>0 && idxy+1 < orderGroup.length){
                        let tempDay = props.convertDateToHumanFriendly(orderGroup.endDate).substr(6, props.convertDateToHumanFriendly(order.endDate).length)

                        let profitDolar = 0
                        let profitPercent = 0

                        if(order.type == 'long'){
                          profitDolar = ((((order.endRate / order.rate) - 1) * order.levarage) * ((order.amount * order.rate)/order.levarage))
                          profitPercent = (((order.endRate / order.rate) - 1) * order.levarage) * 100
                        }
                        else if(order.type == 'short'){
                          profitDolar = ((((order.rate / order.endRate) - 1) * order.levarage) * ((order.amount * order.rate)/order.levarage))
                          profitPercent = (((order.rate / order.endRate) - 1) * order.levarage) * 100
                        }
                        return(
                        <ListGroup.Item className="mt-2 py-0" key={idxy} style={{borderTopWidth: '1'}}>
                          <div className="py-2 my-0 d-flex flex-row justify-content-between align-items-center">
                            <div className="py-0 d-flex flex-md-row align-content-center justify-content-center justify-content-md-start flex-column w-100">
                              <span style={{borderRight: '1px solid rgba(0,0,0,.125)', paddingRight: '15px', marginRight: '10px', width: '35px'}} className="d-none d-md-flex justify-content-center align-items-center">{idxy}</span>
                              <div className={`${style.desc200Mob100} d-flex flex-column flex-md-row justify-content-start align-items-center`} style={{borderRight: '1px solid rgba(0,0,0,.125)', paddingRight: '10px', marginRight: '10px'}}>
                                <img src={order.logo} style={{maxHeight: '20px', maxWidth: '20px', marginLeft: '5px', borderRadius: '50%'}} />
                                <span className="ms-2 mt-2 mt-md-0" style={{fontSize: '14px'}}>{order.coinFullName}</span>
                              </div>  
                              <div style={{borderRight: '1px solid rgba(0,0,0,.125)', paddingRight: '10px', marginRight: '10px', paddingLeft: '10px', fontSize: '14px'}} className={`${style.desc200Mob100} d-flex justify-content-md-start justify-content-center align-items-center flex-row`}>
                                Zysk: <strong className="ms-1">{profitDolar.toFixed(2)}$ ({((profitDolar / ((order.amount * order.rate)/order.levarage))*100).toFixed(2)}%)</strong>
                              </div>
                              <div className='text-center d-flex justify-content-center align-items-center flex-column flex-md-row'>
                                <small>Zamknięto <strong className=''>
                                  <Moment fromNow locale='pl' interval={5000}>
                                    {order.endDate && new Date(order.endDate)}
                                  </Moment>
                                </strong></small>
                                <small>
                                  <span className="text-muted ps-1">{order.endDate && props.convertDateToHumanFriendly(order.endDate)}</span>
                                </small>
                              </div>
                            </div>
                            <div className={`${style.btnsActions} py-0 d-flex flex-column flex-md-row h-100 justify-content-center align-items-center`} style={{height: '100%'}}>
                              <span className="mb-2 mb-md-0" style={{borderLeft: '1px solid rgba(0,0,0,.125', paddingLeft: '15px', marginLeft: '15px'}} onClick={() => {setModalShow(true); setClosingPosId(order.coingeckoID); setClosingPosDoc(order.uid); setClosingPosRate(order.endRate)}}>
                                <FontAwesomeIcon icon={faPen} />
                              </span>
                              <span className="mt-2 mt-md-0" style={{borderLeft: '1px solid rgba(0,0,0,.125', paddingLeft: '15px', marginLeft: '15px'}} onClick={() => { setClosingPosId(order.coingeckoID); setClosingPosDoc(order.uid); initRetryPostion(order.coingeckoID, order.uid);}}>
                                <FontAwesomeIcon icon={faArrowRotateBack} />
                              </span>
                              <span className="mt-2 mt-md-0" style={{borderLeft: '1px solid rgba(0,0,0,.125', paddingLeft: '15px', marginLeft: '15px'}} onClick={() => { setClosingPosId(order.coingeckoID); setClosingPosDoc(order.uid); initDelPosition(order.coingeckoID, order.uid);}}>
                                <FontAwesomeIcon icon={faTrashCan} />
                              </span>
                            </div>
                          </div>
                        </ListGroup.Item>)
                      }
                      else if(idxy+1 == orderGroup.length){
                        return(
                          <h5 className={style.totalDayFutureEndProfit}><span>Dzienny zysk:</span> <b>{order.dayProfit.toFixed(2)} $</b></h5>
                        )
                      }
                      else{
                        console.log(order.day)
                        return (<h6 className={style.dateTitle}>{idxy==0 && order.day}</h6> )
                      }
                    })
                  }
                  </div>
              )
            })
          }
          </ListGroup>
          { allLevarEnd && allLevarEnd.length > 0 && 
            <div className="mt-3" style={{textAlign: 'right'}}>
              <LastUpdate userData={props.userData} />
            </div>
          }
        </Col>
      </Row>
    </Container>
  )
}
