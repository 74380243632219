import React from 'react'

import Moment from 'react-moment';

export default function LastUpdate({userData}) {
  return (
    <div style={{fontSize: '11px'}} className="lastUpdateModule">
      Ostatnia aktualizacja 
      <Moment className="ms-1" style={{fontWeight: '500'}} fromNow locale='pl' interval={5000}>
        {userData && new Date(userData.lastUpdate)}
      </Moment>
    </div>
  )
}
