import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore'
import 'firebase/compat/messaging'

const firebaseConfig = {
  apiKey: "AIzaSyC2EtGWa3ljZi7II2Ff8eaRrm8d_CRQQZY",
  authDomain: "cryptofolio-f6101.firebaseapp.com",
  databaseURL: "https://cryptofolio-f6101-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "cryptofolio-f6101",
  storageBucket: "cryptofolio-f6101.appspot.com",
  messagingSenderId: "671909530931",
  appId: "1:671909530931:web:e1b5d5281921948a684246"
};

const app = firebase.initializeApp(firebaseConfig)

export default app