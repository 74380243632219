import React, { useEffect, useState } from 'react'
import { ListGroup } from 'react-bootstrap'

import DataTable from 'react-data-table-component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import style from './ListWithLastValuesMainChart.module.css'

export default function ListWithLastValuesMainChart(props) {
    const [tableData, setTableData] = useState()

    const columns = [
        {
            name: 'Czas',
            selector: row => row.time,
            sortable: true,
        },
        {
            name: 'Wartość',
            selector: row => row.value,
            sortable: true
        }, 
        {
            name: 'Usuń',
            selector: row => row.action,
            maxWidth: '20px',
            textAlign: 'center'
        }
    ];

    useEffect(() => {
        let list = []
        props.lastTransactionsChart && props.lastTransactionsChart.reverse().map((transaction, index) => {
            let temp = {
                time: props.convertDateToHumanFriendly(transaction.time),
                value: `$ ${(transaction.value).toFixed(2)}`,
                action: <FontAwesomeIcon icon={faXmark} onClick={() => props.handleDeleteHistoryPortfolioValueRecord(transaction.id)} className={style.closeXMark} />
            }
            list.push(temp)
        }) 

        setTableData(list)
    }, [props.lastTransactionsChart])

    return (
        <div className={style.bixTib}>
        { tableData && (tableData.length) ? <DataTable columns={columns} data={tableData} pagination /> : <span className="text-muted">brak danych</span> }
        </div>
    )
}
