import React, { useState, useEffect } from 'react'

import { ListGroup } from 'react-bootstrap'

import style from './ListWithAllCryptosInFirestore.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'

export default function ListWithAllCryptosInFirestore(props) {

    const [ coins, setCoins ] = useState([])

    useEffect(() => {
        if(props.coinsData && props.portfolioData){
            let list = []
            props.coinsData.map((coin, index) => {
                props.portfolioData.map(coinP => {
                    if(coinP.coingeckoID == coin.coingeckoID){
                        let temp = coin
                        list.push({...temp, show: coinP.show})
                    }
                    if(index + 1 == (props.coinsData).length){
                        setCoins(list)
                    }
                })
            })
        }
    }, [props.portfolioData, props.coinsData])

    return (
        <ListGroup>
            {
                (props.portfolioData && props.coinsData && coins) ?
                coins.map(coin => {
                            return (
                                <ListGroup.Item key={coin.coingeckoID} className={`${coin.show ? style.showen : style.hidden} d-flex flex-row align-items-center justify-content-between`}>
                                    <div className='d-flex flex-row align-items-center'>
                                        <img className={style.cryptoLogo} src={coin.logo} style={{marginRight: '10px'}} />
                                        <div className="d-flex flex-column">
                                            <span><b>{coin.longName}</b><span style={{marginLeft: '5px'}}>({(coin.shortName).toUpperCase()})</span></span>
                                        </div>
                                    </div>
                                    <div>
                                        {/* {
                                            coin.show ? 
                                            <FontAwesomeIcon icon={faEyeSlash} onClick={() => props.hideCoinInDB('hide', coin.coingeckoID)} className={style.closeXMark} />
                                            :
                                            <FontAwesomeIcon icon={faEye} onClick={() => props.hideCoinInDB('show', coin.coingeckoID)} className={style.closeXMark} />
                                        } */}
                                        {coins.length > 1 ? <FontAwesomeIcon icon={faXmark} onClick={() => props.deleteCoinFromDB(coin.coingeckoID)} style={{marginLeft: '15px'}} className={style.closeXMark} /> : null }
                                    </div>
                                </ListGroup.Item>
                            )
                }) : 'loading...'
            }
            </ListGroup>
    )
}
