import React, { useEffect } from 'react'

import { Container, Row, Col, Card } from 'react-bootstrap'

import ListWithLastValuesMainChart from '../../components/ListWithLastValuesMainChart/ListWithLastValuesMainChart'
import EditUsersModule from '../../components/EditUsersModule/EditUsersModule'

import style from './PanelAdmin.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faCheck, faFile } from '@fortawesome/free-solid-svg-icons'

export default function PanelAdmin(props) {

    useEffect(() => {
        props.fetchAllUsersDataToChangeStatus()
    }, [])

    return (
        <Container>
        <Row style={{marginTop: '50px'}}>
            <Col xs={12}>
            <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
            <h6 style={{fontWeight: '400'}}>Widok dostępny tylko dla kont ze statusem administratora</h6>
            </Col>
        </Row>
        {
            props.userData && props.userData.admin &&
                <Row style={{marginTop: '50px'}}>
                    <Col xs={12} md={6}>
                        <Card>
                            <Card.Header>
                                Edytuj użytkowników
                            </Card.Header>
                            <Card.Body>
                                <EditUsersModule 
                                    changeUserActiveStatus={props.changeUserActiveStatus}
                                    changeUserAdminStatus={props.changeUserAdminStatus}
                                    changeUserReportStatus={props.changeUserReportStatus}
                                    fetchAllUsersDataToChangeStatus={props.fetchAllUsersDataToChangeStatus}
                                    shortUsersInfo={props.shortUsersInfo}
                                />
                                <div className="d-flex flex-row flex-wrap" style={{fontSize: '13px'}}>
                                    <span className="mt-3">
                                        <FontAwesomeIcon icon={faFile} />&nbsp;&nbsp;-&nbsp;dzienne raporty
                                    </span>
                                    <span className="mt-3 ms-0 ms-md-4">
                                        <FontAwesomeIcon icon={faLock} />&nbsp;&nbsp;-&nbsp;konto z uprawnieniami administratora
                                    </span>
                                    <span className="mt-3 ms-0 ms-md-4">
                                        <FontAwesomeIcon icon={faCheck} />&nbsp;&nbsp;-&nbsp;konto zweryfikowane
                                    </span>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} className="mt-4 mt-md-0">
                        <Card>
                            <Card.Header>
                                Edytuj ostatnie aktualizacje portfela
                            </Card.Header>
                            <Card.Body className="px-0 py-0">
                                <ListWithLastValuesMainChart 
                                    handleDeleteHistoryPortfolioValueRecord={props.handleDeleteHistoryPortfolioValueRecord}
                                    convertDateToHumanFriendly={props.convertDateToHumanFriendly} 
                                    lastTransactionsChart={props.totalPortfolioValueHistoryDONTMOVE} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            }
        </Container>
    )
}
