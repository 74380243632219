import React, { useState, useEffect } from 'react'
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { Navbar, Container, Form, Button, Nav, FormControl } from 'react-bootstrap'

import logo from './../../assets/icon-glow.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faA, faArrowRightFromBracket, faArrowsRotate, faGear, faLock, faEyeLowVision, faEyeSlash, faEye, faMoon, faSun, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

import './NavbarMain.css'
import style from './NavbarMain.module.css'

export default function NavbarMain(props) {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory()

  const goAdmin = () => {
    history.push('/panel/admin')
  }

  return (
    <Container fluid className="px-0">
      <Navbar bg="dark" expand="lg" expanded={expanded} fixed="top">
        <Container>
          <Navbar.Brand as={NavLink} to="/panel/dashboard" onClick={() => setExpanded(false)}><span className="d-none d-md-inline-block"><img src={logo} alt="logo" style={{maxHeight: '30px'}} /></span><span className="d-inline-block d-md-none"><span>CryptoFolio</span><span style={{color: 'white'}}>.io</span></span></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarMain" onClick={() => setExpanded(expanded ? false : "expanded")}>
            {
              !expanded ? 
              <FontAwesomeIcon icon={faAngleDown} style={{padding: '5px'}} /> : 
              <FontAwesomeIcon icon={faAngleUp} style={{padding: '5px'}} />
            }
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarMain" className={`${props.userData && props.userData.admin && style.adminNav}`}>
            <Nav className="me-auto my-2 my-lg-0">
              <Nav.Link as={NavLink} to="/panel/dashboard" onClick={() => setExpanded(false)}>Dashboard</Nav.Link>
              <Nav.Link as={NavLink} to="/panel/wallet/" onClick={() => setExpanded(false)}>Portfel</Nav.Link>
              <Nav.Link as={NavLink} to="/panel/chart/" onClick={() => setExpanded(false)}>Wykres</Nav.Link>
              <Nav.Link as={NavLink} to="/panel/browse/" onClick={() => setExpanded(false)}>Przeglądaj</Nav.Link>
              <Nav.Link as={NavLink} to="/panel/history/" onClick={() => setExpanded(false)}>Historia transakcji</Nav.Link>
              <Nav.Link as={NavLink} to="/panel/futures/" onClick={() => setExpanded(false)}>Futures</Nav.Link>
              <Nav.Link as={NavLink} to="/panel/reports/" onClick={() => setExpanded(false)}>Raporty</Nav.Link>
            </Nav>
            <Button onClick={() => {props.refreshSite(); setExpanded(false)}} className={style.logoutBTN} variant="outline-secondary" disabled={(props.loading || !props.activeAccount)}>
              <FontAwesomeIcon className={style.refreshI} icon={faArrowsRotate} />
            </Button>
            <Button variant="outline-secondary" className={`${style.logoutBTN} ${style.eyeI}`} onClick={props.changeVisibility} disabled={!props.activeAccount}>
              { props.visibility == 2 ? <FontAwesomeIcon icon={faEyeSlash} /> : null }
              { props.visibility == 0 ? <FontAwesomeIcon icon={faEyeLowVision} /> : null }
              { props.visibility == 1 ? <FontAwesomeIcon icon={faEye} /> : null }
            </Button>
            <Button variant="outline-secondary" className={style.logoutBTN} as={NavLink} to="/panel/settings/" onClick={() => setExpanded(false)}>
              <FontAwesomeIcon icon={faGear} />
            </Button>
            {/* <Button variant="outline-secondary" className={style.logoutBTN} onClick={props.changeColorMode}>
              { props.colorMode == 'light' ? <FontAwesomeIcon icon={faMoon} /> : <FontAwesomeIcon icon={faSun} /> }
            </Button> */}
            { props.userData && props.userData.admin &&
            <Button variant="outline-secondary" className={style.logoutBTN} onClick={goAdmin}>
              <FontAwesomeIcon icon={faLock} />
            </Button>
            } 
            <Button onClick={props.handleLogout} className={style.logoutBTN} variant="outline-secondary">
              <span className="d-none d-md-inline-flex">Wyloguj</span> <FontAwesomeIcon style={{marginLeft: '5px'}} icon={faArrowRightFromBracket} />
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  )
}
