import React, { useEffect, useState } from 'react'

import style from './ChartRangeLabel.module.css'

export default function ChartRangeLabel(props) {

  return (
    <div className={props.currentChartRange.label == props.range.label ? `${style.activeRange} ${style.rangeBtn}` : style.rangeBtn} onClick={() => props.changeRangeOfChart(props.range)}>
      {props.range.label}
    </div>  
    )
    
}
