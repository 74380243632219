import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'

import AddNewCrypto from '../../components/AddNewCrypto/AddNewCrypto'
import ListWithAllCryptosInFirestore from '../../components/ListWithAllCryptosInFirestore/ListWithAllCryptosInFirestore'
import ListWithLastValuesMainChart from '../../components/ListWithLastValuesMainChart/ListWithLastValuesMainChart'

import app from '../../firebase'

import style from './PanelSettings.module.css'
import { toast } from 'react-toastify'
import Loader from '../../components/Loader/Loader'

export default function PanelMovements(props) {
    const { currentUser } = useAuth()

    const emailRef = useRef()
    const passref = useRef()
    const [ date, setDate ] = useState(`${new Date().getFullYear()}-${new Date().getMonth()+1 < 10 ? "0"+(new Date().getMonth()+1) : new Date().getMonth()+1}-${new Date().getDate() < 10 ? "0"+new Date().getDate() : new Date().getDate()}`)
    const [ time, setTime ] = useState(`${new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()}:${new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()}`)
    const [ outputDate, setOutputDate ] = useState(new Date())
    const [ amountToCashOut, setAmountToCashOut ] = useState()

    const handleChangeDate = (da) => {
        setDate(da)
        let dat = da.split("-")
        let tempD = new Date(outputDate)
        tempD.setFullYear(dat[0])
        tempD.setMonth(dat[1]-1)
        tempD.setDate(dat[2])
        if(new Date(tempD) != 'Invalid Date') setOutputDate(tempD)
    }

    const handleChangeTime = (ti) => {
        setTime(ti)
        let t = ti.split(':')
        let newDateConverted = new Date(date)
        newDateConverted.setHours(t[0], t[1])
        if(new Date(newDateConverted) != 'Invalid Date') setOutputDate(newDateConverted)
    }

    const handleSaveMail = async (e) => {
        e.preventDefault()

        try{
            await app.auth().signInWithEmailAndPassword(currentUser.email, passref.current.value).then( userCredential => {
                userCredential.user.updateEmail(emailRef.current.value)
                toast(`Poprawnie zmieniono adres email na ${emailRef.current.value}. Zaraz nastąpi wylogowanie.`, {
                    type: 'success'
                })
                
                setTimeout(() => {
                    app.auth().signOut()
                }, 5000)
            })
            await app.firestore()
                .collection('users')
                .doc(currentUser.uid)
                .update({
                    email: emailRef.current.value
                })
            await props.refreshSite()
        }
        catch(e){
            if(e.code = 'auth/wrong-password'){
                toast('Nieprawidłowe hasło', {
                    type: 'error'
                })
            }
            console.log(e.code)
        }
    }

    const handleCashOutAmount = (e) => {
        setAmountToCashOut(e)
    }

    const handleInsertCashOut = async () => {
        if(amountToCashOut > 0){
            try{
                await props.handleInsertFiatOut(currentUser.uid, amountToCashOut, outputDate.valueOf())
                toast('Poprawnie zapisano transakcję wypłaty waluty.', {
                    type: 'success'
                })
                setAmountToCashOut(0)
                setDate(`${new Date().getFullYear()}-${new Date().getMonth()+1 < 10 ? "0"+(new Date().getMonth()+1) : new Date().getMonth()+1}-${new Date().getDate() < 10 ? "0"+new Date().getDate() : new Date().getDate()}`)
                setTime(`${new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()}:${new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()}`)
                setOutputDate(new Date())
            }
            catch(e){
                console.log(e)
            }
        }
        else{
            toast('Podaj poprawną kwotę wypłaty', {
                type: 'warning'
            })
        }
    }

    const handleInsertCashIn = async () => {
        if(amountToCashOut > 0){
            try{
                await props.handleInsertFiatIn(currentUser.uid, amountToCashOut, outputDate.valueOf())
                toast('Poprawnie zapisano transakcję wpłaty waluty.', {
                    type: 'success'
                })
                setAmountToCashOut(0)
                setDate(`${new Date().getFullYear()}-${new Date().getMonth()+1 < 10 ? "0"+(new Date().getMonth()+1) : new Date().getMonth()+1}-${new Date().getDate() < 10 ? "0"+new Date().getDate() : new Date().getDate()}`)
                setTime(`${new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()}:${new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()}`)
                setOutputDate(new Date())
            }
            catch(e){
                console.log(e)
            }
        }
        else{
            toast('Podaj poprawną kwotę wpłaty', {
                type: 'warning'
            })
        }
    }

    return (
        <Container>
            <Row style={{marginTop: '50px'}}>
                <Col xs={12}>
                    <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
                </Col>
            </Row>
            <Row style={{marginTop: '50px'}}>
                <Col xs={12} md={6}>
                    <Card>
                        <Card.Header>
                            Dodaj nową kryptowalutę
                        </Card.Header>
                        <Card.Body>
                            <AddNewCrypto existInCoingecko={props.existInCoingecko} addNewCryptoToDatabase={props.addNewCryptoToDatabase} searchedCryptoToAdd={props.searchedCryptoToAdd} searched={props.searched} checkIfCryptoExistInCoingeckoApi={props.checkIfCryptoExistInCoingeckoApi} />
                        </Card.Body>
                    </Card>
                    <Card className="mt-3">
                        <Card.Header>
                            Zarejestruj wypłatę/wpłatę FIAT (w USD)
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Kwota</Form.Label>
                                    <Form.Control type="number"  inputMode='decimal' onChange={(e) => handleCashOutAmount(e.target.value)} placeholder='np. 240.75'></Form.Control>
                                </Form.Group>
                                <Form.Group className="mt-3 mb-2">
                                    <Form.Label className="mb-0 mt-2">Czas wypłaty</Form.Label>
                                    <div className="">
                                        {/* <span style={{fontSize: '13px', marginTop: '0px', paddingTop: '0px', opacity: '0.5'}}>Nie musisz wypełniać, jeśli chcesz wybrać dzisiejszą datę.</span> */}
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Control type="date" className="mx-0 mt-1" value={date} onChange={(e) => handleChangeDate(e.target.value)} required />
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Control type="time" className="mx-0 mt-1" value={time} onChange={(e) => handleChangeTime(e.target.value)} required />
                                            </Col>
                                        </Row>
                                    </div>
                                </Form.Group>
                                <Button variant="outline-success" className="mt-2 w-100" onClick={handleInsertCashIn}>Zapisz wpłatę</Button>
                                <Button variant="outline-danger" className="mt-2 w-100" onClick={handleInsertCashOut}>Zapisz wypłatę</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                    <Card className="mt-3">
                        <Card.Header>
                            Zmień adres email przypisany do konta
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSaveMail}>
                                <Form.Group className="d-flex flex-column">
                                    <Form.Label className="text-muted">Obecny adres email: {currentUser && currentUser.email}</Form.Label>
                                    <Form.Label>Podaj nowy mail:</Form.Label>
                                    <Form.Control type="email" ref={emailRef} placeholder='jan@kowalski.pl' defaultValue={currentUser && currentUser.email} required></Form.Control>
                                </Form.Group>
                                <Form.Group className="d-flex flex-column mt-2">
                                    <Form.Label>Podaj hasło:</Form.Label>
                                    <Form.Control type="password" ref={passref} required></Form.Control>
                                </Form.Group>
                                <Button variant="outline-success" type="submit" className="mt-2 w-100">Zapisz nowy mail</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={6} className="mt-4 mt-md-0">
                    <Card>
                        <Card.Header>
                            Wszystkie kryptowaluty w bazie danych
                        </Card.Header>
                        <Card.Body>
                            <ListWithAllCryptosInFirestore hideCoinInDB={props.hideCoinInDB} portfolioData={props.userPortfolio} coinsData={props.coinsData} deleteCoinFromDB={props.deleteCoinFromDB} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
