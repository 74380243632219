import React, { useState , useEffect} from 'react'

import style from './Chart.module.css'

import { Container, Row, Col } from 'react-bootstrap'

import TotalPortfolioChart from '../../components/TotalPortfolioChart/TotalPortfolioChart'

import { useAuth } from '../../contexts/AuthContext'
import Loader from '../../components/Loader/Loader'

export default function Chart(props) {
    const { currentUser } = useAuth()

    useEffect(() => {
        props.changeRangeOfChart(props.chartRange[4])
    }, [])

    return (
        <Container>
            <Row style={{marginTop: '50px'}}>
                <Col xs={12}>
                    <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
                    <h6 style={{fontWeight: '400'}}>Całowita wartość portfela: <span style={{fontWeight: '600'}}>${props.userData && props.visibility == 2 ? (props.userData.totalCurrentValueInUSD).toFixed(2) : '*****'}</span></h6>
                </Col>
            </Row>
            <Row style={{marginTop: '50px'}}>
                <Col xs={12}>
                    {   
                        (!props.loadingChart && props.chartData) ? 
                        props.visibility == 2 ? 
                        <TotalPortfolioChart changeRangeOfChart={props.changeRangeOfChart} loadingChart={props.loadingChart} chartData={props.chartData} chartRange={props.chartRange} chartOptions={props.chartOptions} totalPortfolioValueHistory={props.totalPortfolioValueHistory} currentChartRange={props.currentChartRange} /> :  
                        <TotalPortfolioChart changeRangeOfChart={props.changeRangeOfChart} chartData={props.chartData} chartRange={props.chartRange} chartOptions={props.hideChartOptions} totalPortfolioValueHistory={props.totalPortfolioValueHistory} currentChartRange={props.currentChartRange} />
                        : 
                        <>
                            <h4 style={{display: 'flex', justifyContent: 'center', marginTop: '50px', textAlign: 'center'}}>
                                Generowanie wykresu...
                            </h4>                        
                            <Loader />                        
                        </>
                    }
                </Col>
            </Row>
        </Container>
    )
}
