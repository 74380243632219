import React from "react"
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

function PrivateRoute({ component: Container, ...rest }){
    const { currentUser } = useAuth()

    return(
        <Route
            {...rest}
            render={props => {
                return currentUser ? <Container {...props} /> : <Redirect to="/" />
            }}
        ></Route>
    )
}

export default PrivateRoute