import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'

import style from './PanelMovements.module.css'

import { NavLink } from 'react-router-dom'

import app from '../../firebase'

export default function PanelMovements(props) {
    const { currentUser } = useAuth()
    const [ searchingCrypto, setSearchingCrypto ] = useState()
    const [ searching, setSearching ] = useState(false)
    const [ selected, setSelected ] = useState(false)
    const [all, setAll] = useState(true)
    const [ allSearching, setAllSearching ] = useState([])

    const handleSearchCrypto = (e) => { 
        setSearchingCrypto(e.target.value) 
        setSearching(true)
        setAll(false)
        if(e.target.value == ""){
            setAll(true)
            setSearching(false)
        } 
    }

    useEffect(() => {
        if(props.userPortfolio && props.coinsData){
            let list = []
            props.userPortfolio.map((coin, index) => {
                props.coinsData.map(coinMain => {
                    if(coin.coingeckoID == coinMain.coingeckoID && coin.show){
                        list.push(coinMain)
                    }
                })
                if(props.userPortfolio.length - 1 == index){
                    setAllSearching(list)
                }
            })
        }
    }, [searching])

    return (
        <Container>
            <Row style={{marginTop: '50px'}}>
                <Col xs={12}>
                    <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
                </Col>
            </Row>
            <Row style={{marginTop: '50px'}}>
                { !selected ? 
                <Col xs={12}>
                    <Form>
                        <Row>
                            <Col xs={12}>
                                <Form.Label>Wyszukaj kryptowalutę w twoim portfelu</Form.Label>
                                <Form.Control type="text" className={style.mainSearch} onChange={handleSearchCrypto} value={searchingCrypto} placeholder="np. Solana, Metahero, Floki..." />
                            </Col>
                        </Row>
                    </Form>
                </Col> : '' }
                <Col xs={12} className="mt-3">
                    { !allSearching ? <span>Liczba kryptowalut w bazie danych: <b>{allSearching && allSearching.length}</b></span> : '' }
                    <Row>
                        {
                        allSearching ?  
                        allSearching.map(coin => {
                            if(all || (searchingCrypto && (((coin.longName).toLowerCase()).indexOf(searchingCrypto.toLowerCase()) > -1))){
                                let all = null
                                if(searchingCrypto == "" || !searchingCrypto) all = true
                                let currentWallet
                                let haveSomeCoins = false
                                let type = null
        
                                props.portfolioCoinsValues.map(coinP => {
                                    if(coin.coingeckoID == coinP.coingeckoID){
                                        haveSomeCoins = true
                                        currentWallet = coinP
                                    } 
                                })
        
                                return(
                                                <Col xs={12} md={3} className="mt-4 px-2 py-" key={coin.coingeckoID}>
                                                    <Card className={style.panMovBox}>
                                                        <Card.Header className="d-flex align-items-center" style={{padding: '8px', fontSize: '14px'}}>
                                                            <img src={coin.logo} style={{maxHeight: '20px', marginRight: '5px'}} />
                                                            <span className="ps-1" style={{fontWeight: '500'}}>{coin.longName}</span>
                                                        </Card.Header>
                                                        <Card.Body className="px-0 py-0">
                                                            <Row className="w-100" style={{marginLeft: '0px'}}>
                                                                <Col xs={6} className="px-0">
                                                                    <Button as={NavLink} className={`w-100 ${style.actBtn}`} variant="outline-success" to={`/panel/transaction/${coin.coingeckoID}/buy`}>KUP</Button>
                                                                </Col>
                                                                <Col xs={6} className="px-0">
                                                                    <Button as={NavLink} className={`w-100 ${style.actBtn}`} variant="outline-danger" to={`/panel/transaction/${coin.coingeckoID}/sell`}>SPRZEDAJ</Button>
                                                                </Col>
                                                                <Col xs={6} className="px-0">
                                                                    <Button as={NavLink} className={`w-100 ${style.actBtn}`} variant="outline-primary" to={`/panel/transaction/${coin.coingeckoID}/transfer`}>TRANSFER</Button>
                                                                </Col>
                                                                <Col xs={6} className="px-0">
                                                                    <Button as={NavLink} className={`w-100 ${style.actBtn}`} variant="outline-secondary" to={`/panel/history/${coin.coingeckoID}`}>HISTORIA</Button>
                                                                </Col>
                                                                <Col xs={12} className="px-0">
                                                                    <Button as={NavLink} className={`w-100 ${style.actBtn} ${style.showMoreBtn}`} to={`/panel/coin/${coin.coingeckoID}`} variant="outline">Zobacz więcej</Button>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                        <Card.Footer style={{fontSize: '14px'}}>
                                                            <span style={{opacity: '0.65'}}>Portfel: </span>{ haveSomeCoins ? (props.visibility == 2 ? `${((currentWallet.wallet > 1) && (currentWallet.wallet % 1 == 0)) ? props.numberWithCommas(currentWallet.wallet) : currentWallet.wallet} (~ $${currentWallet.valueInUSD})` : '$ *****') : 'brak' }
                                                        </Card.Footer>
                                                    </Card>
                                                </Col>
                                )
                            }
                        }) : 
                        'brak wybranych kryptowalut'
                    }
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
