import './App.css';
import React from 'react'
import {BrowserRouter as Router, Switch, Route, withRouter} from 'react-router-dom'
import { Container } from 'react-bootstrap'
import {AuthProvider} from './contexts/AuthContext';
import PrivateRoute from './pages/PrivateRoute';

import Favicon from 'react-favicon'
import favi from './assets//icon_512x512.png'

import './variables.css'

import Login from './pages/Login/Login'
import Panel from './pages/Panel/Panel'
import Join from './pages/Join/Join'

function App() {
    return (
        <Container fluid className="px-0" style={{minHeight: '100vh'}}>
            <Favicon url={favi} />
            <Router>
                <AuthProvider>
                    <Switch>
                        <Route path="/join"
                            component={Join}
                            exact/>
                        <Route path="/"
                            component={Login}
                            exact/>
                        <PrivateRoute path="/panel"
                            component={Panel}/>
                    </Switch>
                </AuthProvider>
            </Router>
        </Container>
    );
}

export default App;
