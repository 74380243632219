import React from 'react'

import { Container, Row, Col } from 'react-bootstrap'

import style from './ReportBug.module.css'

export default function ReportBug(props) {

  return (
    <Container>
      <Row style={{marginTop: '50px'}}>
        <Col xs={12}>
          <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
          <h6 style={{fontWeight: '400'}}>Zgłoś błąd na stronie - under construction</h6>
        </Col>
      </Row>
      <Row style={{marginTop: '50px'}}>
        <Col xs={12}>
          
        </Col>
      </Row>
    </Container>
  )
}
