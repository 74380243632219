import React, { useState, useEffect } from 'react'

import { Container, Row, Col, Card, Form } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'

import style from './Reports.module.css'

import app from '../../firebase'
import Loader from '../../components/Loader/Loader'

export default function Reports(props) {
    const { currentUser } = useAuth()

    const [ switchShow, setSwitchShow ] = useState(props.userData.autoRaports && props.userData.autoRaports)
    const [ switchShow0630, setSwitchShow0630 ] = useState(props.userData.settings.reports && props.userData.settings.reports.report0630)
    const [ switchShow1300, setSwitchShow1300 ] = useState(props.userData.settings.reports && props.userData.settings.reports.report1300)
    const [ switchShow1800, setSwitchShow1800 ] = useState(props.userData.settings.reports && props.userData.settings.reports.report1800)
    const [ switchShow2300, setSwitchShow2300 ] = useState(props.userData.settings.reports && props.userData.settings.reports.report2300)
    const [ loadingRaports, setLoadingRaports ] = useState(false)

    const handleSwitchShow = async () => {        
        setLoadingRaports(true)
        try{
            await app.firestore()
            .collection('users')
            .doc(currentUser.uid)
            .update({
                autoRaports: !switchShow ? true : false
            })

            setSwitchShow(!switchShow)
            props.fetchUserData()
            setLoadingRaports(false)
        }
        catch(e){
            console.log(e)
            setLoadingRaports(false)
        }
    }

    const handleSwitchShow0630 = async () => {        
        setLoadingRaports(true)
        try{
            await app.firestore()
            .collection('users')
            .doc(currentUser.uid)
            .collection('settings')
            .doc('reports')
            .update({
                report0630: !switchShow0630 ? true : false
            })

            setSwitchShow0630(!switchShow0630)
            props.fetchUserData()
            setLoadingRaports(false)
        }
        catch(e){
            console.log(e)
            setLoadingRaports(false)

            await app.firestore()
            .collection('users')
            .doc(currentUser.uid)
            .collection('settings')
            .doc('reports')
            .set({
                report0630: true
            })
        }
    }

    const handleSwitchShow1300 = async () => {        
        setLoadingRaports(true)
        try{
            await app.firestore()
            .collection('users')
            .doc(currentUser.uid)
            .collection('settings')
            .doc('reports')
            .update({
                report1300: !switchShow1300 ? true : false
            })

            setSwitchShow0630(!switchShow1300)
            props.fetchUserData()
            setLoadingRaports(false)
        }
        catch(e){
            console.log(e)
            setLoadingRaports(false)

            await app.firestore()
            .collection('users')
            .doc(currentUser.uid)
            .collection('settings')
            .doc('reports')
            .set({
                report1300: true
            })
        }
    }

    const handleSwitchShow1800 = async () => {        
        setLoadingRaports(true)
        try{
            await app.firestore()
            .collection('users')
            .doc(currentUser.uid)
            .collection('settings')
            .doc('reports')
            .update({
                report1800: !switchShow1800 ? true : false
            })

            setSwitchShow0630(!switchShow1800)
            props.fetchUserData()
            setLoadingRaports(false)
        }
        catch(e){
            console.log(e)
            setLoadingRaports(false)

            await app.firestore()
            .collection('users')
            .doc(currentUser.uid)
            .collection('settings')
            .doc('reports')
            .set({
                report1800: true
            })
        }
    }

    const handleSwitchShow2300 = async () => {        
        setLoadingRaports(true)
        try{
            await app.firestore()
            .collection('users')
            .doc(currentUser.uid)
            .collection('settings')
            .doc('reports')
            .update({
                report2300: !switchShow2300 ? true : false
            })

            setSwitchShow0630(!switchShow2300)
            props.fetchUserData()
            setLoadingRaports(false)
        }
        catch(e){
            console.log(e)
            setLoadingRaports(false)

            await app.firestore()
            .collection('users')
            .doc(currentUser.uid)
            .collection('settings')
            .doc('reports')
            .set({
                report2300: true
            })
        }
    }

    return (
        <Container>
            <Row style={{marginTop: '50px'}}>
                <Col xs={12}>
                    <h3>Witaj, <span style={{color: 'rgb(230 230 230)'}}>{`${props.userData && props.userData.nick}`}</span>!</h3>
                    <h6 style={{fontWeight: '400'}}>Zarządzaj zautomatyzowanymi raportami na twoim koncie</h6>
                </Col>
                </Row>
                <Row style={{marginTop: '50px'}}>
                <Col xs={12}>
                    <Card className="">
                        <Card.Header>
                            Ustawienia generowania raportów
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Group>
                                    { loadingRaports ? <Loader /> : <Form.Check type="switch" checked={switchShow} label="Automatyczne raporty" onChange={handleSwitchShow} className={style.switcher} /> }
                                </Form.Group>

                            </Form>
                        </Card.Body>
                    </Card>
                    {
                        switchShow &&
                        <Card className="mt-3">
                            <Card.Header>
                                Codzienny raport
                            </Card.Header>
                            <Card.Body>
                                <Form className={style.dailyRaportBox}>
                                    <Form.Group>
                                        { loadingRaports ? <Loader /> : <Form.Check type="switch" checked={switchShow0630} label="Raport o 6:30" onChange={handleSwitchShow0630} className={style.switcher} /> }
                                    </Form.Group>
                                    <Form.Group>
                                        { loadingRaports ? <Loader /> : <Form.Check type="switch" checked={switchShow1300} label="Raport o 13:00" onChange={handleSwitchShow1300} className={style.switcher} /> }
                                    </Form.Group>
                                    <Form.Group>
                                        { loadingRaports ? <Loader /> : <Form.Check type="switch" checked={switchShow1800} label="Raport o 18:00" onChange={handleSwitchShow1800} className={style.switcher} /> }
                                    </Form.Group>
                                    <Form.Group>
                                        { loadingRaports ? <Loader /> : <Form.Check type="switch" checked={switchShow2300} label="Raport o 23:00" onChange={handleSwitchShow2300} className={style.switcher} /> }
                                    </Form.Group>
                                </Form>
                                {/* {
                                    switchShow &&
                                    <span>
                                        Obecnie wysyłane są automatyczne raporty z podsumowaniem ogólnym portfolio, <b>codziennie o 6:30</b> na adres email przypisany do konta. 
                                    </span>
                                } */}
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row>
        </Container>
    )
}
